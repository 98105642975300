import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environment/environment';
import { Router } from '@angular/router';
import { Session } from '../models/session.model';

const API_URL = environment.API_URL;

@Injectable({ providedIn: 'root' })
export class SessionService {
    private userSettingsSubject = new BehaviorSubject<Session>(null);
    userSettings$ = this.userSettingsSubject.asObservable() as Observable<Session>;

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    loadUserSettings(): Promise<Session> {
        return new Promise(resolve => {
            if (!sessionStorage.getItem('token')) {
                this.userSettingsSubject.next(null);
                resolve(null);
                return;
            }

            this.http.get(API_URL + '/session-info/').subscribe({
                next: (settings: Session) => {
                    this.userSettingsSubject.next(settings);
                    if (!environment.production) {
                        console.log('User settings:', settings);
                    }
                    resolve(settings);

                    // se l'utente non ha ancora accettato i termini e condizioni, reindirizza a '/accettazione-termini-e-condizioni'
                    if (!settings.user.terms_and_conditions) {
                        this.router.navigate(['/accettazione-termini-e-condizioni']);
                    }

                    // se il ruolo dell'utente contiene 'principal' o 'demo', reindirizza a '/presenze'
                    if (settings.user.roles.includes('principal') || settings.user.roles.includes('demo')) {
                        this.router.navigate(['/presenze']);
                    }
                },
                error: (error) => {
                    console.error('Error fetching user settings:', error);
                    resolve(null);
                }
            });
        });
    }

    updateUserSettings(newSettings: any) {
        this.userSettingsSubject.next(newSettings);
    }

    getUserId(): number | null {
        const currentSettings: Session = this.userSettingsSubject.getValue();
        return currentSettings ? currentSettings.user.id : null;
    }

    getCompanyId(): number | null {
        const currentSettings: Session = this.userSettingsSubject.getValue();
        return currentSettings ? currentSettings.company.id : null;
    }

    getCompanySetting(setting_key: string): string | null {
        const currentSettings: Session = this.userSettingsSubject.getValue();
        if (!currentSettings) return null;

        const setting = currentSettings.company.settings?.find((s: any) => s.setting_key === setting_key);
        return setting ? setting.setting_value : null;
    }

    isUserSysAdmin(): boolean {
        const currentSettings = this.userSettingsSubject.getValue();
        if (!currentSettings) return false;

        return currentSettings.user.roles.includes('sys_admin');
    }

    clearUserSettings(): void {
        this.userSettingsSubject.next(null);
    }
}
