import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(private sessionService: SessionService) { }

    saveUser(data: any) {
        sessionStorage.setItem('token', data.access_token);
        if (data.refresh_token)
            sessionStorage.setItem('refreshToken', data.refresh_token);
        this.sessionService.loadUserSettings();
        return true;
    }

    getToken() {
        return sessionStorage.getItem('token');
    }

    getMobile() {
        return sessionStorage.getItem('mobile');
    }

    getRefreshToken() {
        return sessionStorage.getItem('refreshToken');
    }

    getUserCompany(): number {
        return parseInt(sessionStorage.getItem('id_company')) || 0;
    }

    setUserCompany(id_company: number) {
        sessionStorage.setItem('id_company', id_company.toString());
    }

    deleteUser() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refreshToken');
        return true;
    }
}
