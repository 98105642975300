import { Component } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FiltersModel } from 'src/app/models/entity.model';
import { Company, Session, User } from 'src/app/models/session.model';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { SessionService } from 'src/app/services/session.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
    standalone: false
})
export class ProfileComponent {
    private subscriptions = new Subscription();
    private destroy$ = new Subject<void>();
    user: User;
    company: Company
    documents: any[] = [];
    mobile: boolean = false;

    constructor(
        private sessionService: SessionService,
        private api: ApiService,
        private notifier: NotifierService,
        private storage: StorageService,
    ) { }

    ngOnInit() {
        this.subscriptions.add(
            this.sessionService.userSettings$.subscribe((settings: Session) => {
                this.user = settings.user;
                this.company = settings.company;
                this.loadDocuments();
            })
        );
        // recupera eventuale parametro "mobile" settato solo da app mobile
        this.mobile = this.storage.getMobile() == 'true' ? true : false;

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe(); // Annulla tutte le sottoscrizioni
        this.destroy$.next();
        this.destroy$.complete();
    }

    loadDocuments() {
        const _select = ["id", "original_name", "file_size", "uploaded_at"],
            _filters: FiltersModel[] = [
                { field: 'reference_table', operator: "=", value: 'users' },
                { field: 'reference_id', operator: "=", value: this.user.id }
            ],
            _sort = [{ field: 'uploaded_at', direction: 'desc' }],
            _options = {};

        this.api.select(typeof {}, 'files', _select, _filters, _sort, _options)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    this.documents = data;
                },
                error: (error) => {
                    console.error('Errore durante il caricamento dei dati:', error);
                    this.notifier.showError('Errore', 'Si è verificato un errore. Riprovare o contattare gli amministratori se il problema persiste.');
                }
            });
    }

    getExtension(fileName: string): string {
        const parts = fileName.split('.');
        return parts.length > 1 ? parts.pop().toUpperCase() : '';
    }

    downloadFile(id: number): void {
        this.api.getDownloadUrl(id)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (url: string) => {
                    const a = document.createElement('a');
                    a.href = url;
                    // l'app mobile va in errore se si imposta il target blank
                    // ma è necessario per il download dei file da browser
                    if (!this.mobile)
                        a.target = '_blank';
                    a.download = '';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                },
                error: (error: any) => {
                    console.error(error);
                }
            });
    }

    formatFileSize(size: number) {
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];
        let unitIndex = 0;
        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }
        return `${size.toFixed(2)} ${units[unitIndex]}`;
    }
}
