import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.services';

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.css'],
    standalone: false
})
export class TermsAndConditionsComponent {

  
  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  accept(){
    return this.auth.acceptTermsAndConditions().subscribe({
      next: (data) => {
          this.router.navigate(['/']);
      },
      error: error => {
      }
    });
  }
}
