<div style="padding: 36px 24px">
    <p>
        <small class="field_name">USERNAME</small>
        <br />
        <label class="filed_value">
            {{user.username}}
        </label>
    </p>
    <p>
        <small class="field_name">RUOLO</small>
        <br />
        <label class="filed_value">
            {{user.admin ? 'Admin' : user.principal ? 'Preside' : 'Operatore'}}
        </label>
    </p>
    <p>
        <small class="field_name">STATO</small>
        <br />
        <label class="filed_value">
            {{user.active ? 'Attivo' : 'Non attivo'}}
        </label>
    </p>
    <button mat-raised-button class="button-with-icon" [routerLink]="['/cambio-password']" style="margin-top: 12px">
        Cambio password
    </button>
</div>