<div class="main_div_terms">
    <img class="img_terms" src="/assets/img/cooperativeincloud/logo.png">
    <div><p><strong><u>TERMINI E CONDIZIONI DI UTILIZZO DELL’APPLICAZIONE “Cooperative in Cloud” </u></strong></p>
    <ol>
    <li><strong> INTRODUZIONE </strong></li>
    </ol>
    <p><strong>1.1. </strong>Le presenti Condizioni sono stipulate tra la Società e l’Utente (come di seguito definiti) ed entrano in vigore al momento dell’accettazione delle Condizioni da parte dell’Utente. Oggetto delle presenti Condizioni è la licenza d’uso dell’Applicazione denominata “Cooperative in Cloud” (come di seguito definita), secondo i termini e le condizioni di seguito descritti.</p>
    <p><strong>1.2. </strong>Installando l’Applicazione o utilizzando i servizi della stessa, l’Utente dichiara di aver letto e compreso i termini di utilizzo delle presenti Condizioni e di accettare integralmente ciascuna delle presenti disposizioni.</p>
    <p><strong>1.3. </strong>L’accettazione integrale delle Condizioni è requisito necessario per l’utilizzo dell’Applicazione secondo i termini previsti dalle presenti Condizioni. Se l’Utente non intende accettare le presenti Condizioni, l’Utente è invitato a non cliccare sul pulsante di registrazione all’Applicazione e a non accedere, visionare, inserire dati, scaricare o in altro modo utilizzare alcuna pagina, contenuto o servizio offerto dall’Applicazione.</p>
    <ol start="2">
    <li><strong> DEFINIZIONI</strong></li>
    </ol>
    <p><strong>2.1. “<em>Aggiornamenti</em></strong>” indica ogni aggiornamento, miglioramento, supplemento che può essere fornito dalla Società in relazione all’Applicazione;</p>
    <p><strong>2.2. </strong>“<strong><em>Apple App Store</em></strong>” indica il servizio realizzato da Apple, Inc., che permette agli utenti di scaricare e acquistare applicazioni disponibili sulla piattaforma di distribuzione iTunes Store, sviluppata e gestita da Apple, Inc. sotto la propria esclusiva responsabilità;</p>
    <p><strong>2.3. </strong>“<strong>Google Play</strong>” indica la piattaforma di distribuzione di applicazioni e contenuti digitali sviluppata e gestita da Google, Inc. sotto la propria esclusiva responsabilità;</p>
    <p><strong>2.4. </strong>“<strong><em>Applicazione</em></strong>” indica l’applicazione denominata “Cooperative in Cloud”, disponibile sulla piattaforma Google Play per smartphone aventi sistema operativo Android versione 5.0 e successive e sulla piattaforma Apple App Store per tutti i modelli di iPhone con sistema operativo iOS versioni 7 e successive, che permette agli Utenti di accedere all’applicazione medesima e di interagire attivamente con Itinera;</p>
    <p><strong>2.5. </strong>“<strong><em>Codice del Consumo</em></strong>” indica il Decreto Legislativo 6 settembre 2005, n. 206, e successive modifiche ed integrazioni;</p>
    <p><strong>2.6. </strong>“<strong><em>Condizioni</em></strong>” indica le presenti condizioni di utilizzo e ogni altro documento ivi richiamato o alle stesse allegato;</p>
    <p><strong>2.7. </strong>“<strong><em>Contenuti</em></strong>” indica informazioni, dati o contenuti, di interesse di Cooperative in Cloud, contenuti nell’Applicazione, compresi a titolo esemplificativo loghi, marchi, icone, grafici, mappe, testi, secondo quanto specificato nel menu dell’Applicazione nonché tutti i dati che verranno inseriti nell’Applicazione dall’Utente;</p>
    <p><strong>2.8. </strong>“<strong><em>Parti</em></strong>” indica la Società e l’Utente;</p>
    <p><strong>2.9. </strong>“<strong><em>Società</em></strong>” indica 3CEngineering s.r.l.s., con sede legale in Roma, Via Riccardo Pitteri, 32;</p>
    <p><strong>2.10. </strong>“<strong>Utente</strong>” indica la persona fisica, collaboratore e/o dipendente di 3CEngineering s.r.l.s. che installa l’Applicazione sul proprio Smartphone e che accetta le presenti Condizioni. L’Utente può agire unicamente per scopi legati all’attività professionale svolta per conto e/o alle dipendenze e/o in collaborazione con 3CEngineering s.r.l.s..</p>
    <p>I termini al singolare comprendono il plurale e viceversa, a seconda di quanto permesso o richiesto dal contesto.</p>
    <ol start="3">
    <li><strong> FUNZIONAMENTO DELL’APPLICAZIONE </strong></li>
    </ol>
    <p><strong>3.1. Requisiti per il funzionamento dell’Applicazione </strong></p>
    <p>3.1.1. Il funzionamento dell’Applicazione richiede congiuntamente:</p>
    <ul>
    <li>il possesso di uno smartphone o di un pc o di un mac dotato di connessione internet;</li>
    <li>il <em>download </em>gratuito dell’Applicazione da Apple App Store o Google Play;</li>
    <li>l’installazione corretta dell’Applicazione;</li>
    <li>la registrazione Utente.</li>
    </ul>
    <p>3.1.2. L’Applicazione, una volta installata, prevede la possibilità, per l’Utente, di utilizzare le funzioni solo ad avvenuta registrazione.</p>
    <p>3.1.3. L’Utente, al fine di utilizzare tutte le funzioni dell’Applicazione dovrà effettuare la registrazione al servizio, inserendo i dati richiesti ed asccettando termini d’uso, condizioni e privacy policy.</p>
    <p>3.1.4. Il funzionamento dell’Applicazione e le attività ad essa connesse sono strettamente legati all’attività professionale svolta dall’Utente per conto e/o alle dipendenze e/o in collaborazione con 3CEngineering s.r.l.s..</p>
    <p>3.1.5. Per impostazione predefinita, l’Applicazione effettuerà il download automatico degli Aggiornamenti.</p>
    <p><strong><u>3.2. Termini e modalità di funzionamento dell’Applicazione</u></strong></p>
    <p>3.2.1. Attraverso l’Applicazione installata sul proprio smartphone, l’Utente, dovrà caricare i dati relativi alla propria prestazione lavorativa quali, in via meramente enumerativa e non esaustiva, gli orari di lavoro, l’utente cui si rivolge la prestazione, la tipologia della prestazione, il luogo presso cui viene svolta la prestazione (Istituzione scolastica, plesso scolastico, ecc.).</p>
    <p>3.2.2. I dati relativi alla prestazione svolta dovranno essere inseriti unicamente tramite Applicazione a decorrere dal 1 ottobre 2023.</p>
    <p>3.2.3. L’Utente ha l’obbligo di inserire i dati lavorati nella settimana di competenza entro massimo le ore 23:30 del giorno di domenica della medesima settimana di competenza.</p>
    <p>3.2.4. È espressamente inteso che la Società si riserva tutti i diritti non specificamente licenziati in conformità alle presenti Condizioni.</p>
    <p><strong>&nbsp;</strong></p>
    <ol start="4">
    <li><strong> LICENZA</strong></li>
    </ol>
    <p><strong>4.1. Concessione di Licenza</strong>.</p>
    <p>4.1.1. Con le presenti Condizioni, la Società concede all’Utente il diritto non esclusivo e non trasferibile di utilizzare l’Applicazione conformemente alle modalità di uso espressamente consentite dall’Applicazione e disciplinate dalle presenti Condizioni. L’Utente dovrà attenersi a qualsiasi limitazione tecnica presente nell’Applicazione senza alterare in alcun modo l’Applicazione stessa. In particolare, l’Utente si asterrà, a titolo esemplificativo, dalle seguenti attività:</p>
    <ul>
    <li>aggirare le limitazioni tecniche presenti nell’Applicazione;</li>
    <li>decodificare, decompilare l’Applicazione;</li>
    <li>rimuovere o modificare gli avvisi di <em>copyright </em>o l’identificazione dell’autore così come appaiono nell’Applicazione;</li>
    </ul>
    <p><strong>4.2. Durata. </strong>L’attivazione avviene al momento in cui l’Applicazione viene installata per la prima volta dall’Utente sul proprio smartphone dopo aver eseguito la registrazione.</p>
    <p><strong>4.3. Restrizioni alla licenza. </strong></p>
    <p>4.3.1. L’Utente potrà installare e utilizzare l’Applicazione su qualsiasi smartphone compatibile di sua proprietà per tutto il periodo di licenza, anche disinstallando l’Applicazione dal dispositivo su cui la abbia precedentemente installata e installandola su un altro smartphone. Sono fatte salve, in ogni caso, le eventuali limitazioni tecniche applicate dai gestori delle piattaforme Apple App Store e Google Play.</p>
    <p>4.3.2. Salvo quanto espressamente consentito dalle presenti Condizioni, l’Utente non può: (i) affittare, concedere in prestito, rivendere, sublicenziare o in altro modo distribuire l’Applicazione; (ii) usare l’Applicazione per fornire o rendere operativi servizi di marketing, formazione e outsourcing o consulenza, o qualsiasi altro servizio commerciale; (iii) usare l’Applicazione per sviluppare un prodotto che sia in concorrenza con l’Applicazione; (iv) utilizzare strumenti di accesso automatico, come <em>spider </em>o <em>robot</em>, oppure effettuare attività di <em>scraping </em>in relazione all’Applicazione o raccogliere informazioni e dati dall’Applicazione; (v) consentire a terzi l’accesso o l’utilizzo dell’Applicazione, salvo quanto eventualmente previsto nelle presenti Condizioni; (vi) distribuire o pubblicare le proprie credenziali di accesso all’Applicazione; o (vii) usare credenziali di accesso non autorizzate.</p>
    <ol start="5">
    <li><strong> ULTERIORI OBBLIGHI DELL’UTENTE</strong></li>
    </ol>
    <p><strong>5.1. </strong>In aggiunta a quanto previsto nella clausola 4 che precede, considera la particolare natura cui è destinata l’Applicazione ed i dati trattati in essa inseriti, l’Utente si impegna ad utilizzare l’Applicazione in conformità alla normativa applicabile ed in particolare:</p>
    <ul>
    <li>dichiara, consapevole della responsabilità in caso di dichiarazioni mendaci, di formazione o uso di atti falsi, ai sensi e per gli effetti dell’art. 46 del D.P.R. 445 del 28 dicembre 2000, che i dati che verranno inseriti saranno veritieri e rispondenti alle effettive prestazioni rese, assumendosene l’Utente ogni responsabilità a riguardo;</li>
    <li>si obbliga a fornire informazioni esatte, aggiornate, non confidenziali e non lesive della proprietà intellettuale o di altri diritti di terzi;</li>
    <li>si obbliga ad inserire i dati relativi alla prestazione espletata nella settimana di competenza entro massimo le ore 23:30 della domenica della medesima settimana di competenza;</li>
    <li>si obbliga a custodire con diligenza le credenziali utilizzate per accedere all’Applicazione, della cui sicurezza e segretezza l’Utente è l’unico responsabile. Qualora l’Utente sospetti che sia stato effettuato un accesso non autorizzato al proprio account da parte di terzi, l’Utente è pregato di contattare immediatamente la Società, e di modificare la propria password nel più breve tempo possibile. La Società non assume alcuna responsabilità per qualunque danno derivante dalla non diligente custodia delle credenziali di accesso utilizzate dall’Utente per accedere all’Applicazione;</li>
    <li>si obbliga a non riprodurre, pubblicare, comunicare al pubblico con qualsiasi mezzo di comunicazione, trasmettere, eseguire, rappresentare, distribuire, esporre, rimuovere, cancellare, aggiungere ovvero in qualsiasi altro modo modificare l’Applicazione o i Contenuti, salvo che per finalità connesse all’uso personale e nei limiti previsti dalle vigenti leggi applicabili, nonché creare opere ad essi ispirate o derivate dagli stessi, commercializzare l’Applicazione e/o i Contenuti e/o prendere parte attivamente alla loro commercializzazione;</li>
    <li>si obbliga a non danneggiare, interferire o interrompere l’accesso all’Applicazione e ai Contenuti, ovvero compiere azioni che potrebbero alterare la funzionalità o interferire con l’accesso di altri Utenti all’Applicazione o ai Contenuti, nonché utilizzare l’Applicazione o i Contenuti in modo illegale o dannoso per la Società, o per qualsiasi altro Utente.</li>
    </ul>
    <ol start="6">
    <li><strong> PROPRIETÀ INTELLETTUALE </strong></li>
    </ol>
    <p>Tutti i diritti e titoli di proprietà intellettuale sull’Applicazione sono e rimarranno della Società e/o dei suoi licenzianti e nessun diritto o titolarità sugli stessi viene con le presenti Condizioni trasferito all’Utente. Fatto salvo quanto previsto dalle clausole 4 e 5 che precedono, l’Utente non avrà alcun diritto di modificare (anche ai fini della correzione di errore), adattare, o convertire l’Applicazione o creare opere derivate dall’Applicazione, eccetto quanto necessario per configurare l’Applicazione mediante menù, opzioni e strumenti ove previsti a tali fini e contenuti nell’Applicazione stessa. Fatto salvo quanto permesso ai sensi della legge applicabile, sono proibite le attività di <em>reverse compiling </em>(incluso il <em>reverse compiling </em>diretto a consentire l’interoperabilità), <em>reverse engineering </em>e altra estrapolazione del codice sorgente dell’Applicazione.</p>
    <ol start="7">
    <li><strong> GARANZIE </strong></li>
    </ol>
    <p><strong>7.1. </strong>L’Utente, ai sensi degli artt. 129 e seguenti del Codice del Consumo, ove applicabile, avrà diritto, in caso di non conformità dell’Applicazione alle presenti Condizioni, al ripristino senza spese della conformità dell’Applicazione mediante riparazione o sostituzione, ovvero alla risoluzione delle presenti Condizioni. L’Utente decadrà dai diritti previsti dall’articolo 130, comma 2, del Codice del Consumo, ove applicabile, se non denuncerà alla Società il difetto di conformità entro il termine di due (2) mesi dalla data in cui ha scoperto il difetto.</p>
    <p><strong>7.2. </strong>Nei limiti consentiti dalla legge e ferme restando le garanzie fornite ai sensi delle presenti Condizioni, la Società non garantisce che il funzionamento dell’Applicazione sia ininterrotto o privo di errori.</p>
    <p><strong>7.3. </strong>Escluse le garanzie specificate in questo articolo e nella misura massima consentita dalla legge, la Società non riconosce altre garanzie. Questo articolo sopravvivrà alla cessazione delle presenti Condizioni.</p>
    <ol start="8">
    <li><strong> LIMITAZIONE DI RESPONSABILITÀ </strong></li>
    </ol>
    <p><strong>8.1. </strong>I Contenuti dell’Applicazione sono di proprietà di 3CEngineering s.r.l.s. o dei suoi licenzianti. La Società non potrà essere ritenuta responsabile per eventuali danni dovessero derivare agli Utenti in relazione ai Contenuti.</p>
    <p><strong>8.2. </strong>La Società non garantisce che il funzionamento dell’Applicazione sia ininterrotto o privo di errori. L’Utente riconosce espressamente che la Società non è responsabile per le azioni, i contenuti, le informazioni o i dati di terze parti – compresi contenuti, azioni, informazioni o dati di Apple, Inc., in quanto gestore della piattaforma Apple App Store, di Google, Inc., in quanto gestore della piattaforma Google Play, o di terzi che forniscano contenuti o servizi a pagamento sull’Applicazione – e l’Utente si impegna a tenere indenne la Società da qualsiasi pretesa, reclamo, richiesta di danno connessa a contenuti, azioni, informazioni o dati di terze parti.</p>
    <p><strong>8.3. </strong>La Società non potrà essere in alcun modo ritenuta responsabile delle conseguenze derivanti dalla violazione, da parte dell’Utente, degli obblighi di cui alle presenti Condizioni, inclusa la violazione, da parte dell’Utente, della normativa applicabile al trattamento dei dati personali di terzi di cui egli venga in possesso nel corso dell’utilizzo dell’Applicazione. L’Utente si impegna a tenere indenne la Società da ogni e qualunque responsabilità derivante dalla violazione, da parte dell’Utente stesso, delle presenti Condizioni.</p>
    <p><strong>8.4. </strong>Nulla di quanto stabilito nelle presenti Condizioni implica in alcun modo che la Società si obblighi né possa essere considerata tenuta allo svolgimento di un’attività di sorveglianza preventiva sulle informazioni che memorizza e trasmette, né a ricercare attivamente fatti o circostanze che indichino la presenza di attività legalmente o contrattualmente illecite. Sono fatti comunque salvi i diritti e le facoltà riconosciute alla Società ai sensi della legislazione comunitaria e nazionale applicabile.</p>
    <ol start="9">
    <li><strong> RISOLUZIONE </strong></li>
    </ol>
    <p><strong>9.1. </strong>La Società potrà in ogni momento risolvere le presenti Condizioni, con effetto immediato, con semplice comunicazione scritta all’Utente nel caso in cui quest’ultimo violi uno qualsiasi degli obblighi previsti dalle clausole 4, 5 e 6 che precedono. La risoluzione non limiterà nessuna parte dall’esperire altri rimedi disponibili.</p>
    <ol start="10">
    <li><strong> RECESSO </strong></li>
    </ol>
    <p><strong>10.1. </strong>Effettuando il <em>download </em>dell’Applicazione, l’Utente acconsente espressamente all’esecuzione, da parte della Società, della fornitura dell’Applicazione ed accetta che il diritto di recesso è escluso rispetto alla suddetta fornitura ai sensi dell’articolo 59, comma 1, lettera o), del Codice del Consumo.</p>
    <ol start="11">
    <li><strong> DATI PERSONALI </strong></li>
    </ol>
    <p><strong>11.1. </strong>La Società tratterà i dati personali dell’Utente secondo la modalità ed i termini specificati nell’<em>Informativa Privacy </em>(https://www.cooperativeincloud.it/gdpr), allegata alle presenti Condizioni, ferme restando le informazioni ed indicazioni fornite agli Utenti nell’ambito della <em>Privacy e Cookie Policy </em>del sito web di Cooperativeincloud. L’Utente dichiara, altresì, di essere informato/a, ai sensi e per gli effetti di cui al D. Lgs. n.196/2003 e del Reg. UE n.679/2016 (GDPR), che i dati raccolti saranno trattati, anche con strumenti informatici, esclusivamente nell’ambito del procedimento per il quale la presente dichiarazione viene resa.</p>
    <ol start="12">
    <li><strong> CLAUSOLE GENERALI </strong></li>
    </ol>
    <p><strong>12.1. Legge Applicabile e Foro Competente</strong>. Per qualsiasi controversia inerente l’interpretazione o l’esecuzione delle presenti Condizioni e dei contratti che ad esso facciano riferimento, sarà applicabile esclusivamente la legge Italiana, con esclusione delle norme di diritto internazionale privato eventualmente applicabili. Ogni controversia derivante dall’interpretazione, validità, esecuzione, adempimento o risoluzione delle presenti Condizioni o comunque inerente al o derivante dalle Condizioni sarà soggetta alla competenza esclusiva ed inderogabile del giudice del luogo ove la Società ha sede legale.</p>
    <p><strong>12.2. Integrazioni e Modifiche</strong>. Le presenti Condizioni costituiscono l’intero accordo tra le Parti e sostituiscono qualsiasi precedente o contemporanea condizione, accordo o dichiarazione, sia verbale che scritta, relativa alla materia oggetto delle presenti Condizioni. Le presenti Condizioni non potranno essere modificate o nessun termine o condizione rinunciata se non con un documento scritto e sottoscritto da un rappresentante debitamente autorizzato di ciascuna parte.</p>
    <p><strong>12.3. Cessione</strong>. Le presenti Condizioni e qualsiasi diritto e obbligazione, non potranno essere cedute dall’Utente senza il preventivo consenso scritto della Società.</p>
    <p><strong>12.4. Conservazione delle Condizioni</strong>. Qualora una qualsiasi disposizione delle presenti Condizioni sia dichiarata illecita, nulla, o inefficace, tale disposizione sarà modificata in modo da renderla efficace o altrimenti eliminata, e non inficerà la validità e l’efficacia delle restanti disposizioni.</p>
    <p><strong>12.5. Forza maggiore. </strong>Nessuna parte sarà considerata inadempiente ai sensi delle presenti Condizioni, a causa di un ritardo o della mancata esecuzione della sua obbligazione, risultante da qualsiasi evento al di fuori del suo ragionevole controllo (cd. “Forza Maggiore”) a condizione che dia comunicazione in modo ragionevolmente tempestivo della condizione di Forza Maggiore e compia ragionevoli sforzi per ridurre il ritardo o l’inadempimento.</p>
    </div>
    <div class="fusion-text fusion-text-2"><h2 style="--fontSize: 48; line-height: 1.17;" data-fontsize="48" data-lineheight="56.16px" class="fusion-responsive-typography-calculated"><strong>PRIVACY POLICY</strong></h2>
    <p>In ottemperanza degli obblighi derivanti dalla normativa nazionale (D. Lgs 30 giugno 2003 n. 196, Codice in materia di protezione dei dati personali) e comunitaria, (Regolamento europeo per la protezione dei dati personali n. 679/2016, GDPR) e successive modifiche, il presente sito rispetta e tutela la riservatezza dei visitatori e degli utenti, ponendo in essere ogni sforzo possibile e proporzionato per non ledere i diritti degli utenti.</p>
    <p>La presente privacy policy si applica esclusivamente alle attività online del presente sito ed è valida per i visitatori/utenti del sito. Non si applica alle informazioni raccolte tramite canali diversi dal presente sito web. Lo scopo dell’informativa privacy è di fornire la massima trasparenza relativamente alle informazioni che il sito raccoglie e come le usa.</p>
    <p><strong>Base giuridica del trattamento</strong></p>
    <p><strong>Il presente sito tratta i dati in base al consenso.</strong> Con l’uso o la consultazione del presente sito i visitatori e gli utenti approvano esplicitamente la presente informativa privacy e acconsentono al trattamento dei loro dati personali in relazione alle modalità e alle finalità di seguito descritte, compreso l’eventuale diffusione a terzi se necessaria per l’erogazione di un servizio. Il conferimento dei dati e quindi il Consenso alla raccolta e al trattamento dei dati è facoltativo, l’Utente può negare il consenso e può revocare in qualsiasi momento un consenso già fornito (tramite il <a href="https://protezionedatipersonali.it/amministratore-sito" target="_blank" rel="noopener">link Contatti</a> a fondo pagina). Tuttavia negare il consenso può comportare l’impossibilità di erogare alcuni servizi e l’esperienza di navigazione nel sito potrebbe essere compromessa.</p>
    <p>A partire dal 25 maggio 2018 (data di entrata in vigore del GDPR), il presente sito tratterà alcuni dei dati in base ai legittimi interessi del titolare del trattamento.</p>
    <p><strong>Dati raccolti e finalità</strong></p>
    <p>Come tutti i siti web anche il presente sito fa uso di log files nei quali vengono conservate<strong> informazioni raccolte in maniera automatizzata</strong> durante le visite degli utenti. Le informazioni raccolte potrebbero essere le seguenti:</p>
    <p>– indirizzo internet protocol (IP);</p>
    <p>– tipo di browser e parametri del dispositivo usato per connettersi al sito;</p>
    <p>– nome dell’internet service provider (ISP);</p>
    <p>– data e orario di visita;</p>
    <p>– pagina web di provenienza del visitatore (referral) e di uscita;</p>
    <p>– eventualmente il numero di click.</p>
    <p>Le suddette informazioni sono trattate in forma automatizzata e raccolte in forma esclusivamente aggregata al fine di verificare il corretto funzionamento del sito e per motivi di sicurezza (dal 25 maggio 2018 tali informazioni saranno trattate in base ai legittimi interessi del titolare).</p>
    <p>A fini di sicurezza (filtri antispam, firewall, rilevazione virus), i dati registrati automaticamente possono eventualmente comprendere anche dati personali come l’indirizzo IP, che potrebbe essere utilizzato, conformemente alle leggi vigenti in materia, al fine di bloccare tentativi di danneggiamento al sito medesimo, di recare danno ad altri utenti o, comunque, attività dannose o costituenti reato. Tali dati non sono mai utilizzati per l’identificazione o la profilazione dell’utente, ma solo a fini di tutela del sito e dei suoi utenti (dal 25 maggio 2018 tali informazioni saranno trattate in base ai legittimi interessi del titolare).</p>
    <p>Qualora il sito consenta l’inserimento di commenti, oppure in caso di specifici servizi richiesti dall’utente, il sito rileva automaticamente e registra alcuni <strong>dati identificativi dell’utente</strong>, compreso l’indirizzo mail. Tali dati si intendono <strong>volontariamente forniti dall’utente al momento della richiesta di erogazione del servizio</strong>. Inserendo un commento o altra informazione l’utente accetta espressamente l’informativa privacy e in particolare acconsente che i contenuti inseriti siano liberamente diffusi anche a terzi.</p>
    <p>I dati ricevuti verranno utilizzati esclusivamente per l’erogazione del servizio richiesto e per il solo tempo necessario per la fornitura del servizio.</p>
    <p>Le informazioni che gli utenti del sito riterranno di rendere pubbliche tramite i servizi e gli strumenti messi a disposizione degli stessi, sono fornite dall’utente consapevolmente e volontariamente, esentando il presente sito da qualsiasi responsabilità in merito ad eventuali violazioni delle leggi. Spetta all’utente verificare di avere i permessi per l’immissione di dati personali di terzi o di contenuti tutelati dalle norme nazionali ed internazionali.</p>
    <p>I dati raccolti dal sito durante il suo funzionamento sono utilizzati esclusivamente per le finalità sopra indicate e conservati per il tempo strettamente necessario a svolgere le attività precisate. In ogni caso i dati rilevati dal sito non saranno forniti mai a terzi, per nessuna ragione, a meno che non si tratti di legittima richiesta da parte dell’autorità giudiziaria e nei soli casi previsti dalla legge.</p>
    <p>I dati utilizzati a fini di sicurezza (blocco tentativi di danneggiamento del sito) sono conservati per 7 giorni.</p>
    <p><strong>Luogo del trattamento</strong></p>
    <p>I dati raccolti dal sito sono trattati presso la sede del Titolare del Trattamento e presso il datacenter del web Hosting. Il web hosting, che è responsabile del trattamento dei dati, elaborando i dati per conto del titolare, si trova nello Spazio Economico Europeo e agisce in conformità delle norme europee.</p>
    <p><strong>Cookie</strong></p>
    <p>Come è d’uso su tutti i siti web, anche questo sito fa uso di cookies, piccoli file di testo che consentono di conservare informazioni sulle preferenze dei visitatori, per migliorare le funzionalità del sito, per semplificarne la navigazione automatizzando le procedure (es. Login, lingua sito) e per l’analisi dell’uso del sito.</p>
    <p>I <strong>cookie di sessione</strong> sono essenziali per poter distinguere tra gli utenti collegati, e sono utili per evitare che una funzionalità richiesta possa essere fornita all’utente sbagliato, nonché per fini di sicurezza per impedire attacchi informatici al sito. I cookie di sessione non contengono dati personali e durano per la sola sessione in corso, cioè fino alla chiusura del browser. Per essi non occorre consenso.</p>
    <p>I <strong>functionality cookie</strong> utilizzati dal sito sono strettamente necessari per l’uso del sito, in particolare sono collegati ad una espressa richiesta di funzionalità da parte dell’utente (come il Login), per i quali non occorre alcun consenso.</p>
    <p><strong> </strong></p>
    <p><strong><u>Utilizzando il sito il visitatore acconsente </u></strong></p>
    <p><strong><u>espressamente all’uso dei cookies.</u></strong></p>
    <p><strong>Disabilitazione cookie</strong></p>
    <p>I cookies sono collegati al browser utilizzato e POSSONO ESSERE DISABILITATI DIRETTAMENTE DAL BROWSER, così rifiutando/revocando il consenso all’uso dei cookies. Occorre tenere presente che la disabilitazione dei cookies potrebbe impedire il corretto utilizzo di alcune funzioni del sito stesso.</p>
    <p>Le istruzioni per la disabilitazione dei cookies si trovano alle seguenti pagine web:</p>
    <p><a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie" target="_blank" rel="noopener">Mozilla Firefox</a> – <a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener">Microsoft Internet Explorer</a> – <a href="https://support.microsoft.com/it-it/help/4027947/windows-delete-cookies" target="_blank" rel="noopener">Microsoft Edge</a> – <a href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank" rel="noopener">Google Chrome</a> – <a href="http://help.opera.com/Windows/10.00/it/cookies.html" target="_blank" rel="noopener">Opera</a> – <a href="https://support.apple.com/it-it/HT201265" target="_blank" rel="noopener">Apple Safari</a></p>
    <p><strong>Cookies di terze parti</strong></p>
    <p>Il presente sito funge anche da<strong> intermediario per cookies di terze parti</strong>, utilizzati per poter fornire ulteriori servizi e funzionalità ai visitatori e per migliorare l’uso del sito stesso, come i pulsanti per i social, oppure video. Questo sito non ha alcun controllo sui cookie delle terze parti, interamente gestiti dalle terze parti. In conseguenza di ciò le informazioni sull’uso dei detti cookies e sulle finalità degli stessi, nonché sulle modalità per l’eventuale disabilitazione, sono fornite direttamente dalle terze parti alle pagine indicate di seguito.</p>
    <p>In particolare il presente sito utilizza cookies delle seguenti terze parti:</p>
    <p>– <strong>Google Analytics</strong>: uno strumento di analisi di Google che attraverso l’uso di cookies (performance cookies), raccoglie dati di navigazione anonimi (IP troncati all’ultimo ottetto) ed esclusivamente aggregati allo scopo di esaminare l’uso del sito da parte degli utenti, compilare report sulle attività nel sito e fornire altre informazioni, compreso il numero dei visitatori e le pagine visitate. Google può anche trasferire queste informazioni a terzi ove ciò sia imposto dalla legge o laddove tali terzi trattino le suddette informazioni per conto di Google. Google non assocerà l’indirizzo Ip a nessun altro dato posseduto da Google. I dati trasmessi a Google sono depositati presso i server di Google negli Stati Uniti.</p>
    <p>In base a specifico accordo con Google, che è designato quale responsabile del trattamento dei dati, questi si impegna a trattare i dati in base alle richieste del Titolare (vedi in fondo all’informativa), impartite tramite le impostazioni del software. In base a tali impostazioni le opzioni pubblicitarie e di condivisione dei dati sono disattive.</p>
    <p>Ulteriori informazioni sui cookies di Google Analytics si trovano alla pagina <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank" rel="noopener">Google Analytics Cookie Usage on Websites</a>.</p>
    <p>L’utente può disabilitare in modo selettivo la raccolta di dati da parte di Google Analytics installando sul proprio browser l’<a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener">apposito componente fornito da Google</a> (opt out).</p>
    <p>– <strong>Youtube</strong>: una piattaforma di proprietà di Google, per la condivisione di video, che utilizza cookie per raccogliere informazioni degli utenti e dei dispositivi di navigazione. I video presenti nel sito non veicolano cookie all’accesso della pagina, essendo stata impostata l’opzione “<a href="https://support.google.com/youtube/answer/171780?expand=PrivacyEnhancedMode#privacy" target="_blank" rel="noopener">privacy avanzata (no cookie)</a>” in base alla quale YouTube non memorizza le informazioni sui visitatori a meno che essi non riproducano volontariamente il video.</p>
    <p>cookie: test_cookie.doubleclick.net non è un cookie permanente, ma è utilizzato per verificare se il browser dell’utente supporta i cookie.</p>
    <p>Per ulteriori informazioni sull’uso dei dati e sul loro trattamento da parte di Google si raccomanda di prendere visione delle informazioni all’apposita <a href="https://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noopener">pagina predisposta da Google</a>, e alla pagina sulle <a href="https://policies.google.com/privacy/partners?hl=it" target="_blank" rel="noopener">Modalità di utilizzo dei dati da parte di Google quando si utilizzano siti o app dei partner</a>.</p>
    <p><strong>Plugin Social Network</strong></p>
    <p>Il presente sito incorpora anche plugin e/o bottoni per i social network, al fine di consentire una facile condivisione dei contenuti sui vostri social network preferiti. Tali plugin sono programmati in modo da non impostare alcun cookie all’accesso della pagina, per salvaguardare la privacy degli utenti. Eventualmente i cookie vengono impostati, se così previsto dai social network, solo quando l’utente fa effettivo e volontario uso del plugin. Si tenga presente che se l’utente naviga essendo loggato nel social network allora ha già acconsentito all’uso dei cookie veicolati tramite questo sito al momento dell’iscrizione al social network.</p>
    <p>La raccolta e l’uso delle informazioni ottenute a mezzo del plugin sono regolati dalle rispettive informative privacy dei social network, alle quali si prega di fare riferimento.</p>
    <p>– <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener">Facebook</a> – (<a href="https://www.facebook.com/help/cookies/" target="_blank" rel="noopener">link informativa cookie</a>)</p>
    <p>– <a href="https://twitter.com/privacy?lang=it" target="_blank" rel="noopener">Twitter</a> – (<a href="https://support.twitter.com/articles/20170519-uso-dei-cookie-e-di-altre-tecnologie-simili-da-parte-di-twitter" target="_blank" rel="noopener">link informativa cookie</a>)</p>
    <p>– <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener">LinkedIn</a> – (<a href="https://www.linkedin.com/legal/cookie-policy" target="_blank" rel="noopener">link informativa cookie</a>)</p>
    <p>– <a href="http://www.google.com/intl/it/policies/privacy/" target="_blank" rel="noopener">Google+</a> – (<a href="http://www.google.it/intl/it/policies/technologies/cookies/" target="_blank" rel="noopener">link informativa cookie</a>).</p>
    <p><strong>Trasferimento di dati in paesi extra UE</strong></p>
    <p>Il presente sito potrebbe condividere alcuni dei dati raccolti con servizi localizzati al di fuori dell’area dell’Unione Europea. In particolare con Google, Facebook e Microsoft (LinkedIn) tramite i social plugin e il servizio di Google Analytics. Il trasferimento è autorizzato in base a specifiche <a href="http://www.garanteprivacy.it/home/provvedimenti-normativa/normativa/normativa-comunitaria-e-intenazionale/trasferimento-dei-dati-verso-paesi-terzi" target="_blank" rel="noopener">decisioni dell’Unione Europea e del Garante per la tutela dei dati personali</a>, in particolare la decisione 1250/2016 (<a href="http://eur-lex.europa.eu/legal-content/IT/TXT/?uri=CELEX%3A32016D1250" target="_blank" rel="noopener">Privacy Shield</a> – qui la <a href="http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/5306161" target="_blank" rel="noopener">pagina informativa del Garante italiano</a>), per cui non occorre ulteriore consenso. Le aziende sopra menzionate garantiscono la propria adesione al Privacy Shield.</p>
    <p><strong>Misure di sicurezza</strong></p>
    <p>Il presente sito tratta i dati degli utenti in maniera lecita e corretta, adottando le opportune misure di sicurezza volte ad impedire accessi non autorizzati, divulgazione, modifica o distruzione non autorizzata dei dati. Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al titolare, in alcuni casi, potrebbero avere accesso ai dati categorie di incaricati coinvolti nell’organizzazione del sito (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione).</p>
    <p><strong>Diritti dell’utente</strong></p>
    <p>Ai sensi del Regolamento europeo 679/2016 (GDPR) e della normativa nazionale, l’Utente può, secondo le modalità e nei limiti previsti dalla vigente normativa, esercitare i seguenti diritti:</p>
    <p>– richiedere la conferma dell’esistenza di dati personali che lo riguardano (diritto di accesso);</p>
    <p>– conoscerne l’origine;</p>
    <p>– riceverne comunicazione intelligibile;</p>
    <p>– avere informazioni circa la logica, le modalità e le finalità del trattamento;</p>
    <p>– richiederne l’aggiornamento, la rettifica, l’integrazione, la cancellazione, la trasformazione in forma anonima, il blocco dei dati trattati in violazione di legge, ivi compresi quelli non più necessari al perseguimento degli scopi per i quali sono stati raccolti;</p>
    <p>– nei casi di trattamento basato su consenso, ricevere al solo costo dell’eventuale supporto, i suoi dati forniti al titolare, in forma strutturata e leggibile da un elaboratore di dati e in un formato comunemente usato da un dispositivo elettronico;</p>
    <p>– il diritto di presentare un reclamo all’Autorità di controllo (Garante Privacy);</p>
    <p>– nonché, più in generale, esercitare tutti i diritti che gli sono riconosciuti dalle vigenti disposizioni di legge.</p>
    <p>Le richieste vanno rivolte al Titolare del trattamento.</p>
    <p>Nel caso in cui i dati siano trattati in base ai <strong>legittimi interessi</strong> sono comunque garantiti i diritti degli interessati al trattamento (tranne il diritto alla portabilità che non è previsto dalle norme), in particolare il diritto di opposizione al trattamento che può essere esercitato inviando una richiesta al titolare del trattamento.</p>
    <p><strong>Titolare del trattamento</strong></p>
    <p>Titolare del trattamento ai sensi delle leggi vigenti è l’amministratore del sito, contattabile tramite la sezione <a href="https://protezionedatipersonali.it/amministratore-sito" target="_blank" rel="noopener">CONTATTI</a>.</p>
    <p>Il web hosting si trova nello Spazio Economico Europeo e agisce in conformità delle norme europee.</p>
    <p><strong>Aggiornamenti</strong></p>
    <p>La presente privacy policy è aggiornata alla data del 23 marzo 2020.</p>
    <p>______________________</p>
    <p><strong>COOKIE POLICY</strong></p>
    <p><strong> </strong></p>
    <p>In ottemperanza al Regolamento Europeo n.679/2016 e s.m.i. 3CEngineering s.r.l.s.  la informa sul trattamento dei dati rilevati nell’ambito della navigazione del sito web</p>
    <p><strong><u>Dati di navigazione</u></strong></p>
    <p>Il normale funzionamento del sito web <a href="http://www.cooperativeincloud.it">www.cooperativeincloud.it</a>  comporta la rilevazione di alcuni dati (la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet) non associati a utenti direttamente identificabili. Tra i dati raccolti sono ricompresi gli indirizzi IP e i nomi di dominio dei computer utilizzati da chi si connette al sito, <a href="https://www.cooperativeincloud.it">https://www.cooperativeincloud.it</a> .Questi dati vengono trattati, per il tempo strettamente necessario, al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il regolare funzionamento.</p>
    <p><strong><u>Cookie</u></strong></p>
    <p><strong><em>Cosa sono i cookie</em></strong></p>
    <p>Molti siti web raccolgono piccole quantità di informazioni riguardo alla sua attività sul sito stesso. Tali informazioni sono racchiuse in un file di testo identificato come “<em>cookie</em>“, memorizzato sul Suo computer. Al fine di garantirle sempre la migliore navigazione possibile, il nostro sito offre le migliori prestazioni con i cookie abilitati. Grazie ai cookie è possibile “ricordarci” di lei quando torna sul nostro sito, così come identificare e risolvere errori – fornendole una navigazione più veloce e indicata per lei.</p>
    <p><strong><em>Tipi di cookie usati su questo sito </em></strong></p>
    <p>Il nostro sito utilizza i seguenti tipi di cookie:</p>
    <ul>
    <li><em>cookie tecnici di navigazione o sessione</em>, che garantiscono la normale navigazione e fruizione del sito web (permettendo, ad esempio, di realizzare un acquisto o autenticarsi per accedere ad aree riservate). Tali cookie vengono eliminati ogni volta che il browser viene chiuso;</li>
    <li><em>cookie tecnici di funzionalità</em>, che permettono all’utente la navigazione in funzione di una serie di criteri selezionati (ad esempio, la lingua, i prodotti selezionati per l’acquisto) al fine di migliorare il servizio reso allo stesso; questi cookie permettono al sito di “ricordare” le scelte da te effettuate (come la tua username, la lingua o la regione in cui ti trovi) e quindi fornire funzionalità più complesse ed indicate per te. Tali cookie una volta chiuso il browser non vengono distrutti ma rimangono fino ad una data di scadenza preimpostata.</li>
    </ul>
    <p><u>Questo sito non utilizza Cookie di profilazione di prima parte</u>. I cookie di profilazione sono volti a creare profili relativi all’utente e vengono utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dallo stesso nell’ambito della navigazione in rete. In ragione della particolare invasività che tali dispositivi possono avere nell’ambito della sfera privata degli utenti, la normativa europea e italiana prevede che l’utente debba essere adeguatamente informato sull’uso degli stessi ed esprimere così il proprio valido consenso. I cookie di profilazione configurano un processo decisionale automatizzato.</p>
    <p><a name="_Toc401161337"></a><strong><em>Cookie di terze parti</em></strong></p>
    <p>Questo sito Web ospita, oltre ai Cookie proprietari, anche Cookie di terze parti, cioè gestiti da altre organizzazioni. Un esempio importante è dato dalla presenza dei “social plugin” per Facebook, Twitter, Google+, video di YouTube, mappe di Google Maps, ecc. Si tratta di porzioni della pagina visitata generate direttamente dai suddetti siti ed ospitati nella pagina del sito ospitante, al fine di condividere dei contenuti sui social network o svolgere altre operazioni.</p>
    <p>Si informa l’utente che la maggior parte dei cookie di profilazione sono usati per individuare il comportamento on line, analizzare gli interessi e personalizzare relative proposte pubblicitarie.</p>
    <p>Il trattamento dei dati effettuato da terze parti tramite l’utilizzo dei cookie è disciplinato dalle relative informative, di cui, di seguito, riportiamo i link corrispondenti:</p>
    <ul>
    <li>Facebook – informativa: <a href="https://www.facebook.com/about/privacy/update" target="_blank" rel="noopener"><em><u>https://www.facebook.com/about/privacy/update</u></em></a></li>
    <li>Linkedin – informativa: <a href="https://www.linkedin.com/legal/privacy-policy?_l=it_IT" target="_blank" rel="noopener"><em><u>https://www.linkedin.com/legal/privacy-policy?_l=it_IT</u></em></a></li>
    <li><em>Twitter – informativa: </em><a href="https://twitter.com/it/privacy" target="_blank" rel="noopener"><em><u>https://twitter.com/it/privacy</u></em></a></li>
    </ul>
    <p><a name="_Toc401161340"></a><strong><em>Gestione dei cookie</em></strong></p>
    <p>La scelta di accettare o meno i cookie è rimessa all’utente che può anche utilizzare le impostazioni del proprio <em>browser</em>. Tuttavia la disabilitazione totale o parziale dei cookie tecnici può compromettere l’utilizzo delle funzionalità del sito, mentre la disabilitazione dei cookie “terze parti” non pregiudica in alcun modo la navigabilità.</p>
    <p>I principali <em>browser</em> permettono di definire impostazioni diverse per i cookie proprietari e per quelli di terze parti.</p>
    <p>Utilizzando i link sottostanti è possibile accedere alle aree dedicate dei principali browser dove si potrà definire se accettare o meno i diversi tipi di cookie e procedere alla loro rimozione <u>parziale</u> o <u>totale</u>.</p>
    <ul>
    <li><a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener"><u>Internet Explorer</u></a></li>
    <li><a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie" target="_blank" rel="noopener"><u>Firefox</u></a></li>
    <li><a href="https://support.apple.com/kb/ph21411?locale=it_IT" target="_blank" rel="noopener"><u>Safari</u></a></li>
    <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=it" target="_blank" rel="noopener"><u>Chrome</u></a></li>
    <li><a href="http://help.opera.com/opera/Windows/2393/it/controlPages.html#manageCookies" target="_blank" rel="noopener"><u>Opera</u></a></li>
    </ul>
    <p><strong><em> </em></strong></p>
    <p><strong><em>Cookie trattati </em></strong></p>
    <p>Il presente sito web utilizza i seguenti Cookie:</p>
    <table>
    <tbody>
    <tr>
    <td><strong>Nome</strong></td>
    <td><strong>Finalità</strong></td>
    <td width="142"><strong>Informazioni registrate</strong></td>
    <td width="76"><strong>Durata</strong></td>
    <td width="76"><strong>Tipo di cookie</strong></td>
    <td width="58"><strong>Luogo del trattamento</strong></td>
    </tr>
    <tr>
    <td>SESSxxxID</td>
    <td>Permette di riconoscere la sessione dell’utente</td>
    <td width="142">Identificativo univoco</td>
    <td width="76">Una settimana</td>
    <td width="76">Tecnico</td>
    <td width="58">U.E.</td>
    </tr>
    <tr>
    <td>has_js</td>
    <td>Permette di capire se il browser ha javascript attivato</td>
    <td width="142">1 o 0 a seconda se il browser supporti o meno javascript</td>
    <td width="76">Sessione</td>
    <td width="76">Tecnico</td>
    <td width="58">U.E.</td>
    </tr>
    <tr>
    <td>boost-gzip</td>
    <td>Permette di riconoscere se è attivata la compressione gzip per migliorare la performance del sito</td>
    <td width="142">TRUE o FALSE a seconda se la compressione è attivata o meno</td>
    <td width="76">Due settimane</td>
    <td width="76">Tecnico</td>
    <td width="58">U.E.</td>
    </tr>
    <tr>
    <td>cc_cookie_accept</td>
    <td>Registra il consenso all’uso dei cookie</td>
    <td width="142">Il consenso all’uso dei cookie</td>
    <td width="76">Un mese</td>
    <td width="76">Tecnico</td>
    <td width="58">U.E.</td>
    </tr>
    <tr>
    <td>cc_cookie_decline</td>
    <td>Registra il diniego all’uso dei cookie</td>
    <td width="142">Se l’utente non accetta l’uso dei cookie</td>
    <td width="76">Sessione</td>
    <td width="76">Tecnico</td>
    <td width="58">U.E.</td>
    </tr>
    <tr>
    <td>__utma</td>
    <td>Permette di riconoscere sessione e utente per Google Analytics.</td>
    <td width="142">Identificativo univoco</td>
    <td width="76">Due anni</td>
    <td width="76">Analytics</td>
    <td width="58">U.S.A.</td>
    </tr>
    <tr>
    <td>__utmb</td>
    <td>Permette di riconoscere nuove sessioni/visite.</td>
    <td width="142">Identificativo univoco</td>
    <td width="76">Ogni volta che vengono inviati dati a google analytics</td>
    <td width="76">Analytics</td>
    <td width="58">U.S.A.</td>
    </tr>
    <tr>
    <td>__utmc</td>
    <td>Permette di riconoscere nuove sessioni/visite.</td>
    <td width="142">Identificativo univoco</td>
    <td width="76">Sessione</td>
    <td width="76">Analytics</td>
    <td width="58">U.S.A.</td>
    </tr>
    <tr>
    <td>__utmt</td>
    <td>Usato per controllare la frequenza delle richieste</td>
    <td width="142">1 o 0</td>
    <td width="76">Dieci minuti</td>
    <td width="76">Analytics</td>
    <td width="58">U.S.A.</td>
    </tr>
    <tr>
    <td>__utmz</td>
    <td>Registra l’origine della visita e come l’utente ha raggiunto il sito.</td>
    <td width="142">Identificativo univoco</td>
    <td width="76">Sei mesi</td>
    <td width="76">Analytics</td>
    <td width="58">U.S.A.</td>
    </tr>
    </tbody>
    </table>
    <p>I cookie tecnici e Google analitycs (anonimizzati e non incrociati) presenti sul sito permangono fino a cancellazione da parte dell’utente.</p>
    <p>In relazione ai Cookie di terze parti, si fa riferimento alle relative informative.</p>
    <table>
    <tbody>
    <tr>
    <td><strong>Nome</strong></td>
    <td width="240"><strong>Finalità</strong></td>
    <td width="104"><strong>Fornitore</strong></td>
    <td width="103"><strong>Informazioni registrate</strong></td>
    <td width="47"><strong>Durata</strong></td>
    <td width="66"><strong>Tipo di cookie</strong></td>
    <td width="57"><strong>Luogo del trattamento</strong></td>
    </tr>
    <tr>
    <td>AID</td>
    <td width="240">Set di conversione dei cookie di Google che aiuta gli inserzionisti a determinare quante volte le persone che hanno fatto clic sui loro annunci hanno poi acquistato i loro prodotti.</td>
    <td width="104">googleadservices.com</td>
    <td width="103">Identificativo univoco</td>
    <td width="47">227 giorni</td>
    <td width="66">Cookie di profilazione</td>
    <td width="57">U.S.A.</td>
    </tr>
    </tbody>
    </table>
    <p><strong><em>Base giuridica del trattamento e natura del conferimento</em></strong></p>
    <p>Fatta eccezione per i cookie tecnici strettamente necessari alla normale navigazione (e per i cookie google analitycs anonimizzati e non incrociati) per i quali non è necessario il consenso, per gli altri cookie il conferimento dei dati è rimesso alla volontà dell’interessato che decida di navigare sul sito dopo aver preso visione dell’informativa breve contenuta nell’apposito banner e di usufruire dei servizi che comportano l’installazione di cookie.</p>
    <p>L’interessato può quindi evitare l’installazione dei cookie mantenendo il banner (astenendosi quindi dal chiuderlo cliccando sul tasto “ok”), nonché attraverso le apposite funzioni disponibili sul proprio browser.</p>
    <p><strong><u> </u></strong></p>
    <p><strong><u> </u></strong></p>
    <p><strong><u> </u></strong></p>
    <p><strong><u> </u></strong></p>
    <p><strong><u>Form</u></strong></p>
    <p>In relazione al Form “<strong>Contatti</strong>” i dati saranno acquisiti per rispondere alle richieste degli utenti, in virtù del consenso facoltativo prestato flaggando l’apposito riquadro, per il tempo necessario a dare riscontro. Il mancato conferimento dei dati richiesti o la cancellazione degli stessi comporteranno l’impossibilità di rispondere alle vostre richieste.</p>
    <p>In relazione al Form “<strong>Registrazione</strong>” i dati saranno acquisiti per attivare l’area riservata utente di <strong>Cooperative in Cloud</strong>, in virtù del consenso prestato flaggando l’apposito riquadro, per un periodo di due anni, trascorso il quale verrà richiesto un nuovo consenso; il conferimento è facoltativo, si potrà pertanto decidere di non conferire alcun dato o di negare successivamente la possibilità di trattare dati già forniti: in tal caso, non si potrà ricevere informazioni promozionali su nostre iniziative. Per ogni email inviata verrà data la possibilità di cancellare il proprio account dal servizio di newsletter.</p>
    <p><strong><u>Modalità di trattamento</u></strong></p>
    <p>Il trattamento dei dati sarà effettuato attraverso procedure atte a memorizzare, gestire e trasmettere i dati stessi, con logiche strettamente correlate alle finalità stesse per cui sono stati raccolti, impiegando idonee misure volte a garantire la riservatezza, l’esattezza, l’aggiornamento dei dati, onde evitare usi illeciti o non corretti e l’indebito accesso a soggetti terzi o a personale non autorizzato.</p>
    <p><strong><u>Destinatari ed ambito di conoscibilità</u></strong></p>
    <p>I dati relativi al sito web potranno venire a conoscenza dei seguenti soggetti:</p>
    <ul>
    <li>nostro personale incaricato per le operazioni di sua competenza;</li>
    <li>Internet Service Provider per la fornitura dei servizi inerenti ad Internet.</li>
    </ul>
    <p><strong><u>Trasferimenti verso paesi terzi ed organizzazioni internazionali</u></strong></p>
    <p>La gestione e la conservazione dei dati personali avverrà presso la sede del titolare del trattamento e su server ubicati in Italia o all’interno dell’Unione Europea.</p>
    <p><strong><u>Reclamo all’Autorità di Controllo: </u></strong></p>
    <p>L’interessato che ritenga che il trattamento che lo riguarda violi il Regolamento UE 2016/679 (GDPR), ha il diritto di proporre reclamo al Garante per la protezione dei dati personali – Piazza di Monte Citorio n. 121 – 00186 Roma.</p>
    <p><strong><u>Diritti degli interessati</u></strong></p>
    <p>Nella Sua qualità di interessato, le sono riconosciuti i seguenti diritti:</p>
    <ul>
    <li>Ottenere la conferma che sia o meno in corso un trattamento di dati personali che la riguardano e, in tal caso, di ottenere l’accesso ai dati personali e a tutte le informazioni previste dell’art. 15 del GDPR.</li>
    <li>Ottenere la rettifica dei dati personali inesatti che la riguardano senza ingiustificato ritardo. Tenuto conto delle finalità del trattamento lei ha il diritto di ottenere l’integrazione dei dati personali incompleti, anche fornendo una dichiarazione integrativa.</li>
    <li>Ottenere la cancellazione dei dati personali che la riguardano senza ingiustificato ritardo.</li>
    <li>Esercitare il diritto all’oblio (In questa ipotesi l’art. 17 paragrafo 2 impone al titolare non solo di cancellare i dati – sempre ovviamente che ritenga la richiesta legittima per quanto lo riguarda – ma anche, “tenuto conto della tecnologia disponibile e dei costi di attuazione”, di adottare “misure ragionevoli, anche tecniche” per informare della richiesta che gli è pervenuta anche gli altri eventuali titolari che stanno utilizzando i dati a lui resi pubblici).</li>
    <li>Ottenere la limitazione del trattamento quando ricorra una delle ipotesi previste dall’art. 18 del GDPR.</li>
    <li>Ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che la riguardano forniti al Titolare del trattamento, nei casi previsti dall’art. 20 del GDPR. Nell’esercitare i propri diritti relativamente alla portabilità dei dati, l’interessato ha inoltre il diritto di ottenere la trasmissione diretta dei dati personali da un titolare del trattamento all’altro, se tecnicamente fattibile.</li>
    <li>Opporsi in qualsiasi momento, per motivi connessi alla sua situazione particolare, al trattamento dei dati personali che la riguardano ai sensi dell’articolo 6, paragrafo 1, lettere e) o f) del GDPR. In questo caso il Titolare del trattamento si asterrà dal trattare ulteriormente i dati personali salvo l’esistenza di motivi legittimi cogenti per procedere al trattamento che prevalgono sugli interessi, sui diritti e sulle libertà dell’interessato oppure per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria.</li>
    <li>Non essere sottoposto a una decisione basata unicamente sul trattamento automatizzato, che produca effetti giuridici che la riguardano o che incida in modo analogo significativamente sulla sua persona. Questo diritto non si applica nei casi disciplinati dall’art. 22, paragrafo 2 del GDPR.</li>
    <li>Esercitare il diritto di reclamo all’Autorità di controllo.</li>
    </ul>
    <p>Il Titolare del trattamento è:</p>
    <p><strong>3CEngineering s.r.l.s.</strong></p>
    <p><strong>P.IVA 14556671007</strong></p>
    <p><strong>Via R. Pitteri n.32, E-mail </strong><a href="mailto:info@3cengineering.it"><strong>info&#64;3cengineering.it</strong></a></p>
    <p><strong>                                                                                                                                                                                                                                            </strong></p>
    <p>3CEngineering s.r.l.s. si riserva la facoltà di modificare questa informativa in qualunque momento. In tal caso, daremo la possibilità di leggere la versione modificata attraverso un avviso sul sito web.</p>
    <p>Roma, 01.12.2024</p>
    </div>
</div>