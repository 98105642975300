import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-mobilelogin',
    imports: [],
    templateUrl: './mobile-login.component.html',
    styleUrl: './mobile-login.component.css'
})
export class MobileLoginComponent {
    token: string = '';
    refreshToken: string = '';
    private intervalId: any;
    private destroy$ = new Subject<void>();

    constructor(
        private storage: StorageService
    ) { }

    ngOnInit(): void {
        this.startPolling();
    }

    ngOnDestroy(): void {
        this.stopPolling();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private startPolling(): void {
        this.intervalId = setInterval(() => {
            this.token = this.storage.getToken();
            this.refreshToken = this.storage.getRefreshToken();
            if (this.token) {
                this.stopPolling();
                window.location.href = '/';
            }
        }, 100);
    }

    private stopPolling(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }
}
