<div class="test-banner" *ngIf="!isProduction"></div>
<mat-sidenav-container class="menu-container" fullscreen>
    <mat-sidenav #sidenav [mode]="smallScreen ? 'over' : 'side'" [autoFocus]="false"
        [opened]="loggedIn && (!smallScreen || opened)" (opened)="events.push('open!')" (closed)="events.push('close!')"
        [disableClose]="!smallScreen" style="max-height: 100vh; position: fixed;">
        <app-menu *ngIf="loggedIn"></app-menu>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar *ngIf="loggedIn && smallScreen">
            <button mat-icon-button aria-label="Menu toggle" (click)="sidenav.toggle()" *ngIf="termsAndConditions">
                <mat-icon class="color_main">menu</mat-icon>
            </button>
            <span [routerLink]="['/']" style="cursor:pointer; align-self: end;">
                <img style="height: 45px;" src="{{headerImage}}" />
            </span>
            <span class="mat-toolbar-spacer"></span>
            <button mat-button class="color_main" [routerLink]="['/profilo']">{{username}}</button>
            <button mat-icon-button class="favorite-icon color_main" [routerLink]="['/logout']">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>