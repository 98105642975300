import { Component, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatColumnDef, MatTableDataSource } from '@angular/material/table';
import { CdkDrag, CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { EntityModel, FieldsModel, OptionModel, FiltersModel } from 'src/app/models/entity.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { FormModalComponent } from 'src/app/shared/form-modal/form-modal.component';
import { TableComponent } from 'src/app/shared/table/table.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { MatIcon } from '@angular/material/icon';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatFormFieldModule, MatHint, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule, MatDateRangeInput, MatDateRangePicker, MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatToolbar } from '@angular/material/toolbar';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { SessionService } from 'src/app/services/session.service';
import { Session } from 'src/app/models/session.model';

// formato data visualizzato nel filtro laterale
export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

declare var window: any;

@Component({
    selector: 'app-entity-list',
    templateUrl: './entity-list.component.html',
    styleUrls: ['./entity-list.component.css'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    imports: [
        CdkDrag,
        CommonModule,
        DragDropModule,
        FormsModule,
        MatAccordion,
        MatButton,
        MatCheckbox,
        MatColumnDef,
        MatDatepickerModule,
        MatDateRangeInput,
        MatDateRangePicker,
        MatEndDate,
        MatExpansionPanel,
        MatExpansionPanelDescription,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatFormFieldModule,
        MatHint,
        MatIcon,
        MatInput,
        MatLabel,
        MatMenu,
        MatMenuItem,
        MatMenuTrigger,
        MatOption,
        MatSelect,
        MatSidenav,
        MatSidenavContainer,
        MatSidenavContent,
        MatSlideToggle,
        MatStartDate,
        MatToolbar,
        ReactiveFormsModule,
        TableComponent
    ]
})

export class EntityListComponent {
    @Input() table: EntityModel;
    private destroy$: Subject<void> = new Subject<void>();
    private subscriptions = new Subscription();
    @ViewChild(TableComponent) cTable;

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private api: ApiService,
        private storage: StorageService,
        public dialog: MatDialog,
        private sessionService: SessionService,
    ) { }

    // parametro source ricevuto in input da app-routing.module.ts
    routeData = this.route.data['_value'];
    sourceName = this.routeData['source'];

    tableFields: FieldsModel[];
    tableVisibleFields: FieldsModel[];
    tableVisibleFieldsKV: FieldsModel[] = [];
    tableOptions: OptionModel;
    formSelectList: string[];
    tableFieldsList: string[];
    allFieldsList: string[];
    tableFormFieldsGroups: string[];
    tableVisibleFieldsList: string[] = [];
    tableVisibleFieldsFilterList: string[] = [];

    data: typeof this.table.model[] = [];
    formMode: ('insert' | 'update') = null;
    filtersForm: FormGroup = new FormGroup({});
    filtersObj = {};
    filtersObjKV = [];
    filterFieldsList: string[];
    filtersCount: number = 0;
    filtersRecap: string = '';
    sortDirection: ('asc' | 'desc' | '') = null;
    sortActive: string = null;
    dynamicData = [];
    dataSource = new MatTableDataSource<typeof this.data>(null);
    dataSourceFull = new MatTableDataSource<typeof this.data>(null);
    showColumns = false;
    menuOpened: boolean = false;
    events: string[] = [];
    showFilters: boolean = false;
    hideFiltersBtn = true;
    mobile: boolean = false;
    initSort: boolean = true;
    count: number = 0;
    offset: number = 0;
    limit: number = 10;
    filters: FiltersModel[] = [];
    dataLoading: boolean = false;
    distinctOption: boolean = false;
    fieldsGroups: string[];
    principal: boolean = false;
    admin: boolean = false;


    ngOnInit() {
        this.subscriptions.add(
            this.sessionService.userSettings$.subscribe((settings: Session) => {
                this.principal = settings.user.principal;
                this.admin = settings.user.admin;
            })
        );
        this.table = new EntityModel(this.sourceName);
        // recupera eventuale parametro "mobile" settato solo da app mobile
        this.mobile = this.storage.getMobile() == 'true' ? true : false;
        // popola variabili locali a partire dall'input del component
        this.tableOptions = this.table.getOptions();
        this.tableFields = this.table.getFields();
        this.tableVisibleFields = this.table.getVisibleFields();
        this.tableVisibleFieldsKV = this.table.getVisibleFieldsKV();
        this.tableFieldsList = this.table.getFieldsList();
        this.allFieldsList = this.table.getAllFieldsList();
        this.fieldsGroups = this.table.getFieldsGroups();
        this.tableFormFieldsGroups = this.table.getFormFieldsGroups();
        this.tableVisibleFieldsList = this.table.getVisibleFieldsList();
        // se è previsto il filtro per singoli campi, viene creata la lista dei campi
        if (this.tableOptions.filterHeader) {
            this.tableVisibleFieldsList.forEach(field => {
                this.tableVisibleFieldsFilterList.push(field + '-filter');
            });
        }
        // chiamo il metodo di update della lista dei campi visibili per aggiungere opzionalmente il
        // menu di riga action, ma solo dopo aver settato i campi filtro tableVisibleFieldsFilterList
        this.updateTableVisibleFieldsList();
        // Recupera la lista dei campi che hanno una form select
        this.formSelectList = this.table.getFormSelectList();
        this.formSelectList.forEach(fieldName => {
            this.dynamicData[fieldName] = {
                "form": this.table.getFormSelectByFieldName(fieldName),
                "data": []
            };
        });

        // Popola i campi della form filtersForm
        this.tableVisibleFields.forEach(field => {
            //cicla i formati dei campi
            switch (this.tableVisibleFieldsKV[field.name]['format']) {
                case 'string':
                case 'number':
                case 'currency':
                case 'boolean':
                    this.filtersForm.addControl(field.name, this.formBuilder.control(''));
                    break;
                case 'date':
                case 'datetime':
                case 'time':
                    this.filtersForm.addControl(field.name + '_start', this.formBuilder.control(''));
                    this.filtersForm.addControl(field.name + '_end', this.formBuilder.control(''));
                    break;
                default:
                    break;
            }
        });

        // imposta il valore iniziale del numero di record per pagina
        if (this.tableOptions.pageSize > 0) {
            this.limit = this.tableOptions.pageSize;
        }
        // recupera i filtri che l'utente non può gestire lato client
        this.filters = this.table.filters.slice();
        // recupera i filtri che l'utente può gestire lato client
        this.defaultClientFilters();

        // Inizializzazione dell'ordinamento di default
        this.sortDirection = this.tableOptions.defaultSortDirection ? this.tableOptions.defaultSortDirection : 'asc';
        this.sortActive = this.tableOptions.defaultSortActive;

        // carica i dati
        this.loadData();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.subscriptions.unsubscribe(); // Annulla tutte le sottoscrizioni
    }

    defaultClientFilters() {
        const _filters = this.tableOptions.defaultClientFilter;
        // se non sono impostati filtri di default, skippa il metodo
        if (_filters === undefined) return;
        _filters.forEach(f => {
            // solo al caricamento della pagina, aggiungo i filtri client all'elenco filtri e popolo i filtri laterali
            this.filters.push(f);
            const field = this.table.getFieldByName(f.field);
            switch (field.format) {
                case 'boolean':
                    const _value = f.value.toString();
                    const _e = { value: _value };
                    this.filtersForm.controls[f.field].setValue(_value);
                    this.filterField(f.field, 'boolean', _e, false);
                    break;
                case 'date':
                    switch (f.value) {
                        case 'LAST_MONTH':
                            // const _value = moment().subtract(1, 'months').startOf('day');
                            // mese in corso
                            const _value = moment().startOf('month');
                            const _e = { target: { value: _value } };
                            this.filtersForm.controls[f.field + '_start'].setValue(_value);
                            this.filterField(f.field, 'startDate', _e, false);
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        });
        // Aggiorna il numero di filtri inseriti e il recap del tooltip
        this.updateFiltersInfo();
    }

    updateTableVisibleFieldsList() {
        this.tableVisibleFieldsList = this.tableVisibleFields
            .slice()
            .filter(field => field.show)
            .map(f => f.name);

        // Gestione del menu di ciascuna riga
        // Se abilitata l'opzione, aggiunge l'apposita colonna alla tabella
        if (this.tableOptions.rowActionMenu && !this.tableOptions.noInsert)
            this.tableVisibleFieldsList.push('actionColumn');
    }

    getGroupFormFields(groupName: string, crud: string) {
        return this.table.getGroupFormFields(groupName, crud);
    }

    getGroupFields(groupName: string): FieldsModel[] {
        return this.table.getGroupFields(groupName);
    }

    //handleLoadMain(count: number, offset: number, limit: number, sortActive: string, sortDirection: ('asc' | 'desc' | ''), initSort: boolean) {
    handleLoadMain() {
        this.loadMain();
    }

    loadMain() {
        this.dataLoading = true;
        const _model = typeof this.table.model;
        const _sourceName = this.table.sourceName;
        const _fields = this.allFieldsList;
        const _filters = this.filters;
        const _countOptions = { count: true };
        const _selectOptions = { limit: this.limit, offset: this.offset, distinct: this.distinctOption };
        let _sort = [];
        if (this.sortActive != null && this.sortActive != undefined && this.sortActive != ''
            && this.sortDirection != null && this.sortDirection != undefined && this.sortDirection != '') {
            _sort.push({ field: this.sortActive, direction: this.sortDirection });
        }
        // count
        this.api.select(_model, _sourceName, _fields, _filters, [], _countOptions)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                data => {
                    this.count = data[0]['queryCount'];
                });
        // scarica i dati dell'entità dall'api
        this.api.select(_model, _sourceName, _fields, _filters, _sort, _selectOptions)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    this.data = data;
                    this.dataSource = new MatTableDataSource<typeof this.data>(this.data);
                    if (this.tableOptions.defaultSortActive?.length > 0 && this.initSort) {
                        this.cTable.tableSortInputs();
                        this.initSort = false;
                    }
                    this.dataLoading = false;
                },
                error: (error) => {
                    this.data = [];
                    this.dataSource = new MatTableDataSource<typeof this.data>(this.data);
                    this.dataLoading = false;
                }
            });
    }

    loadFullMain(format: ('xlsx')) {
        this.dataLoading = true;
        const _model = typeof this.table.model;
        const _sourceName = this.table.sourceName;
        const _fields = this.tableVisibleFields.slice().filter(f => f.show).map(f => f.name);
        const _filters = this.filters;
        const _selectOptions = { distinct: this.distinctOption };
        let _sort = [];
        if (this.sortActive != null && this.sortActive != undefined && this.sortActive != ''
            && this.sortDirection != null && this.sortDirection != undefined && this.sortDirection != '') {
            if (_fields.indexOf(this.sortActive) == -1) {
                // se l'ordinamento è su un campo non visibile, utilizzo il primo campo della query
                _sort.push({ field: _fields[0], direction: this.sortDirection });
            } else {
                // se l'ordinamento è su un campo visibile, lo utilizzo
                _sort.push({ field: this.sortActive, direction: this.sortDirection });
            }
        }
        // scarica i dati dell'entità dall'api
        this.api.select(_model, _sourceName, _fields, _filters, _sort, _selectOptions)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    // this.data = data;
                    this.dataSourceFull = new MatTableDataSource<typeof this.data>(data);
                    if (this.tableOptions.defaultSortActive?.length > 0 && this.initSort) {
                        this.cTable.tableSortInputs();
                    }
                    this.dataLoading = false;
                    this.export(format, true)
                },
                error: (error) => {
                    this.dataSourceFull = new MatTableDataSource<typeof this.data>([]);
                    this.dataLoading = false;
                }
            });
    }

    loadData() {
        // loadData viene invocato solo al caricamento della pagina, la prima volta
        // se sono previsti sort di default, setta quelli, poi carica i dati,
        // altrimenti carica solo i dati
        this.loadMain();

        // scarica eventuali dati per le form select
        this.formSelectList.forEach(fieldName => {
            let options: {} = {};
            if (this.dynamicData[fieldName].form.select.distinct) {
                options = {
                    'distinct': true
                };
            }

            let selectList = [];
            // id della select
            selectList.push(this.dynamicData[fieldName].form.select.id);
            // label della select
            selectList.push(this.dynamicData[fieldName].form.select.label);
            // eventuale chiave per un filtro dipendente da altra select
            if (this.dynamicData[fieldName].form.select.upSelectFilter) {
                selectList.push(this.dynamicData[fieldName].form.select.upSelectFilter.filterKey);
            }

            this.api.select(
                typeof {},
                this.dynamicData[fieldName].form.select.sourceName,
                selectList,
                this.dynamicData[fieldName].form.select.filters,
                [],
                options)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    data => {
                        this.dynamicData[fieldName]['data'] = data;
                    }
                );
        });
    }

    openDialog(_type: ('insert' | 'update'), obj?: typeof this.table.model): void {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: { model: this.table, formMode: _type, selObj: obj },
            panelClass: 'full-page-dialog'
        });

        // metodo invocato quando il dialog viene chiuso
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                if (result?.result == 'ok')
                    this.loadData();
            });
    }

    filterField(col: string,
        type: ('startDate' | 'endDate' | 'startTime' | 'endTime' | 'number' | 'string' | 'boolean'),
        e: any,
        showBtn: boolean = true) {
        // aggiorno il valore del filtro , per il campo selezionato (all o puntuale)
        switch (type) {
            case 'string':
                e.target?.value
                    ? this.filtersObj[col] = e.target?.value
                    : delete this.filtersObj[col];
                break;

            case 'startDate':
                if (!this.filtersObj[col]) this.filtersObj[col] = {};
                e.target?.value
                    ? this.filtersObj[col]['start'] = e.target?.value
                    : delete this.filtersObj[col]['start'];
                break;

            case 'endDate':
                if (!this.filtersObj[col]) this.filtersObj[col] = {};
                e.target?.value
                    ? this.filtersObj[col]['end'] = e.target?.value
                    : delete this.filtersObj[col]['end'];
                break;

            case 'startTime':
                if (!this.filtersObj[col]) this.filtersObj[col] = {};
                e.target?.value
                    ? this.filtersObj[col]['start'] = e.target?.value
                    : delete this.filtersObj[col]['start'];
                break;

            case 'endTime':
                if (!this.filtersObj[col]) this.filtersObj[col] = {};
                e.target?.value
                    ? this.filtersObj[col]['end'] = e.target?.value
                    : delete this.filtersObj[col]['end'];
                break;

            case 'number':
                e.target?.value
                    ? this.filtersObj[col] = +e.target?.value
                    : delete this.filtersObj[col];
                break;

            case 'boolean':
                e.value
                    ? this.filtersObj[col] = +e.value
                    : delete this.filtersObj[col];
                break;

            default:
                break;
        }
        if (showBtn) {
            // mostra il pulsante di aggiornamento dati
            this.hideFiltersBtn = false;
        }
    }

    updateFiltersInfo() {
        this.filterFieldsList = Object.keys(this.filtersObj);
        this.filtersCount = this.filterFieldsList.length;
        this.filtersObjKV = [];
        this.filterFieldsList.forEach(field => {
            const filterName = field;
            const filterFormat = (field == '_filterAll') ? 'string' : this.tableVisibleFieldsKV[field].format;
            const filterLabel = (field == '_filterAll') ? 'Ricerca libera' : this.tableVisibleFieldsKV[field].label;
            const filterValue = this.filtersObj[field];
            const filterHasRange = filterFormat == 'date' || filterFormat == 'datetime' || filterFormat == 'time';
            // popolo l'oggetto che mostra a video i filtri attivi
            this.filtersObjKV.push({
                'field': filterName,
                'format': filterFormat,
                'label': filterLabel,
                'value': !filterHasRange ? filterValue : null,
                'start': filterHasRange ? filterValue['start'] : null,
                'end': filterHasRange ? filterValue['end'] : null
            });
        });
    }

    removeFilter(filterName: string) {
        // Cancella il filtro passato in input
        delete this.filtersObj[filterName];
        if (['datetime', 'time', 'date'].includes(this.tableVisibleFieldsKV[filterName].format)) {
            // il campo in questione ha filtro range, quindi vanno puliti i campi '_start' ed '_end'
            this.filtersForm.controls[filterName + '_start'].reset();
            this.filtersForm.controls[filterName + '_end'].reset();
        } else {
            // tutti gli altri campi hanno il control singolo
            this.filtersForm.controls[filterName].reset();
        }
        this.serverFilters();
    }

    serverFilters() {
        // nasconde il pulsante di aggiornamento dati
        this.hideFiltersBtn = true;
        this.offset = 0;
        this.cTable.paginatorFirstPage();

        this.filters = [];
        this.filters = this.table.filters.slice();

        this.filterFieldsList = Object.keys(this.filtersObj);
        // Aggiorna il numero di filtri inseriti e il recap del tooltip
        this.updateFiltersInfo();

        // ciclo tutti i campi filtro popolati, tranne la ricerca libera che ha una sua gestione
        this.filterFieldsList.filter(field => { return field != '_filterAll'; }).forEach(fieldName => {
            const fieldFormat = this.tableVisibleFieldsKV[fieldName].format;
            const searchPattern = this.filtersObj[fieldName];
            switch (fieldFormat) {
                case 'string':
                    this.filters.push({ field: fieldName, operator: 'like', value: searchPattern.toLowerCase() });
                    break;
                case 'number':
                    this.filters.push({ field: fieldName, operator: '=', value: Number(searchPattern) });
                    break;
                case 'date':
                case 'datetime':
                    if (searchPattern.start)
                        this.filters.push({ field: fieldName, operator: '>=', value: moment(searchPattern.start).format('YYYY-MM-DD') });
                    if (searchPattern.end)
                        this.filters.push({ field: fieldName, operator: '<=', value: moment(searchPattern.end).format('YYYY-MM-DD') });
                    break;
                case 'time':
                    let sSeconds = 0;
                    // numero massimo di secondi nelle 24 ore
                    let eSeconds = 86400;
                    if (searchPattern.start != undefined) {
                        // converte il tempo di inizio in secondi
                        const sTime = searchPattern.start.split(':');
                        sSeconds = sTime[0] * 3600 + sTime[1] * 60;
                    }
                    if (searchPattern.end != undefined) {
                        // converte il tempo di fine in secondi
                        const eTime = searchPattern.end.split(':');
                        eSeconds = eTime[0] * 3600 + eTime[1] * 60;
                    }
                    // se il valore del campo non è compreso nel range inserito come filtro, esclude la riga
                    if (searchPattern.start)
                        this.filters.push({ field: fieldName, operator: '>=', value: searchPattern.start });
                    if (searchPattern.end)
                        this.filters.push({ field: fieldName, operator: '<=', value: searchPattern.end });
                    break;
                case 'boolean':
                    // searchPattern
                    this.filters.push({ field: fieldName, operator: '=', value: searchPattern });
                    break;
                default:
                    break;
            }
        });
        this.loadMain();
    }

    clearFilters() {
        // svuota l'array con i valori di ricerca
        this.filtersObj = {};
        this.updateFiltersInfo();
        // applica i filtri
        this.serverFilters();
        // svuota tutti i campi input di filtro
        this.filtersForm.reset();
    }

    dropFieldList(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.tableVisibleFieldsList, event.previousIndex, event.currentIndex);
    }

    dropField(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.tableVisibleFields, event.previousIndex, event.currentIndex);
        this.updateTableVisibleFieldsList();
    }

    getExportableData(full: boolean = false) {
        // Elenco dei campi visibili (oggetto con tutte le proprietà del campo)
        const visibleFields = this.tableVisibleFields.slice().filter(f => f.show);
        let outputDataSource: MatTableDataSource<typeof this.data> = full ? this.dataSourceFull : this.dataSource;
        // Costruisce un nuovo oggetto partendo dalle righe filtrate della tabelle
        // let exportableData = this.dataSource.filteredData.map(row => {
        let exportableData = outputDataSource.filteredData.map(row => {
            let exportableRow = {};
            visibleFields.forEach(f => {
                let exportableField: any;
                switch (f.format) {
                    case 'date':
                        exportableField = row[f.name] ? moment(row[f.name]).format('DD/MM/YYYY') : '';
                        break;
                    case 'datetime':
                        exportableField = row[f.name] ? moment(row[f.name]).format('DD/MM/YYYY HH:mm:ss') : '';
                        break;
                    case 'time':
                        exportableField = row[f.name] ? moment(row[f.name] * 1000).utc().format('HH:mm') : '';
                        break;
                    case 'boolean':
                        exportableField = row[f.name] == 1 ? 'Si' : 'No';
                        break;
                    default:
                        exportableField = row[f.name];
                        break;
                }
                // Utilizza come nome della colonna quello della header della tabella
                exportableRow[f.label] = exportableField;
            });
            return exportableRow;
        });
        return exportableData;
    }

    exportFull(format: ('xlsx')) {
        this.loadFullMain(format);
    }

    export(format: ('xlsx' | 'pdf' | 'json' | 'xml' | 'csv'), full: boolean = false) {
        // Recupera l'oggetto JSON esportabile
        const outputData = this.getExportableData(full);

        switch (format) {
            case 'xlsx':
                // Restituisce la lunghezza per ciascuna colonna
                const fitToColumn = data => {
                    const columnWidths = [];
                    // Larghezza massima della colonna
                    const maxWidth = 30;
                    for (const property in data[0]) {
                        // Larghezza reale della colonna (potenzialmente maggiore della maxWidth)
                        const realWidth = Math.max(
                            property ? property.toString().length : 0, ...data.map(
                                obj => obj[property] ? obj[property].toString().length : 0)
                        );
                        columnWidths.push({
                            // utilizza la realWidth se minore della maxWidth
                            wch: Math.min(maxWidth, realWidth)
                        });
                    }
                    return columnWidths;
                };
                // Crea WorkBook e WorkSheet
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(outputData);
                ws['!cols'] = fitToColumn(outputData);
                // Crea il file
                const sheetName = this.table.options.title || 'export';
                const fileName = sheetName + '_' + moment(new Date()).format('YYYYMMDDHHmmss') + '.xlsx';
                XLSX.utils.book_append_sheet(wb, ws, sheetName);
                // Esporta il file all'utente
                XLSX.writeFile(wb, fileName);
                break;

            default:
                alert('Non disponibile');
                break;
        }
    }

    tableSortInputs() {
        this.sortActive = this.sortActive;
        this.sortDirection = this.sortDirection;
        // this.cTable.tableSortInputs();
    }

    timeInputClick(event: any, input: HTMLInputElement, formControlName: string, type: ('startTime' | 'endTime')) {
        event.preventDefault();

        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler("showTimePicker", input.value).then((result: any) => {
                if (result) {
                    input.value = result;

                    switch (type) {
                        case 'startTime':
                            if (!this.filtersObj[formControlName]) this.filtersObj[formControlName] = {};
                            result
                                ? this.filtersObj[formControlName]['start'] = result
                                : delete this.filtersObj[formControlName]['start'];
                            break;

                        case 'endTime':
                            if (!this.filtersObj[formControlName]) this.filtersObj[formControlName] = {};
                            result
                                ? this.filtersObj[formControlName]['end'] = result
                                : delete this.filtersObj[formControlName]['end'];
                            break;
                    }
                    
                    this.hideFiltersBtn = false;
                }
            });
        }
    }
}