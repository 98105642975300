import { Component } from '@angular/core';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

Chart.register(ChartDataLabels);

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.css'],
    standalone: false
})
export class StatsComponent {
    private destroy$ = new Subject<void>();
    public chart: any;
    datasets: any[] = [];
    filteredDatasets: any[] = [];
    selectedDatasets: any[] = [];
    dataLoading: boolean = false;

    constructor(
        private api: ApiService
    ) { }

    ngOnInit() {
        this.loadData();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    loadData() {
        this.dataLoading = true;
        // ordinamento alfabetico per nominativo operatore
        const _sort = [{ field: 'user', direction: 'asc' }];
        this.api.select(
            typeof {},
            'stats_user_month',
            ['id_user', 'user', 'sep', 'oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug'],
            [], _sort, {}
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                data => {
                    let first: boolean = true;
                    data.forEach(d => {
                        this.datasets.push({
                            id: d.id_user,
                            label: d.user,
                            data: [d.sep, d.oct, d.nov, d.dec, d.jan, d.feb, d.mar, d.apr, d.may, d.jun, d.jul, d.aug],
                            hidden: !first
                        });
                        if (first) {
                            // popola la lista dei selezionati di default
                            this.selectedDatasets.push(d.id_user);
                            first = false;
                        }
                    });
                    this.filteredDatasets = this.datasets;
                    this.createChart();
                    this.dataLoading = false;
                });
    }

    datasetSelection() {
        this.datasets.forEach((d, i) => {
            const isVisible = this.selectedDatasets.indexOf(d.id) > -1;
            this.chart.setDatasetVisibility(i, isVisible);
        });
        this.chart.update();
    }

    createChart() {
        this.chart = new Chart("MyChart", {
            type: 'bar',
            data: {
                labels: ['Set', 'Ott', 'Nov', 'Dic', 'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago'],
                datasets: this.datasets
            },
            options: {
                aspectRatio: 1.5,
                layout: {
                    padding: {
                        left: 50,
                        right: 50,
                        top: 50,
                        bottom: 50
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            stepSize: 3600,
                            callback: function (value) {
                                const seconds = Number(value);
                                const duration = moment.duration(seconds, 'seconds');
                                const duration_hours = Math.floor(duration.asHours());
                                const duration_minutes = Math.floor(duration.asMinutes() - duration_hours * 60);
                                // const duration_seconds = seconds - (duration_hours * 3600) - (duration_minutes * 60);
                                return duration_hours + ':' + ('0' + duration_minutes).slice(-2);
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        position: 'bottom',
                        labels: {
                            filter: (legendItem, data) => {
                                const datasetIndex = legendItem.datasetIndex;
                                const id = data.datasets[datasetIndex]['id'];
                                const isVisible = this.selectedDatasets?.indexOf(id) > -1;
                                return isVisible;
                            }
                        },
                        onClick: null
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                const seconds = Number(context.raw);
                                const duration = moment.duration(seconds, 'seconds');
                                const duration_hours = Math.floor(duration.asHours());
                                const duration_minutes = Math.floor(duration.asMinutes() - duration_hours * 60);
                                const duration_seconds = seconds - (duration_hours * 3600) - (duration_minutes * 60);
                                return duration_hours + ':' + ('0' + duration_minutes).slice(-2) + ':' + ('0' + duration_seconds).slice(-2);
                            },
                            title: function (context) {
                                return context[0].dataset.label + ' - ' + context[0].label;
                            }
                        }
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: function (value) {
                            const seconds = Number(value);
                            // skip se valore 0
                            if (seconds == 0) return null;
                            const duration = moment.duration(seconds, 'seconds');
                            const duration_hours = Math.floor(duration.asHours());
                            const duration_minutes = Math.floor(duration.asMinutes() - duration_hours * 60);
                            const duration_seconds = seconds - (duration_hours * 3600) - (duration_minutes * 60);
                            return duration_hours + ':' + ('0' + duration_minutes).slice(-2);
                        },
                        display: 'auto',
                        clamp: true,
                        font: {
                            weight: 'bold'
                        }
                    }
                }
            }
        });
    }
}
