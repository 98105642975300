<mat-list role="list" class="companies-counters-list">
    <ng-container *ngFor="let company of companiesCounters">
        <mat-list-item role="listitem" (click)="selectedCompany = selectedCompany === company ? null : company"
            [class.selected]="selectedCompany === company">
            <div class="company-item">
                <div class="bar" [style.width.%]="(company.count_users / maxCountUsers * 75)"></div>
                <div class="company-description">{{company.description_short}}</div>
                <div class="users-counter">{{company.count_users | number:'1.0-0'}} op. ({{company.price}} €)</div>
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
    </ng-container>
</mat-list>

<div *ngIf="selectedCompany" class="dashboard-container">
    <h2>{{ selectedCompany.description_short }}</h2>
    <div class="dashboard-grid">
        <ng-container *ngFor="let card of [
            { title: 'Operatori attivi', subtitle: 'Numero totale - Cross modulo', total: selectedCompany.count_users, lastWeek: selectedCompany.count_users_last_week, previousWeek: selectedCompany.count_users_previous_week, pipe: 'number' },
            { title: 'Assistenze totali', subtitle: 'Nell\'anno accademico in corso', total: selectedCompany.count_assistances, lastWeek: selectedCompany.count_assistances_last_week, previousWeek: selectedCompany.count_assistances_previous_week, pipe: 'number' },
            { title: 'Timbrature', subtitle: 'Completate e in corso', total: selectedCompany.count_works, lastWeek: selectedCompany.count_works_last_week, previousWeek: selectedCompany.count_works_previous_week, pipe: 'number' },
            { title: 'Storage', subtitle: 'Spazio totale occupato', total: selectedCompany.storage_size, lastWeek: selectedCompany.storage_size_last_week, previousWeek: selectedCompany.storage_size_previous_week, pipe: 'formatFileSize' }
        ]">
            <div class="dashboard-card">
                <div class="card-header">
                    <div class="card-title">{{ card.title }}</div>
                    <div class="card-subtitle">{{ card.subtitle }}</div>
                </div>
                <div class="card-value">
                    <ng-container *ngIf="card.pipe === 'number'">
                        {{ card.total | number:'1.0-0' }}
                    </ng-container>
                    <ng-container *ngIf="card.pipe === 'formatFileSize'">
                        {{ formatFileSize(card.total) }}
                    </ng-container>
                </div>
                <div class="card-deltas">
                    <div class="card-delta-row">
                        <span class="card-delta-value"
                            [ngClass]="{'positive': card.lastWeek > 0, 'negative': card.lastWeek < 0, 'neutral': card.lastWeek === 0}">
                            <i class="material-icons">
                                {{ card.lastWeek > 0 ? 'arrow_drop_up' : (card.lastWeek < 0 ? 'arrow_drop_down'
                                    : 'remove' ) }} </i>
                                    <ng-container *ngIf="card.pipe === 'number'">
                                        {{ card.lastWeek | number:'1.0-0' }}
                                    </ng-container>
                                    <ng-container *ngIf="card.pipe === 'formatFileSize'">
                                        {{ formatFileSize(card.total) }}
                                    </ng-container>
                                    (7gg)
                        </span>
                    </div>
                    <div class="card-delta-row">
                        <span class="card-delta-percent"
                            [ngClass]="{'positive': card.previousWeek > 0 && (card.lastWeek / card.previousWeek * 100) > 0, 'negative': card.previousWeek > 0 && (card.lastWeek / card.previousWeek * 100) < 0, 'neutral': card.previousWeek === 0 || (card.lastWeek / card.previousWeek * 100) === 0}">
                            {{ ((card.previousWeek > 0) ? (card.lastWeek / card.previousWeek * 100) : 0) |
                            number:'1.0-0' }}%
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>