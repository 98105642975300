import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UserModel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.services';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
    standalone: false
})
export class ProfileComponent {
  private destroy$ = new Subject<void>();
  user: UserModel = new UserModel();

  constructor(
    private auth: AuthService
  ) {
    this.auth.checkSession()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          this.user = data;
        }
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
