import { FieldsModel, FiltersModel, FormFieldModel } from "./entity.model";

export class reportOption {
    title: string;
    filename: string;
    customDownload?: string;
    downloadWithHeaders?: boolean;
}

export class Report {
    model: any;
    sourceName: ('school_month' | 'user_month' | 'school_week' | 'user_week' | 'contract_week' | 'substitution_week' | 'user_missing_weeks' |
        'user_works_absences' | 'report_assistances' | 'report_assistances_qualifications' | 'user_assistances_absences') | null;
    fields: FieldsModel[];
    filters?: FiltersModel[];
    options: reportOption;

    constructor(source: string) {
        // in caso di inizializzazione vuota, restituisco null
        if (source == '') { return null; }

        const activeReport: Report = reports[source];
        this.model = activeReport.model;
        this.sourceName = activeReport.sourceName;
        // inizializzo tutti i campi come visibili
        this.fields = activeReport.fields.map(f => { f.show = true; return f; });
        this.filters = activeReport.filters;
        this.options = activeReport.options;
    }

    getAllFieldsKV(): FieldsModel[] {
        let output: FieldsModel[] = [];
        this.fields
            .slice()
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .forEach(field => {
                // trasposizione chiave-valore della lista dei campi
                output[field.name] = field;
            });
        return output;
    }

    getFieldByName(fieldName: string): FieldsModel {
        return this.fields
            .slice()
            .filter(f => f.name == fieldName)[0];
    }

    getFieldByFormName(fieldName: string): FieldsModel {
        return this.fields
            .slice()
            .filter(f => f.form?.name == fieldName)[0];
    }

    getOptions(): reportOption {
        return this.options;
    }

    getFields(): FieldsModel[] {
        return this.fields
            .slice()
            .sort(function (a, b) { return a.tableSort - b.tableSort; });
    }

    getFieldsList(): string[] {
        return this.fields
            .slice()
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .map(field => {
                return field.name;
            });
    }

    getVisibleFields(): FieldsModel[] {
        return this.fields
            .slice()
            .filter(f => f.format != 'hidden')
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; });
    }

    getVisibleFieldsKV(): FieldsModel[] {
        let output: FieldsModel[] = [];
        this.fields
            .slice()
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .forEach(field => {
                // trasposizione chiave-valore della lista dei campi
                output[field.name] = field;
            });
        return output;
    }

    getVisibleFieldsList(): string[] {
        return this.fields
            .slice()
            .filter(field => field.format != 'hidden')
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .map(f => f.name);
    }

    getFormFields(): FieldsModel[] {
        return this.fields
            .slice()
            .filter(f => f.form != null)
            .sort(function (a, b) { return a.form.formSort - b.form.formSort; });
    }

    getFormSelectList(): string[] {
        return this.fields
            .slice()
            .filter(f => f.form?.type == 'select')
            .map(field => {
                return field.form.name;
            });
    }

    getFormSelectByFieldFormName(fieldName: string): FormFieldModel {
        return this.fields
            .slice()
            .filter(f => f.form?.name == fieldName)
            .map(field => {
                return field.form;
            })[0];
    }
}

const schoolMonthReport = new Report('');
schoolMonthReport.model = null;
schoolMonthReport.sourceName = 'school_month';
schoolMonthReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    { name: 'description_network', label: 'Commessa', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'description_school', label: 'Istituto', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'mechanical_code', label: 'Codice meccanografico', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'sep', label: 'Settembre', format: 'duration', tableSort: 4, crud: ['select'] },
    { name: 'oct', label: 'Ottobre', format: 'duration', tableSort: 5, crud: ['select'] },
    { name: 'nov', label: 'Novembre', format: 'duration', tableSort: 6, crud: ['select'] },
    { name: 'dec', label: 'Dicembre', format: 'duration', tableSort: 7, crud: ['select'] },
    { name: 'jan', label: 'Gennaio', format: 'duration', tableSort: 8, crud: ['select'] },
    { name: 'feb', label: 'Febbraio', format: 'duration', tableSort: 9, crud: ['select'] },
    { name: 'mar', label: 'Marzo', format: 'duration', tableSort: 10, crud: ['select'] },
    { name: 'apr', label: 'Aprile', format: 'duration', tableSort: 11, crud: ['select'] },
    { name: 'may', label: 'Maggio', format: 'duration', tableSort: 12, crud: ['select'] },
    { name: 'jun', label: 'Giugno', format: 'duration', tableSort: 13, crud: ['select'] },
    { name: 'jul', label: 'Luglio', format: 'duration', tableSort: 14, crud: ['select'] },
    { name: 'aug', label: 'Agosto', format: 'duration', tableSort: 15, crud: ['select'] },
    { name: 'total', label: 'Totale', format: 'duration', tableSort: 16, crud: ['select'] },
];
schoolMonthReport.filters = [];
schoolMonthReport.options = {
    title: 'Istituto mese',
    filename: 'Report_istituto_mese'
};

const schoolMonthReport2 = new Report('');
schoolMonthReport2.model = null;
schoolMonthReport2.sourceName = 'school_month';
schoolMonthReport2.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    { name: 'description_network', label: 'Commessa', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'description_school', label: 'Istituto', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'mechanical_code', label: 'Codice meccanografico', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'sep2', label: 'Settembre', format: 'duration', tableSort: 4, crud: ['select'] },
    { name: 'oct2', label: 'Ottobre', format: 'duration', tableSort: 5, crud: ['select'] },
    { name: 'nov2', label: 'Novembre', format: 'duration', tableSort: 6, crud: ['select'] },
    { name: 'dec2', label: 'Dicembre', format: 'duration', tableSort: 7, crud: ['select'] },
    { name: 'jan2', label: 'Gennaio', format: 'duration', tableSort: 8, crud: ['select'] },
    { name: 'feb2', label: 'Febbraio', format: 'duration', tableSort: 9, crud: ['select'] },
    { name: 'mar2', label: 'Marzo', format: 'duration', tableSort: 10, crud: ['select'] },
    { name: 'apr2', label: 'Aprile', format: 'duration', tableSort: 11, crud: ['select'] },
    { name: 'may2', label: 'Maggio', format: 'duration', tableSort: 12, crud: ['select'] },
    { name: 'jun2', label: 'Giugno', format: 'duration', tableSort: 13, crud: ['select'] },
    { name: 'jul2', label: 'Luglio', format: 'duration', tableSort: 14, crud: ['select'] },
    { name: 'aug2', label: 'Agosto', format: 'duration', tableSort: 15, crud: ['select'] },
    { name: 'total2', label: 'Totale', format: 'duration', tableSort: 16, crud: ['select'] },
];
schoolMonthReport2.filters = [];
schoolMonthReport2.options = {
    title: 'Istituto mese',
    filename: 'Report_istituto_mese'
};

const userMonthReport = new Report('');
userMonthReport.model = null;
userMonthReport.sourceName = 'user_month';
userMonthReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_school', label: 'Istituto', form: {
            type: 'select',
            name: 'id_school',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'schools',
                placeholder: 'Selezionare un istituto',
                id: 'id',
                label: 'description',
                filter: true,
                upSelectFilter: {
                    upField: 'id_network',
                    filterKey: 'id_network'
                },
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 3
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_contract', label: 'Contratto', form: {
            type: 'select',
            name: 'id_contract',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'contracts',
                placeholder: 'Selezionare un contratto',
                id: 'id',
                label: 'description',
                filter: true,
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo contratti attivi
            },
            formSort: 3
        }, format: 'hidden', crud: []
    },
    { name: 'description_network', label: 'Commessa', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'description_school', label: 'Istituto', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'mechanical_code', label: 'Codice meccanografico', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'user', label: 'Operatore', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'description_contract', label: 'Contratto', format: 'string', tableSort: 4, crud: ['select'] },
    { name: 'student', label: 'Allievo', format: 'string', tableSort: 4, crud: ['select'] },
    { name: 'sep', label: 'Settembre', format: 'duration', tableSort: 5, crud: ['select'] },
    { name: 'oct', label: 'Ottobre', format: 'duration', tableSort: 6, crud: ['select'] },
    { name: 'nov', label: 'Novembre', format: 'duration', tableSort: 7, crud: ['select'] },
    { name: 'dec', label: 'Dicembre', format: 'duration', tableSort: 8, crud: ['select'] },
    { name: 'jan', label: 'Gennaio', format: 'duration', tableSort: 9, crud: ['select'] },
    { name: 'feb', label: 'Febbraio', format: 'duration', tableSort: 10, crud: ['select'] },
    { name: 'mar', label: 'Marzo', format: 'duration', tableSort: 11, crud: ['select'] },
    { name: 'apr', label: 'Aprile', format: 'duration', tableSort: 12, crud: ['select'] },
    { name: 'may', label: 'Maggio', format: 'duration', tableSort: 13, crud: ['select'] },
    { name: 'jun', label: 'Giugno', format: 'duration', tableSort: 14, crud: ['select'] },
    { name: 'jul', label: 'Luglio', format: 'duration', tableSort: 15, crud: ['select'] },
    { name: 'aug', label: 'Agosto', format: 'duration', tableSort: 16, crud: ['select'] },
    { name: 'total', label: 'Totale', format: 'duration', tableSort: 17, crud: ['select'] },
];
userMonthReport.filters = [];
userMonthReport.options = {
    title: 'Operatore mese',
    filename: 'Report_operatore_mese'
};

const userMonthReport2 = new Report('');
userMonthReport2.model = null;
userMonthReport2.sourceName = 'user_month';
userMonthReport2.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_school', label: 'Istituto', form: {
            type: 'select',
            name: 'id_school',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'schools',
                placeholder: 'Selezionare un istituto',
                id: 'id',
                label: 'description',
                filter: true,
                upSelectFilter: {
                    upField: 'id_network',
                    filterKey: 'id_network'
                },
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 3
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_contract', label: 'Contratto', form: {
            type: 'select',
            name: 'id_contract',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'contracts',
                placeholder: 'Selezionare un contratto',
                id: 'id',
                label: 'description',
                filter: true,
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo contratti attivi
            },
            formSort: 3
        }, format: 'hidden', crud: []
    },
    { name: 'description_network', label: 'Commessa', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'description_school', label: 'Istituto', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'mechanical_code', label: 'Codice meccanografico', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'user', label: 'Operatore', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'description_contract', label: 'Contratto', format: 'string', tableSort: 4, crud: ['select'] },
    { name: 'student', label: 'Allievo', format: 'string', tableSort: 4, crud: ['select'] },
    { name: 'sep2', label: 'Settembre', format: 'duration', tableSort: 5, crud: ['select'] },
    { name: 'oct2', label: 'Ottobre', format: 'duration', tableSort: 6, crud: ['select'] },
    { name: 'nov2', label: 'Novembre', format: 'duration', tableSort: 7, crud: ['select'] },
    { name: 'dec2', label: 'Dicembre', format: 'duration', tableSort: 8, crud: ['select'] },
    { name: 'jan2', label: 'Gennaio', format: 'duration', tableSort: 9, crud: ['select'] },
    { name: 'feb2', label: 'Febbraio', format: 'duration', tableSort: 10, crud: ['select'] },
    { name: 'mar2', label: 'Marzo', format: 'duration', tableSort: 11, crud: ['select'] },
    { name: 'apr2', label: 'Aprile', format: 'duration', tableSort: 12, crud: ['select'] },
    { name: 'may2', label: 'Maggio', format: 'duration', tableSort: 13, crud: ['select'] },
    { name: 'jun2', label: 'Giugno', format: 'duration', tableSort: 14, crud: ['select'] },
    { name: 'jul2', label: 'Luglio', format: 'duration', tableSort: 15, crud: ['select'] },
    { name: 'aug2', label: 'Agosto', format: 'duration', tableSort: 16, crud: ['select'] },
    { name: 'total2', label: 'Totale', format: 'duration', tableSort: 17, crud: ['select'] },
];
userMonthReport2.filters = [];
userMonthReport2.options = {
    title: 'Operatore mese',
    filename: 'Report_operatore_mese'
};

const schoolWeekReport = new Report('');
schoolWeekReport.model = null;
schoolWeekReport.sourceName = 'school_week';
schoolWeekReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_school', label: 'Istituto', form: {
            type: 'select',
            name: 'id_school',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'schools',
                placeholder: 'Selezionare un istituto',
                id: 'id',
                label: 'description',
                filter: true,
                upSelectFilter: {
                    upField: 'id_network',
                    filterKey: 'id_network'
                },
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 3
        }, format: 'hidden', crud: []
    },
    { name: 'description_school', label: 'Istituto', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'mechanical_code', label: 'Codice meccanografico', format: 'string', tableSort: 2, crud: ['select'] },
    {
        name: 'week_monday', label: 'Settimana', format: 'hidden', crud: [], form:
            { type: 'week', name: 'week_monday', isRequired: true, formSort: 4 }
    },
    { name: 'week_duration', label: 'Totale h settimana', format: 'duration', tableSort: 3, crud: ['select'] },
    { name: 'prev_week_duration', label: 'Totale h fino alla settimana (inclusa)', format: 'duration', tableSort: 4, crud: ['select'] },
];
schoolWeekReport.filters = [];
schoolWeekReport.options = {
    title: 'Istituto settimana',
    filename: 'Report_istituto_settimana'
};

const userWeekReport = new Report('');
userWeekReport.model = null;
userWeekReport.sourceName = 'user_week';
userWeekReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_school', label: 'Istituto', form: {
            type: 'select',
            name: 'id_school',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'schools',
                placeholder: 'Selezionare un istituto',
                id: 'id',
                label: 'description',
                filter: true,
                upSelectFilter: {
                    upField: 'id_network',
                    filterKey: 'id_network'
                },
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 3
        }, format: 'hidden', crud: []
    },
    { name: 'description_school', label: 'Istituto', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'mechanical_code', label: 'Codice meccanografico', format: 'string', tableSort: 2, crud: ['select'] },
    {
        name: 'id_user', label: 'Operatore', form: {
            type: 'select',
            name: 'id_user',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'users',
                placeholder: 'Selezionare un operatore',
                id: 'id',
                label: 'fullname',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company'
                },
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 4
        }, format: 'hidden', crud: []
    },
    { name: 'fullname_user', label: 'Operatore', format: 'string', tableSort: 3, crud: ['select'] },
    {
        name: 'week_monday', label: 'Settimana', format: 'hidden', crud: [], form:
            { type: 'week', name: 'week_monday', isRequired: true, formSort: 5 }
    },
    { name: 'week_duration', label: 'Totale h settimana', format: 'duration', tableSort: 4, crud: ['select'] },
    { name: 'prev_week_duration', label: 'Totale h fino alla settimana (inclusa)', format: 'duration', tableSort: 5, crud: ['select'] },
];
userWeekReport.filters = [];
userWeekReport.options = {
    title: 'Operatore settimana',
    filename: 'Report_operatore_settimana'
};

const contractWeekReport = new Report('');
contractWeekReport.model = null;
contractWeekReport.sourceName = 'contract_week';
contractWeekReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo cooperative attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    {
        name: 'week_monday', label: 'Settimana', format: 'hidden', crud: [], form:
            { type: 'week', name: 'week_monday', isRequired: true, formSort: 2 }
    },
    { name: 'description_contract', label: 'Contratto', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'week_duration', label: 'Totale h settimana', format: 'duration', tableSort: 2, crud: ['select'] },
    { name: 'prev_week_duration', label: 'Totale h fino alla settimana (inclusa)', format: 'duration', tableSort: 3, crud: ['select'] },
];
contractWeekReport.filters = [];
contractWeekReport.options = {
    title: 'Contratto settimana',
    filename: 'Report_contratto_settimana'
};

const substitutionWeekReport = new Report('');
substitutionWeekReport.model = null;
substitutionWeekReport.sourceName = 'substitution_week';
substitutionWeekReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    {
        name: 'week_monday', label: 'Settimana', format: 'hidden', crud: [], form:
            { type: 'week', name: 'week_monday', isRequired: true, formSort: 2 }
    },
    { name: 'substitution', label: 'Sostituzione', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'week_duration', label: 'Totale h settimana', format: 'duration', tableSort: 2, crud: ['select'] },
    { name: 'prev_week_duration', label: 'Totale h fino alla settimana (inclusa)', format: 'duration', tableSort: 3, crud: ['select'] },
];
substitutionWeekReport.filters = [];
substitutionWeekReport.options = {
    title: 'Sostituzione settimana',
    filename: 'Report_sostituzione_settimana'
};

const userMissingsReport = new Report('');
userMissingsReport.model = null;
userMissingsReport.sourceName = 'user_missing_weeks';
userMissingsReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies',
                placeholder: 'Selezionare una cooperativa',
                id: 'id',
                label: 'description_short',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    { name: 'fullname_user', label: 'Operatore', format: 'string', tableSort: 1, crud: ['select'] },
    {
        name: 'week_monday', label: 'Settimana', format: 'hidden', crud: [], form:
            { type: 'week', name: 'week_monday', isRequired: true, formSort: 1 }
    },
    {
        name: 'id_contract', label: 'Contratto', form: {
            type: 'select',
            name: 'id_contract',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'contracts',
                placeholder: 'Selezionare un contratto',
                id: 'id',
                label: 'description',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo contratti attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    { name: 'description_contract', label: 'Contratto', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'mon', label: 'Lun', format: 'boolean', tableSort: 3, crud: ['select'] },
    { name: 'tue', label: 'Mar', format: 'boolean', tableSort: 4, crud: ['select'] },
    { name: 'wed', label: 'Mer', format: 'boolean', tableSort: 5, crud: ['select'] },
    { name: 'thu', label: 'Gio', format: 'boolean', tableSort: 6, crud: ['select'] },
    { name: 'fri', label: 'Ven', format: 'boolean', tableSort: 7, crud: ['select'] },
    { name: 'missing_days', label: 'Incompleti', format: 'number', tableSort: 8, crud: ['select'] },
];
userMissingsReport.filters = [
    { "field": "missing_days", "operator": ">", "value": "0" },
];
userMissingsReport.options = {
    title: 'Settimane incomplete',
    filename: 'Report_settimane_incomplete'
};

const userWorksAbsences = new Report('');
userWorksAbsences.model = null;
userWorksAbsences.sourceName = 'user_works_absences';
userWorksAbsences.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies',
                placeholder: 'Selezionare una cooperativa',
                id: 'id',
                label: 'description_short',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    { name: 'type', label: 'Tipo', format: 'hidden', tableSort: 0, crud: ['select'] },
    { name: 'external_id_company', label: 'Id Cooperativa', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'external_id_user', label: 'Id Operatore', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'fullname_user', label: 'Operatore', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'external_id_work', label: 'Codice Giustificativo', format: 'string', tableSort: 4, crud: ['select'] },
    { name: 'code_work', label: 'Codice Timbratura', format: 'string', tableSort: 5, crud: ['select'] },
    {
        name: 'execution_work', label: 'Data', format: 'date', tableSort: 6, crud: ['select'], form: {
            type: 'date', name: 'execution_work', isRequired: true, formSort: 2
        }
    },
    { name: 'start_time_work', label: 'Inizio', format: 'time', tableSort: 7, crud: ['select'] },
    { name: 'end_time_work', label: 'Fine', format: 'time', tableSort: 8, crud: ['select'] },
    { name: 'duration_work', label: 'Durata', format: 'duration', tableSort: 9, crud: ['select'] },
];
userWorksAbsences.filters = [
];
userWorksAbsences.options = {
    title: 'Export Zucchetti PUA',
    filename: 'Export_Zucchetti PUA',
    customDownload: 'export_zucchetti_pua'
};

const userAssistancesAbsences = new Report('');
userAssistancesAbsences.model = null;
userAssistancesAbsences.sourceName = 'user_assistances_absences';
userAssistancesAbsences.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies',
                placeholder: 'Selezionare una cooperativa',
                id: 'id',
                label: 'description_short',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    { name: 'type', label: 'Tipo', format: 'hidden', tableSort: 0, crud: ['select'] },
    { name: 'external_id_company', label: 'Id Cooperativa', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'external_id_user', label: 'Id Operatore', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'fullname_user', label: 'Operatore', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'external_id_assistance', label: 'Codice Giustificativo', format: 'string', tableSort: 4, crud: ['select'] },
    { name: 'code_assistance', label: 'Codice Assistenza', format: 'string', tableSort: 5, crud: ['select'] },
    {
        name: 'execution_assistance', label: 'Data', format: 'date', tableSort: 6, crud: ['select'], form: {
            type: 'date', name: 'execution_assistance', isRequired: true, formSort: 2
        }
    },
    { name: 'start_time_assistance', label: 'Inizio', format: 'time', tableSort: 7, crud: ['select'] },
    { name: 'end_time_assistance', label: 'Fine', format: 'time', tableSort: 8, crud: ['select'] },
    { name: 'duration_assistance', label: 'Durata', format: 'duration', tableSort: 9, crud: ['select'] },
];
userAssistancesAbsences.filters = [
];
userAssistancesAbsences.options = {
    title: 'Export Zucchetti',
    filename: 'Export_Zucchetti',
    customDownload: 'export_zucchetti'
};

const reportPUAT = new Report('');
reportPUAT.model = null;
reportPUAT.sourceName = 'user_works_absences';
reportPUAT.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies',
                placeholder: 'Selezionare una cooperativa',
                id: 'id',
                label: 'description_short',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    // { name: 'type', label: 'Tipo', format: 'hidden', tableSort: 0, crud: ['select'] },
    { name: 'external_id_company', label: 'CodAziendaUfficiale', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'external_id_user', label: 'CodDipendenteUfficiale', format: 'string', tableSort: 2, crud: ['select'] },
    // { name: 'fullname_user', label: 'Operatore', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'automatic_generation', label: 'GenerazioneAutomaticaDaTeorico', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'external_id_work', label: 'CodGiustificativoRilPres', format: 'string', tableSort: 4, crud: ['select'] },
    // { name: 'code_work', label: 'Codice Timbratura', format: 'string', tableSort: 5, crud: ['select'] },
    {
        name: 'execution_work', label: 'Data', format: 'date', tableSort: 6, crud: ['select'], form: {
            type: 'date', name: 'execution_work', isRequired: true, formSort: 2
        }
    },
    // { name: 'start_time_work', label: 'Inizio', format: 'time', tableSort: 7, crud: ['select'] },
    // { name: 'end_time_work', label: 'Fine', format: 'time', tableSort: 8, crud: ['select'] },
    // { name: 'duration_work', label: 'Durata', format: 'duration', tableSort: 9, crud: ['select'] },

    { name: 'duration_work_hours', label: 'NumOre', format: 'number', tableSort: 10, crud: ['select'] },
    { name: 'duration_work_minutes', label: 'NumMinuti', format: 'number', tableSort: 11, crud: ['select'] },
    { name: 'duration_work_minutes_cent', label: 'NumMinutiInCent', format: 'number', tableSort: 12, crud: ['select'] },
    
    { name: 'free_day', label: 'GiornoDiRiposo', format: 'string', tableSort: 14, crud: ['select'] },
    { name: 'close_day', label: 'GiornoChiusuraStraordinari', format: 'string', tableSort: 15, crud: ['select'] },
];
reportPUAT.filters = [];
reportPUAT.options = {
    title: 'Report PUA',
    filename: 'Report_PUAT'
};

const reportAssistances = new Report('');
reportAssistances.model = null;
reportAssistances.sourceName = 'report_assistances';
reportAssistances.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo cooperative attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    // {
    //     name: 'id_network', label: 'Commessa', form: {
    //         type: 'select',
    //         name: 'id_network',
    //         isRequired: true,
    //         pattern: "^[0-9]*$",
    //         select: {
    //             sourceName: 'companies_networks_related',
    //             placeholder: 'Selezionare una commessa',
    //             id: 'id_network',
    //             label: 'description_network',
    //             filter: true,
    //             upSelectFilter: {
    //                 upField: 'id_company',
    //                 filterKey: 'id_company_related'
    //             },
    //             filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
    //         },
    //         formSort: 2
    //     }, format: 'hidden', crud: []
    // },
    {
        name: 'id_user', label: 'Operatore', form: {
            type: 'select',
            name: 'id_user',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'users',
                placeholder: 'Selezionare un operatore',
                id: 'id',
                label: 'fullname',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company'
                },
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo operatori attivi
            },
            formSort: 2
        }, format: 'hidden', crud: [], reportTableFields: ['fullname_user']
    },
    {
        name: 'id_school', label: 'Scuola', form: {
            type: 'select',
            name: 'id_school',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'schools_users',
                placeholder: 'Selezionare un istituto',
                id: 'id_school',
                label: 'description_school',
                filter: true,
                upSelectFilter: {
                    upField: 'id_user',
                    filterKey: 'id_user'
                },
                filters: [{ field: 'active_school', value: 1, operator: '=' }] // solo scuole attive
            },
            formSort: 3
        }, format: 'hidden', crud: [], reportTableFields: ['description_school', 'mechanical_code_school']
    },
    {
        name: 'id_student', label: 'Studente', form: {
            type: 'select',
            name: 'id_student',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'students',
                placeholder: 'Selezionare uno studente',
                id: 'id',
                label: 'fullname',
                filter: true,
                upSelectFilter: {
                    upField: 'id_school',
                    filterKey: 'id_school'
                },
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo studenti attivi
            },
            formSort: 4
        }, format: 'hidden', crud: [], reportTableFields: ['fullname_student']
    },
    {
        name: 'execution_assistance', label: 'Mese', format: 'hidden', crud: [], form: {
            type: 'month', name: 'execution_assistance', isRequired: true, formSort: 6
        }
    },
    { name: 'id_assistance', label: 'ID Assistenza', format: 'hidden', crud: [] },

    { name: 'description_school', label: 'Scuola', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'mechanical_code_school', label: 'Codice meccanografico', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'fullname_student', label: 'Studente', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'fullname_user', label: 'Operatore', format: 'string', tableSort: 4, crud: ['select'] },
    { name: 'execution_assistance', label: 'Data', format: 'date', tableSort: 5, crud: ['select'] },
    { name: 'start_time_assistance', label: 'Inizio', format: 'time', tableSort: 6, crud: ['select'] },
    { name: 'end_time_assistance', label: 'Fine', format: 'time', tableSort: 7, crud: ['select'] },
    { name: 'duration_assistance', label: 'Durata', format: 'duration', tableSort: 8, crud: ['select'] },
    { name: 'class_assistance', label: 'Classe', format: 'string', tableSort: 9, crud: ['select'] },
    { name: 'missing_student_assistance', label: 'Studente assente', format: 'boolean', tableSort: 10, crud: ['select'] },
];
reportAssistances.filters = [
];
reportAssistances.options = {
    title: 'Libro firme',
    filename: 'Report_assistenze',
    downloadWithHeaders: true,
};

const reportAssistancesQualifications = new Report('');
reportAssistancesQualifications.model = null;
reportAssistancesQualifications.sourceName = 'report_assistances_qualifications';
reportAssistancesQualifications.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo cooperative attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    {
        name: 'description_qualification', label: 'Qualifica', form: {
            type: 'select',
            name: 'id_qualification',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'qualifications',
                placeholder: 'Selezionare una qualifica',
                id: 'id',
                label: 'description',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo qualifiche attive
            },
            formSort: 3
        }, format: 'string', crud: ['select'], tableSort: 1
    },
    {
        name: 'description_school', label: 'Scuola', form: {
            type: 'groupable', name: 'id_school', formSort: 4
        }, format: 'string', crud: ['select'], tableSort: 2
    },
    {
        name: 'fullname_student', label: 'Studente', form: {
            type: 'groupable', name: 'id_student', formSort: 5
        }, format: 'string', crud: ['select'], tableSort: 3
    },
    {
        name: 'fullname_user', label: 'Operatore', form: {
            type: 'groupable', name: 'id_user', formSort: 6
        }, format: 'string', crud: ['select'], tableSort: 4
    },
    {
        name: 'execution_assistance', label: 'Mese', form: {
            type: 'month', name: 'execution_assistance', isRequired: true, formSort: 7
        }, format: 'hidden', crud: []
    },
    // { name: 'id_assistance', label: 'ID Assistenza', format: 'hidden', crud: [] },
    // { name: 'description_school', label: 'Scuola', format: 'string', tableSort: 1, crud: ['select'] },
    // { name: 'mechanical_code_school', label: 'Codice meccanografico', format: 'string', tableSort: 2, crud: ['select'] },
    // { name: 'fullname_student', label: 'Studente', format: 'string', tableSort: 3, crud: ['select'] },
    // { name: 'fullname_user', label: 'Operatore', format: 'string', tableSort: 4, crud: ['select'] },
    // { name: 'execution_assistance', label: 'Data', format: 'date', tableSort: 5, crud: ['select'] },
    { name: 'duration_assistance', label: 'Durata', format: 'duration', tableSort: 5, crud: ['select'], aggregate: 'SUM' },

];
reportAssistancesQualifications.filters = [
];
reportAssistancesQualifications.options = {
    title: 'Rendicontazione qualifiche',
    filename: 'Report_assistenze_qualifiche',
};

let reports: Report[] = [];
reports['school_month'] = schoolMonthReport;
reports['school_month2'] = schoolMonthReport2;
reports['user_month'] = userMonthReport;
reports['user_month2'] = userMonthReport2;
reports['school_week'] = schoolWeekReport;
reports['user_week'] = userWeekReport;
reports['contract_week'] = contractWeekReport;
reports['substitution_week'] = substitutionWeekReport;
reports['user_missing_weeks'] = userMissingsReport;
reports['user_assistances_absences'] = userAssistancesAbsences;
reports['user_works_absences'] = userWorksAbsences;
reports['report_assistances'] = reportAssistances;
reports['report_assistances_qualifications'] = reportAssistancesQualifications;
reports['report_puat'] = reportPUAT;