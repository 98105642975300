import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from './guards/role-guard.guard';

import { EntityDetailComponent } from './components/entity-detail/entity-detail.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { EntityListComponent } from './components/entity-list/entity-list.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ProfileComponent } from './components/profile/profile.component';
import { UploadComponent } from './components/upload/upload.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { StatsComponent } from './components/stats/stats.component';
import { TermsAndConditionsDetailsComponent } from './components/terms-and-conditions/terms-and-conditions-details.component';
import { MobileLoginComponent } from './components/mobile-login/mobile-login.component';
import { ReportHomeComponent } from './components/report-home/report-home.component';
import { WorkEntryComponent } from './components/work-entry/work-entry.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminStatsComponent } from './components/admin/stats/admin-stats.component';

const ROLE_SYS_ADMIN = 'sys_admin';
const ROLE_ADMIN = 'admin';
const ROLE_USER = 'user';
const ROLE_PRINCIPAL = 'principal';
const ROLE_DEMO = 'demo';

const MODULE_DEMO = 'DEMO';
const MODULE_BASE = 'BASE';
const MODULE_SCHOOL = 'SCHOOL';
const MODULE_PUA = 'PUA';
const MODULE_DOC = 'DOC';

const routes: Routes = [
    {
        path: 'allievi/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'students' },
    },
    {
        path: 'allievi',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'students' },
    },
    {
        path: 'anni',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'years' },
    },
    {
        path: 'assenze',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_SCHOOL], source: 'absences' },
    },
    {
        path: 'assenze/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_SCHOOL], source: 'absences' },
    },
    {
        path: 'assenze-pua',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_PUA], source: 'absences_pua' },
    },
    {
        path: 'assenze-pua/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_PUA], source: 'absences_pua' },
    },
    {
        path: 'tipi-assenze',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'absences_types' },
    },
    {
        path: 'tipi-assenze-pua',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_PUA], source: 'absences_types_pua' },
    },
    {
        path: 'presenze/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER, ROLE_DEMO], modules: [MODULE_DEMO, MODULE_SCHOOL], source: 'assistances' },
    },
    {
        path: 'presenze',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER, ROLE_PRINCIPAL, ROLE_DEMO], modules: [MODULE_DEMO, MODULE_SCHOOL], source: 'assistances' },
    },
    {
        path: 'tipi-documenti',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_DOC], source: 'files_types' },
    },
    {
        path: 'attivita/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'activities' },
    },
    {
        path: 'attivita-pua/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_PUA], source: 'activities_pua' },
    },
    {
        path: 'attivita',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'activities' },
    },
    {
        path: 'attivita-pua',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_PUA], source: 'activities_pua' },
    },
    {
        path: 'qualifiche',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'qualifications' },
    },
    {
        path: 'qualifiche/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'qualifications' },
    },
    {
        path: 'operatori/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_BASE], source: 'users' },
    },
    {
        path: 'operatori',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_BASE], source: 'users' },
    },
    {
        path: 'commesse/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'networks' },
    },
    {
        path: 'commesse',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'networks' },
    },
    {
        path: 'istituti/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'schools' },
    },
    {
        path: 'istituti',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'schools' },
    },
    {
        path: 'segnalazioni/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_SCHOOL], source: 'requests' },
    },
    {
        path: 'segnalazioni-pua/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_PUA], source: 'requests_pua' },
    },
    {
        path: 'segnalazioni',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_SCHOOL], source: 'requests' },
    },
    {
        path: 'segnalazioni-pua',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_PUA], source: 'requests_pua' },
    },
    {
        path: 'sedi',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_PUA], source: 'sites' },
    },
    {
        path: 'sedi/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_PUA], source: 'sites' },
    },
    {
        path: 'timbrature',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_PUA], source: 'works' },
    },
    {
        path: 'timbrature/:id',
        component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_PUA], source: 'works' },
    },
    {
        path: 'badge',
        component: WorkEntryComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_PUA] },
    },
    {
        path: 'upload',
        component: UploadComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL, MODULE_PUA] },
    },
    {
        path: 'statistiche',
        component: StatsComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_SCHOOL] },
    },
    {
        // questo path serve per gestire il puntamento a un report da url o f5 dalla pagina di un report
        // quando l'app non ha ancora caricato i report dell'utente loggato, altrimenti andrebbe in 404
        path: 'report/:id',
        component: ReportHomeComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_BASE] },
    },
    {
        path: 'archivio/presenze',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'assistances_full' },
    },
    {
        path: 'archivio/assenze',
        component: EntityListComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_SCHOOL], source: 'absences_full' },
    },
    { path: 'login', component: LoginComponent },
    { path: 'cambio-password', component: ChangePasswordComponent },
    {
        path: 'profilo',
        component: ProfileComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER, ROLE_PRINCIPAL], modules: [MODULE_BASE] },
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'accettazione-termini-e-condizioni',
        component: TermsAndConditionsComponent
    },
    {
        path: 'termini-e-condizioni',
        component: TermsAndConditionsDetailsComponent
    },
    {
        path: 'mobileLogin',
        component: MobileLoginComponent
    },
    {
        path: '',
        component: HomeComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_USER], modules: [MODULE_BASE] },
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_SYS_ADMIN] },
        children: [
            {
                path: 'statistiche', component: AdminStatsComponent,
                canActivate: [RoleGuard],
                data: { roles: [ROLE_SYS_ADMIN], modules: [MODULE_BASE], sysAdminPage: true }
            },
            {
                path: 'cooperative', component: EntityListComponent,
                canActivate: [RoleGuard],
                data: { roles: [ROLE_SYS_ADMIN], modules: [MODULE_BASE], source: 'companies_admin', sysAdminPage: true }
            },
            {
                path: 'cooperative/:id', component: EntityDetailComponent,
                canActivate: [RoleGuard],
                data: { roles: [ROLE_SYS_ADMIN], modules: [MODULE_BASE], source: 'companies_admin', sysAdminPage: true }
            },
            {
                path: 'operatori', component: EntityListComponent,
                canActivate: [RoleGuard],
                data: { roles: [ROLE_SYS_ADMIN], modules: [MODULE_BASE], source: 'users', sysAdminPage: true }
            },
            {
                path: 'operatori/:id', component: EntityDetailComponent,
                canActivate: [RoleGuard],
                data: { roles: [ROLE_SYS_ADMIN], modules: [MODULE_BASE], source: 'users', sysAdminPage: true }
            },
            {
                path: 'report', component: EntityListComponent,
                canActivate: [RoleGuard],
                data: { roles: [ROLE_SYS_ADMIN], modules: [MODULE_BASE], source: 'reports', sysAdminPage: true }
            },
            {
                path: 'report/:id', component: EntityDetailComponent,
                canActivate: [RoleGuard],
                data: { roles: [ROLE_SYS_ADMIN], modules: [MODULE_BASE], source: 'reports', sysAdminPage: true }
            },
            { path: '', redirectTo: '/', pathMatch: 'full' },
            { path: '**', component: PageNotFoundComponent }
        ]
    },
    {
        path: 'cooperative/:id', component: EntityDetailComponent,
        canActivate: [RoleGuard],
        data: { roles: [ROLE_ADMIN], modules: [MODULE_BASE], source: 'companies', noBackButton: true }
    },
    { path: '404', component: PageNotFoundComponent },
    { path: '**', redirectTo: '404' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
