<div class="container detail_page">
    <div class="page_header">
        <button mat-raised-button routerLink=".." class="button-with-icon">
            <i class="bi bi-chevron-left"></i>Indietro
        </button>
    </div>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="{{options['detailTitle'] || 'Main'}}">
            <div class="main_tab_body" *ngIf="data">
                <button mat-button (click)="openDialog('update', data);" *ngIf="((data && data['editable']==1) || role=='admin') && !options['noUpdate']" class="detail_edit_button">
                    <mat-icon style="margin-right: 0px;">edit</mat-icon>
                </button>

                <ng-container *ngFor="let group of fieldsGroups">
                    <div class="group-wrapper" *ngIf="getGroupFields(group).length > 0">
                        <label *ngIf="group" class="group-title">{{group}}</label>
                        <ng-container *ngFor="let field of getGroupFields(group)">
                            <span class="details_section_span">
                                <small class="field_name">{{visibleFieldsKV[field.name]['label']}}</small>
                                <br />

                                <ng-container [ngSwitch]="visibleFieldsKV[field.name]['format']" class="filed_value">
                                    <ng-container *ngSwitchCase="'number'">
                                        {{data[field.name]}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'currency'">
                                        € {{data[field.name]}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'string'">
                                        <a *ngIf="visibleFieldsKV[field.name]['linkText']?.length > 0 && role=='admin'"
                                            [routerLink]="[visibleFieldsKV[field.name]['linkText'], data[visibleFieldsKV[field.name]['linkItemField']]]">
                                            {{data[field.name]}}
                                        </a>
                                        <span *ngIf="!(visibleFieldsKV[field.name]['linkText']?.length > 0) || role!='admin'" class="pre-text">
                                            {{data[field.name]}}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'datetime'">
                                        {{data[field.name] | date: 'dd/MM/yyyy HH:mm:ss'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'date'">
                                        {{data[field.name] | date: 'dd/MM/yyyy'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'time'">
                                        {{data[field.name]*1000 | date: 'HH:mm':'UTC'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'boolean'">
                                        <mat-icon *ngIf="data[field.name]==true || data[field.name]==1" class="boolean_icon_true">check</mat-icon>
                                        <mat-icon
                                            *ngIf="data[field.name]==false || data[field.name]==0" class="boolean_icon_false">minimize</mat-icon>
                                    </ng-container>
                                </ng-container>
                            </span>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </mat-tab>

        <ng-container *ngFor="let link of linked;let i=index">
            <mat-tab *ngIf="isLinkedVisible(link)">
                <ng-template mat-tab-label>
                    <div matBadge="{{dynamicLinked[link?.sourceName]?.length ?? 0}}" matBadgeOverlap="false"
                        class="demo-section">
                        {{link?.title}}
                    </div>
                </ng-template>

                <!-- Select di aggiunta relazione -->
                <div *ngIf="!link?.select && !link?.addFields && link?.routerLink && link?.routerId" class="linked_no_form_message">
                    <span>
                        <i class="bi bi-exclamation-circle-fill main-color"></i>
                    </span>
                    <p><em>Per questa entità associata, la modifica è possibile solo direttamente nell'entità stessa</em></p>
                </div>
                <div *ngIf="data && ( data['editable']==1 || role=='admin' ) && ( link?.select || link?.addFields )" class="linked_form_container">
                    <!-- <button mat-button *ngIf="( data['editable']==1 || role=='admin' ) && ( link.select || link.addFields )"
                        style="margin: 16px 0 0 16px"
                        (click)="linkedAddShow[link.sourceName] ? linkedAddShow[link.sourceName] = false : linkedAddShow[link?.sourceName] = true">
                        <span *ngIf="!linkedAddShow[link.sourceName]">Mostra form inserimento</span>
                        <span *ngIf="linkedAddShow[link.sourceName]">Nascondi form inserimento</span>
                    </button> -->
                    <button mat-raised-button class="button-with-icon"
                            (click)="linkedAddShow[link.sourceName] ? linkedAddShow[link.sourceName] = false : linkedAddShow[link?.sourceName] = true">
                        <span *ngIf="!linkedAddShow[link.sourceName]">Aggiungi <i class="bi bi-plus-lg"></i></span>
                        <span *ngIf="linkedAddShow[link.sourceName]">Nascondi form inserimento</span>
                    </button>

                    <div *ngIf="linkedAddShow[link.sourceName]" class="linked_form">
                        <ng-container *ngIf="link.select">
                            <!-- Form di associazione -->
                            <mat-form-field appearance="outline" subscriptSizing="dynamic" [class.xl-select-field]="link.select.filter">
                                <mat-label>{{link?.title}}</mat-label>
                                <mat-select [(ngModel)]="linkedAddEntries[link.select?.sourceName]" matInput multiple>
                                    <!-- TODO: aggiungere campo filtro se da entity è presente il flag link.select.filter -->
                                    <mat-form-field appearance="outline" *ngIf="link.select.filter" style="margin: 0px 8px; width: calc(100% - 16px);">
                                        <input [(ngModel)]="filter_input[i+'-'+linkedAddEntries[link.select?.sourceName]]" matInput placeholder="Filtro">
                                    </mat-form-field>
                                    <ng-container *ngFor="let opt of filterSelect(dynamicData[link.select?.sourceName]['data'], link.select?.label, filter_input[i+'-'+linkedAddEntries[link.select?.sourceName]])">
                                        <mat-option [value]="opt[link.select?.id]" *ngIf="!isLinkedUsed(link, opt[link.select?.id])">
                                            {{opt[link.select?.label]}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            <button mat-button style="margin-left: 8px"
                                *ngIf="linkedAddEntries[link.select?.sourceName]?.length > 0" (click)="addLinked(link)">
                                Aggiungi ({{linkedAddEntries[link.select?.sourceName]?.length}})
                            </button>
                        </ng-container>

                        <ng-container *ngIf="!link.select && link.addFields">
                            <div class="linked_form_row">
                                <!-- Form di inserimento libero -->
                                <div *ngFor="let col of link.addFields">
                                    <ng-container *ngIf="col.form.type=='textarea'">
                                        <mat-form-field appearance="outline" subscriptSizing="dynamic">
                                            <mat-label>{{col.label}}</mat-label>
                                            <textarea #input matInput type="text" value="" [(ngModel)]="linkedAddForm[col.form.name]"
                                                placeholder="{{col.form.placeholder}}"></textarea>
                                            <mat-hint *ngIf="col.form.maxLength" align="end">
                                                {{input.value.length}}/{{col.form.maxLength}}
                                            </mat-hint>
                                            <mat-error *ngIf="col.form?.isRequired && linkedAddForm[col.form.name]?.length > 0">
                                                Il campo '{{col.label}}' è obbligatorio
                                            </mat-error>
                                            <mat-error align="end"
                                                *ngIf="col.form?.maxLength > 0 && linkedAddForm[col.form.name]?.length < col.form.maxLength">
                                                {{input.value.length}}/{{col.form.maxLength}}
                                            </mat-error>
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container *ngIf="col.form.type=='select'">
                                        <mat-form-field appearance="outline" subscriptSizing="dynamic" [class.xl-select-field]="col.form.select?.filter">
                                            <mat-label>{{col.label}}</mat-label>
                                            <mat-select matInput [(ngModel)]="linkedAddForm[col.form.name]">
                                                <mat-form-field appearance="outline" *ngIf="col.form.select?.filter" style="margin: 0px 8px; width: calc(100% - 16px);">
                                                    <input [(ngModel)]="filter_input[i+'-'+linkedAddForm[col.form.name]]" matInput placeholder="Filtro">
                                                </mat-form-field>
                                                <ng-container *ngFor="let opt of filterSelect(dynamicData[col.form.name]['data'], dynamicData[col.form.name].form?.select.label, filter_input[i+'-'+linkedAddForm[col.form.name]])">
                                                    <mat-option *ngIf="!isLinkedUsed(link, opt[dynamicData[col.form.name].form?.select.id])" [value]="opt[dynamicData[col.form.name].form?.select.id]">
                                                        {{opt[dynamicData[col.form.name].form?.select.label]}}
                                                    </mat-option>
                                                </ng-container>
                                            </mat-select>
                                            <mat-error *ngIf="col.form?.isRequired && linkedAddForm[col.form.name]?.length > 0">
                                                Il campo '{{col.label}}' è obbligatorio
                                            </mat-error>
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container *ngIf="col.form.type=='number'" subscriptSizing="dynamic">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{col.label}}</mat-label>
                                            <input matInput type="number" value="" step="1" min="0" [(ngModel)]="linkedAddForm[col.form.name]" placeholder="{{col.form.placeholder}}">
                                            <!-- mat-icon matSuffix *ngIf="linkedAddForm[col.form.name].status=='VALID'"
                                                class="valid-icon">check</mat-icon -->
                                            <mat-hint align="end">Formato 123</mat-hint>
                                            <!-- mat-error *ngIf="col.form?.isRequired && linkedAddForm[col.form.name].errors?.required">
                                                Il campo '{{col.label}}' è obbligatorio
                                            </mat-error -->
                                        </mat-form-field>
                                    </ng-container>
                                </div>
                            </div>
                            <button mat-button style="margin-left: 8px" (click)="addLinkedForm(link)">Aggiungi</button>
                        </ng-container>
                    </div>
                </div>

                <div class="linked_tab_body">
                    <div *ngIf="dynamicLinked[link?.sourceName]?.length == 0" class="linked_form_container">
                        <div class="linked_form">
                            <label>Nessun record da visualizzare...</label>
                        </div>
                    </div>
                    <mat-table [dataSource]="dynamicLinked[link?.sourceName]" *ngIf="dynamicLinked[link?.sourceName]?.length > 0">
                        <ng-container *ngFor="let col of getSortedActive(link?.fields)" matColumnDef="{{col.name}}">
                            <mat-header-cell *matHeaderCellDef>{{col.label}}</mat-header-cell>
                            <mat-cell *matCellDef="let cell" [ngClass]="{
                                'numberTd'   : col?.format=='number',
                                'stringTd'   : col?.format=='string',
                                'datetimeTd' : col?.format=='datetime',
                                'dateTd'     : col?.format=='date',
                                'booleanTd'  : col?.format=='boolean'
                                }">
                                <!--- <small class="d-inline-mobile" style="color: darkgrey">{{col.label}}:&nbsp;</small> --->
                                <ng-container [ngSwitch]="col?.format">
                                    <ng-container *ngSwitchCase="'number'">
                                        {{cell[col.name]}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'string'">
                                        {{cell[col.name]}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'datetime'">
                                        {{cell[col.name] | date: 'dd/MM/yyyy HH:mm:ss'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'date'">
                                        {{cell[col.name] | date: 'dd/MM/yyyy'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'boolean'">
                                        <mat-icon *ngIf="cell[col.name]==true || cell[col.name]==1" class="boolean_icon_true">check</mat-icon>
                                        <mat-icon *ngIf="cell[col.name]==false || cell[col.name]==0" class="boolean_icon_false">minimize</mat-icon>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        {{cell[col.name]}}
                                    </ng-container>
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <!-- Aciton Column -->
                        <ng-container matColumnDef="actionColumn" stickyEnd>
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let cell" class="table-action-button">
                                <button mat-icon-button aria-label="Action button" [matMenuTriggerFor]="actionOptionsMenu"
                                        [matMenuTriggerData]="{routerLink: link?.routerLink, routerId: cell[link?.routerId], editId: cell[link?.editId] || null, removable: (link?.select || link?.addFields) }"
                                        (click)="handleActionsClick($event)" *ngIf="data && (data['editable']==1 || role=='admin') && link?.routerLink && link?.routerId">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <!-- -->

                        <mat-header-row *matHeaderRowDef="getPropertyList(getSortedActive(link?.fields), 'name').concat('actionColumn')"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: getPropertyList(getSortedActive(link?.fields), 'name').concat('actionColumn')" class="row_main"></mat-row>
                    </mat-table>

                    <mat-menu #actionOptionsMenu="matMenu" xPosition="before">
                        <ng-template matMenuContent let-routerLink="routerLink" let-routerId="routerId" let-editId="editId" let-removable="removable">
                            <button mat-menu-item routerLink="/{{routerLink}}/{{routerId}}">
                                <mat-icon>open_in_new</mat-icon>
                                <span>Apri</span>
                            </button>
                            <button mat-menu-item (click)="deleteLink(link?.sourceName, editId)" *ngIf="removable">
                                <mat-icon>delete</mat-icon>
                                <span>Elimina</span>
                            </button>
                        </ng-template>
                    </mat-menu>
                </div>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</div>