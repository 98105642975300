import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { StorageService } from 'src/app/services/storage.service';
import { NavigationEnd, Route, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { ApiService } from 'src/app/services/api.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { CompanyModel, CompanyReportModel } from 'src/app/models/db.model';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { ReportComponent } from '../report/report.component';
import { RoleGuard } from 'src/app/guards/role-guard.guard';

@Component({
    selector: 'app-menu',
    imports: [
        CommonModule,
        MatSelectModule,
        MatListModule,
        MatIcon,
        CdkAccordionModule,
        CdkAccordionItem,
        RouterLink,
        RouterLinkActive,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatOptionModule
    ],
    templateUrl: './menu.component.html',
    styleUrl: './menu.component.css'
})
export class MenuComponent {
    private destroy$ = new Subject<void>();
    loggedIn: boolean = false;
    isUserSysAdmin: boolean = false;
    selectedCompany: number = 0;
    companies: [] = [];
    role: string = 'user';
    admin: boolean = false;
    principal: boolean = false;
    username: string = '';
    termsAndConditions: boolean = false;
    contract: string;
    mobile: boolean = false;
    smallScreen: boolean = false;
    menuImage: string;
    companyInfo: CompanyModel;
    headerImage: string;
    companyReports: CompanyReportModel[] = [];
    base_menu = [
        { 'type': 'item', 'routerLink': '/', 'icon': 'home', 'title': 'Home', 'role': 'user' },
        { 'type': 'item', 'routerLink': '/presenze', 'icon': 'handshake', 'title': 'Presenze', 'role': 'user' },
        { 'type': 'item', 'routerLink': '/assenze', 'icon': 'work_off', 'title': 'Assenze', 'role': 'user', 'hideContract': true },
        { 'type': 'item', 'routerLink': '/segnalazioni', 'icon': 'playlist_add_check', 'title': 'Segnalazioni', 'role': 'user' },
        { 'type': 'item', 'routerLink': '/upload', 'icon': 'cloud_upload', 'title': 'Upload', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/statistiche', 'icon': 'leaderboard', 'title': 'Statistiche', 'role': 'user' },
        {
            'type': 'dropdown', 'icon': 'dog', 'title': 'Anagrafiche', 'role': 'admin', 'items': [
                { 'type': 'item', 'routerLink': '/commesse', 'icon': 'business', 'title': 'Commesse', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/istituti', 'icon': 'school', 'title': 'Istituti', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/allievi', 'icon': 'child_care', 'title': 'Allievi', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/operatori', 'icon': 'people_alt', 'title': 'Operatori', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/attivita', 'icon': 'edit_calendar', 'title': 'Attività', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/qualifiche', 'icon': 'edit_calendar', 'title': 'Qualifiche', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/anni', 'icon': 'refresh', 'title': 'Anni', 'role': 'admin' },
            ]
        },
        {
            'type': 'dropdown', 'icon': 'people_alt', 'title': 'Archivio', 'role': 'admin', 'items': [
                { 'type': 'item', 'routerLink': '/archivio/presenze', 'icon': 'people_alt', 'title': 'Presenze', 'role': 'admin' },
                { 'type': 'item', 'routerLink': '/archivio/assenze', 'icon': 'people_alt', 'title': 'Assenze', 'role': 'admin' },
            ]
        },
    ];
    menu: any[] = [];

    constructor(
        private api: ApiService,
        private storage: StorageService,
        private router: Router
    ) {
        this.initializeApp();

        // resta in attesa del salvataggio delle companyInfo
        this.storage.companyInfo$.subscribe(companyInfo => {
            this.loadCompanyInfo(companyInfo);
        });

        // resta in attesa del salvataggio dei companyInfo
        this.storage.companyReports$.subscribe(companyReports => {
            this.loadCompanyReports(companyReports);
        });
    }

    private initializeApp() {
        this.menu = [...this.base_menu];
        this.checkScreenSize();
        this.handleUserAuthentication();

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
            )
            .subscribe((ev: NavigationEnd) => {
                this.checkScreenSize();
            });
    }

    loadCompanyInfo(companyInfo: CompanyModel) {
        this.companyInfo = companyInfo;
        // procedo solo se sono stati caricati i dati della company
        if (!this.companyInfo) return;

        this.menuImage = this.companyInfo.image_menu;
        this.headerImage = this.companyInfo.image_header;
    }

    loadCompanyReports(companyReports: CompanyReportModel[]) {
        this.companyReports = companyReports;
        // procedo solo se sono stati caricati i report
        if (!this.companyReports) return;
        // carico i dati dei report solo la prima volta, altrimenti un caricamento succcessivo
        // ricaricherebbe da capo il menu facendo chiudere sezioni espanse o perdere la proprietà
        // active di una eventuale voce di menu selezionata e attiva
        // this.firstReportsLoad = false;

        let reportList = [];
        this.companyReports?.forEach((companyReport: CompanyReportModel) => {
            // preapro la voce di menu per ogni report
            reportList.push({
                'type': 'item',
                'routerLink': companyReport.path,
                'icon': companyReport.icon,
                'title': companyReport.description,
                'role': 'admin'
            });
            // aggiungo le rotte per i report
            this.addRoute(companyReport.path, companyReport.entityName);
        });

        if (reportList.length > 0) {
            this.menu.push({ 'type': 'dropdown', 'icon': 'report', 'title': 'Report', 'role': 'admin', 'items': reportList });
        }
    }

    addRoute(_path: string, _source: string) {
        const newRoute: Route = {
            // se il primo carattere è / lo rimuove in quanto la rotto non può iniziare con /
            path: _path.startsWith('/') ? _path.substring(1) : _path,
            component: ReportComponent,
            canActivate: [RoleGuard],
            data: { roles: ['admin'], source: _source },
        };

        // Recupera le rotte attuali
        const existingRoutes = this.router.config;

        // Combina le rotte esistenti con le nuove
        const updatedRoutes = [newRoute, ...existingRoutes];
        // il comando sopra aggiunge le rotte nuove sempre in testa, quindi l'ordine è invertito
        // questo potrebbe eventualmente creare problemi in caso di precedenza delle rotte
        // qualora servisse, le righe di seguito fanno in modo di inserire la rotta immediatamente prima del wildcard **
        // // Trova l'indice della rotta '**'
        // const wildcardIndex = existingRoutes.findIndex(route => route.path === '**');
        // // Inserisce la nuova rotta prima della rotta '**'
        // const updatedRoutes = [
        //     ...existingRoutes.slice(0, wildcardIndex),
        //     newRoute,
        //     ...existingRoutes.slice(wildcardIndex)
        // ];

        // Aggiorna la configurazione delle rotte
        this.router.resetConfig(updatedRoutes);
    }

    checkScreenSize() {
        this.smallScreen = window.innerWidth < 768;
        this.mobile = this.storage.getMobile() == 'true' ? true : false;
    }

    getCompanies() {
        const sourceName = 'companies';
        const fields = ['id', 'description_short'];
        const filters = [];
        const sort = [{ field: 'description_short', direction: 'asc' }];
        const options = {};
        this.api.select(typeof {}, sourceName, fields, filters, sort, options)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                data => {
                    this.companies = data;
                }
            );
    }

    private handleUserAuthentication() {
        const token = this.storage.getToken();
        this.loggedIn = !!this.storage.getToken();
        if (token) {
            this.selectedCompany = this.storage.getUserCompany();
            this.isUserSysAdmin = this.storage.isUserSysAdmin();
            if (this.isUserSysAdmin) {
                this.getCompanies();
            }

            this.role = this.storage.getRole();
            this.username = this.storage.getUserUsername();
            this.termsAndConditions = this.storage.getUserTermsAndConditions();
            this.contract = this.storage.getUserContract();
            this.menu = this.menu.filter(item => !(item.hideContract != undefined && item.hideContract == true
                && ['Partinta Iva', 'Occasionale'].indexOf(this.contract) > -1)
            );

            this.admin = this.role == 'admin';
            this.principal = this.role == 'principal';
        }
    }

    onCompanyChange(event) {
        this.storage.setUserCompany(event.value);
        window.location.reload();
    }

}
