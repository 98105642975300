<div class="upload-container">
    <div class="upload-area" [class.dragover]="isDragOver" (dragover)="onDragOver($event)" (dragleave)="onDragLeave()"
        (drop)="onDrop($event)" (click)="fileInput.click()">
        <p *ngIf="!selectedFile">
            <mat-icon>cloud_upload</mat-icon>
            <br />
            <span *ngIf="!isDragOver">Trascina un file qui o clicca per selezionarlo</span>
            <span *ngIf="isDragOver">Rilascia il file per caricarlo</span>
        </p>
        <input #fileInput id="fileInput" type="file" hidden (change)="onFileSelected($event)">
        <div *ngIf="selectedFile" class="file-preview" (click)="filePreviewClick($event)">
            <div class="file-icon">{{ fileExtension }}</div>
            <div class="file-details">
                <span class="file-name">
                    {{ selectedFile.name.length <= 40 ? selectedFile.name : selectedFile.name.substr(0,30) + '...' +
                        selectedFile.name.substr(-7) }} </span><br />
                        <span class="file-size">{{ readableFileSize }}</span>
            </div>
            <button mat-icon-button aria-label="Cancella il file selezionato" class="remove-btn" (click)="removeFile($event)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</div>