import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { Component, ElementRef, Type, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { StorageService } from 'src/app/services/storage.service';
import { NavigationEnd, Route, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { ApiService } from 'src/app/services/api.service';
import { debounceTime, filter, firstValueFrom, fromEvent, Subject, Subscription, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { CompanyReportModel } from 'src/app/models/db.model';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { ReportComponent } from '../report/report.component';
import { RoleGuard } from 'src/app/guards/role-guard.guard';
import { SessionService } from 'src/app/services/session.service';
import { Session } from 'src/app/models/session.model';
import { HttpClient } from '@angular/common/http';

const MENU_IMAGE = 'assets/img/cooperativeincloud/logo.png';

const ROLE_SYS_ADMIN = 'sys_admin';
const ROLE_ADMIN = 'admin';
const ROLE_USER = 'user';
const ROLE_PRINCIPAL = 'principal';
const ROLE_DEMO = 'demo';

const MODULE_DEMO = 'DEMO';
const MODULE_BASE = 'BASE';
const MODULE_SCHOOL = 'SCHOOL';
const MODULE_PUA = 'PUA';
const MODULE_DOC = 'DOC';
const MODULE_SYS_ADMIN = 'SYS_ADMIN';

@Component({
    selector: 'app-menu',
    imports: [
        CommonModule,
        MatSelectModule,
        MatListModule,
        MatIcon,
        CdkAccordionModule,
        CdkAccordionItem,
        RouterLink,
        RouterLinkActive,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatOptionModule
    ],
    templateUrl: './menu.component.html',
    styleUrl: './menu.component.css'
})
export class MenuComponent {
    private destroy$ = new Subject<void>();
    private subscriptions = new Subscription();
    @ViewChild('menuContainer', { read: ElementRef }) menuContainer!: ElementRef;

    loggedIn: boolean = false;
    selectedCompany: number = 0;
    companies: any[] = [];
    sysAdmin: boolean = false;
    admin: boolean = false;
    principal: boolean = false;
    username: string = '';
    termsAndConditions: boolean = false;
    mobile: boolean = false;
    smallScreen: boolean = false;
    menuImage: string;
    base_menu = [
        // { 'type': 'item', 'routerLink': '/presenze', 'icon': 'handshake', 'title': 'Presenze', 'role': ROLE_DEMO, 'modules': [MODULE_BASE] },

        { 'type': 'item', 'routerLink': '/', 'icon': 'home', 'title': 'Home', 'role': ROLE_USER, 'modules': [MODULE_BASE] },
        { 'type': 'item', 'routerLink': '/badge', 'icon': 'badge', 'title': 'Badge', 'role': ROLE_USER, 'modules': [MODULE_PUA] },
        { 'type': 'item', 'routerLink': '/presenze', 'icon': 'handshake', 'title': 'Presenze', 'role': ROLE_USER, 'modules': [MODULE_SCHOOL] },
        { 'type': 'item', 'routerLink': '/timbrature', 'icon': 'badge', 'title': 'Timbrature', 'role': ROLE_USER, 'modules': [MODULE_PUA] },
        { 'type': 'item', 'routerLink': '/assenze', 'icon': 'work_off', 'title': 'Assenze', 'role': ROLE_USER, 'hideContract': true, 'modules': [MODULE_SCHOOL] },
        { 'type': 'item', 'routerLink': '/assenze-pua', 'icon': 'work_off', 'title': 'Assenze PUA', 'role': ROLE_USER, 'hideContract': true, 'modules': [MODULE_PUA] },
        { 'type': 'item', 'routerLink': '/segnalazioni', 'icon': 'playlist_add_check', 'title': 'Segnalazioni', 'role': ROLE_USER, 'modules': [MODULE_SCHOOL] },
        { 'type': 'item', 'routerLink': '/segnalazioni-pua', 'icon': 'playlist_add_check', 'title': 'Segnalazioni PUA', 'role': ROLE_USER, 'modules': [MODULE_PUA] },
        { 'type': 'item', 'routerLink': '/upload', 'icon': 'cloud_upload', 'title': 'Upload', 'role': ROLE_ADMIN, 'modules': [MODULE_SCHOOL, MODULE_PUA] },
        { 'type': 'item', 'routerLink': '/statistiche', 'icon': 'leaderboard', 'title': 'Statistiche', 'role': ROLE_USER, 'modules': [MODULE_SCHOOL] },
        {
            'type': 'dropdown', 'icon': '', 'title': 'Admin', 'role': ROLE_SYS_ADMIN, 'items': [
                { 'type': 'item', 'routerLink': '/admin/statistiche', 'icon': 'leaderboard', 'title': 'Statistiche', 'role': ROLE_SYS_ADMIN, 'modules': [MODULE_SYS_ADMIN] },
                { 'type': 'item', 'routerLink': '/admin/cooperative', 'icon': 'handshake', 'title': 'Cooperative', 'role': ROLE_SYS_ADMIN, 'modules': [MODULE_SYS_ADMIN] },
                { 'type': 'item', 'routerLink': '/admin/operatori', 'icon': 'people_alt', 'title': 'Operatori', 'role': ROLE_SYS_ADMIN, 'modules': [MODULE_SYS_ADMIN] },
                { 'type': 'item', 'routerLink': '/admin/report', 'icon': 'grid_on', 'title': 'Report', 'role': ROLE_SYS_ADMIN, 'modules': [MODULE_SYS_ADMIN] },
            ], 'modules': [MODULE_SYS_ADMIN]
        },
        {
            'type': 'dropdown', 'icon': '', 'title': 'Anagrafiche', 'role': ROLE_ADMIN, 'items': [
                { 'type': 'item', 'routerLink': '/operatori', 'icon': 'people_alt', 'title': 'Operatori', 'role': ROLE_ADMIN, 'modules': [MODULE_BASE] },
                { 'type': 'item', 'routerLink': '/commesse', 'icon': 'business', 'title': 'Commesse', 'role': ROLE_ADMIN, 'modules': [MODULE_SCHOOL] },
                { 'type': 'item', 'routerLink': '/istituti', 'icon': 'school', 'title': 'Istituti', 'role': ROLE_ADMIN, 'modules': [MODULE_SCHOOL] },
                { 'type': 'item', 'routerLink': '/allievi', 'icon': 'child_care', 'title': 'Allievi', 'role': ROLE_ADMIN, 'modules': [MODULE_SCHOOL] },
                { 'type': 'item', 'routerLink': '/sedi', 'icon': 'badge', 'title': 'Sedi', 'role': ROLE_ADMIN, 'modules': [MODULE_PUA] },
                { 'type': 'item', 'routerLink': '/attivita', 'icon': 'edit_calendar', 'title': 'Attività', 'role': ROLE_ADMIN, 'modules': [MODULE_SCHOOL] },
                { 'type': 'item', 'routerLink': '/attivita-pua', 'icon': 'edit_calendar', 'title': 'Attività PUA', 'role': ROLE_ADMIN, 'modules': [MODULE_PUA] },
                { 'type': 'item', 'routerLink': '/tipi-assenze', 'icon': 'work_off', 'title': 'Tipi Assenze', 'role': ROLE_ADMIN, 'modules': [MODULE_SCHOOL] },
                { 'type': 'item', 'routerLink': '/tipi-assenze-pua', 'icon': 'work_off', 'title': 'Tipi Assenze PUA', 'role': ROLE_ADMIN, 'modules': [MODULE_PUA] },
                { 'type': 'item', 'routerLink': '/qualifiche', 'icon': 'edit_calendar', 'title': 'Qualifiche', 'role': ROLE_ADMIN, 'modules': [MODULE_SCHOOL] },
                { 'type': 'item', 'routerLink': '/anni', 'icon': 'refresh', 'title': 'Anni', 'role': ROLE_ADMIN, 'modules': [MODULE_SCHOOL] },
                { 'type': 'item', 'routerLink': '/tipi-documenti', 'icon': 'file_copy', 'title': 'Tipi Documenti', 'role': ROLE_ADMIN, 'modules': [MODULE_DOC] },
            ], 'modules': [MODULE_BASE]
        },
        {
            'type': 'dropdown', 'icon': '', 'title': 'Archivio', 'role': ROLE_ADMIN, 'items': [
                { 'type': 'item', 'routerLink': '/archivio/presenze', 'icon': 'people_alt', 'title': 'Presenze', 'role': ROLE_ADMIN, 'modules': [MODULE_SCHOOL] },
                { 'type': 'item', 'routerLink': '/archivio/assenze', 'icon': 'people_alt', 'title': 'Assenze', 'role': ROLE_ADMIN, 'modules': [MODULE_SCHOOL] },
            ], 'modules': [MODULE_SCHOOL]
        },
    ];
    menu: any[] = [];
    settings: Session;
    hasUpsert: boolean = false;
    currentVersion: string = "";
    private versionUrl = "/assets/version.json";

    constructor(
        private api: ApiService,
        private storage: StorageService,
        private router: Router,
        private sessionService: SessionService,
        private http: HttpClient
    ) {
        this.loadVersion();
    }

    ngOnInit() {
        this.checkScreenSize();
        this.loggedIn = !!this.storage.getToken();
        this.menu = [...this.base_menu];

        this.subscriptions.add(
            this.sessionService.userSettings$.subscribe((settings: Session) => {
                this.settings = new Session(settings);
                this.menu = [...this.base_menu];
                if (settings == null) return;

                this.termsAndConditions = settings.user.terms_and_conditions;
                this.sysAdmin = settings.user.sys_admin;
                this.admin = settings.user.admin;
                this.principal = settings.user.principal;
                this.username = settings.user.username;

                // rimuovo la voce assenze se l'utente è in un contratto è 3 o 4 (partita IVA o occasionale)
                this.menu = this.menu.filter(item => !(item.hideContract != undefined && item.hideContract == true
                    && [3, 4].indexOf(settings.user.id_contract) > -1)
                );
                this.menuImage = settings.company.image_menu || MENU_IMAGE;

                this.loadCompanyReports(settings.company.reports);
                if (this.sysAdmin) {
                    this.getCompanies();
                }
            })
        );

        this.selectedCompany = this.storage.getUserCompany();
        this.router.events
            .pipe(
                filter((event): event is NavigationEnd => event instanceof NavigationEnd)
            )
            .subscribe((ev: NavigationEnd) => {
                this.checkScreenSize();
            });

        fromEvent(window, 'resize')
            .pipe(debounceTime(150), takeUntil(this.destroy$))
            .subscribe(() => this.checkScreenSize());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.subscriptions.unsubscribe(); // Annulla tutte le sottoscrizioni
    }

    private loadCompanyReports(companyReports: CompanyReportModel[]) {
        let reportList = [];
        companyReports?.forEach((companyReport: CompanyReportModel) => {
            const menu_item = {
                'type': 'item',
                'routerLink': companyReport.path,
                'icon': companyReport.icon,
                'title': companyReport.description,
                'role': ROLE_ADMIN,
                'modules': [companyReport.module],
            };
            if (this.enabledMenu([menu_item]).length > 0) {
                // preapro la voce di menu per ogni report
                reportList.push(menu_item);
                // aggiungo le rotte per i report
                this.addRoute(companyReport.path, ReportComponent, companyReport.entityName, [ROLE_ADMIN]);
            }
        });
        if (reportList.length > 0) {
            this.menu.push({ 'type': 'dropdown', 'icon': 'report', 'title': 'Report', 'role': ROLE_ADMIN, 'items': reportList, 'modules': [MODULE_BASE] });
        }
    }

    private addRoute(_path: string, _component: Type<any>, _source: string, _rolesList: string[]) {
        const newRoute: Route = {
            // se il primo carattere è / lo rimuove in quanto la rotto non può iniziare con /
            path: _path.startsWith('/') ? _path.substring(1) : _path,
            component: _component,
            canActivate: [RoleGuard],
            data: { roles: _rolesList, source: _source },
        };

        // Recupera le rotte attuali
        const existingRoutes = this.router.config;

        // Combina le rotte esistenti con le nuove
        const updatedRoutes = [newRoute, ...existingRoutes];

        // Aggiorna la configurazione delle rotte
        this.router.resetConfig(updatedRoutes);
    }

    private checkScreenSize() {
        this.smallScreen = window.innerWidth < 768;
        this.mobile = this.storage.getMobile() == 'true' ? true : false;
    }

    private getCompanies() {
        const sourceName = 'companies';
        const fields = ['id', 'description_short'];
        const filters = [];
        const sort = [{ field: 'description_short', direction: 'asc' }];
        const options = {};
        this.api.select(typeof {}, sourceName, fields, filters, sort, options)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                data => {
                    this.companies = data;
                    if (!this.selectedCompany) {
                        this.selectedCompany = this.settings.company.id;
                    }
                }
            );
    }

    onCompanyChange(event) {
        this.storage.setUserCompany(event.value);
        window.location.reload();
    }

    enabledMenu(menu: any) {
        // se menu non è un oggetto, ritorna false
        if (typeof menu != 'object') return [];

        const modules = this.settings.getModules();
        // controlla se il modulo previsto dalla voce di menu è attivo per l'utente e la sua società
        const filteredMenu = menu.filter(item => {
            // item.modules contiene 'BASE' oppure ha almeno un elemento in comune con settings.modules
            return (item.modules?.includes(MODULE_BASE) || item.modules?.some((module: string) => modules.includes(module))) &&
                (this.settings.user.roles.includes(item.role) || (item.role == ROLE_DEMO && this.settings.user.username == 'demo.user'));
        });
        return filteredMenu;
    }

    toggleDropdown(accordionItem: any, index: number) {
        accordionItem.toggle();

        setTimeout(() => {
            if (accordionItem.expanded) {
                this.scrollToDropdown(index);
            }
        }, 200);
    }

    scrollToDropdown(index: number) {
        const menu = this.menuContainer.nativeElement;
        const targetDropdown = document.getElementById(`accordion-body-${index}`);

        if (menu && targetDropdown) {
            const offsetTop = targetDropdown.offsetTop;
            const offsetCorrection = 68;
            menu.scrollTo({ top: offsetTop - offsetCorrection, behavior: 'smooth' });
        }
    }

    async loadVersion() {
        try {
            const data = await firstValueFrom(this.http.get<{ version: string }>(this.versionUrl));
            this.currentVersion = data.version;
        } catch (error) {
            console.error("Errore nel caricamento della versione:", error);
        }
    }
}
