import { Component, EventEmitter, Output, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormModalComponent } from '../form-modal/form-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import L from 'leaflet';
import 'leaflet-draw';

@Component({
    selector: 'app-geofence-map',
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatFormField,
        MatInputModule,
        MatIcon
    ],
    templateUrl: './geofence-builder.component.html',
    styleUrl: './geofence-builder.component.css'
})
export class GeofenceMapComponent implements OnInit, OnDestroy {
    @Output() geofenceSelected = new EventEmitter<string>();

    private map!: L.Map;
    private drawControl!: L.Control.Draw;
    private drawnItems = new L.FeatureGroup();
    currentLayer: L.Layer | null = null;
    geofence: any;

    constructor(
        public dialogRef: MatDialogRef<FormModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { geofence: string }
    ) {
        this.geofence = data.geofence;
    }

    ngOnInit() {
        this.initMap();
    }

    ngOnDestroy() {
        this.map.remove();
    }

    private initMap() {
        this.map = L.map('map').setView([41.8902, 12.4922], 17);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);

        L.drawLocal.draw.toolbar.actions.title = 'Annulla disegno';
        L.drawLocal.draw.toolbar.actions.text = 'Annulla';
        L.drawLocal.draw.toolbar.finish.title = 'Termina disegno';
        L.drawLocal.draw.toolbar.finish.text = 'Termina';
        L.drawLocal.draw.toolbar.undo.title = 'Elimina ultimo punto disegnato';
        L.drawLocal.draw.toolbar.undo.text = 'Elimina ultimo punto';
        L.drawLocal.draw.toolbar.buttons.polygon = 'Disegna un poligono';
        L.drawLocal.draw.handlers.polygon.tooltip.start = 'Clicca per iniziare a disegnare';
        L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Clicca per continuare a disegnare';
        L.drawLocal.draw.handlers.polygon.tooltip.end = 'Clicca sul primo punto per chiudere il poligono';
        L.drawLocal.edit.toolbar.actions.save.title = 'Salva modifiche';
        L.drawLocal.edit.toolbar.actions.save.text = 'Salva';
        L.drawLocal.edit.toolbar.actions.cancel.title = 'Annulla modifiche';
        L.drawLocal.edit.toolbar.actions.cancel.text = 'Annulla';
        L.drawLocal.edit.toolbar.actions.clearAll.title = 'Cancella tutto';
        L.drawLocal.edit.toolbar.actions.clearAll.text = 'Cancella tutto';
        L.drawLocal.edit.toolbar.buttons.edit = 'Modifica';
        L.drawLocal.edit.toolbar.buttons.editDisabled = 'Nessun layer da modificare';
        L.drawLocal.edit.toolbar.buttons.remove = 'Elimina';
        L.drawLocal.edit.toolbar.buttons.removeDisabled = 'Nessun layer da eliminare';
        L.drawLocal.edit.handlers.edit.tooltip.subtext = 'Trascina i marker per modfiicare il poligono.';
        L.drawLocal.edit.handlers.edit.tooltip.text = 'Clicca su un vertice attivo per eliminarlo.<br />Clicca su un vertice non attivo per aggiungerlo.<br /><br />Clicca "Annulla" per interrompere la modifica senza salvarla.<br />Clicca "Salva" per confermare le modifiche.';

        // Richiedi la posizione dell'utente
        if (navigator.geolocation && !this.geofence) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    this.map.setView([latitude, longitude], 17);
                },
                (error) => {
                    console.error('Geolocation error:', error);
                    // La mappa rimane centrata sulla posizione di default
                }
            );
        }

        if (this.geofence) {
            const geoJson = JSON.parse(this.geofence);
            const geoJsonLayer = L.geoJSON(geoJson);

            geoJsonLayer.eachLayer((layer: any) => {
                // Extract coordinates from the GeoJSON feature
                const coordinates = layer.feature.geometry.coordinates[0];

                // Convert GeoJSON coordinates [lng,lat] to Leaflet [lat,lng]
                const latLngs = coordinates.map((coord: number[]) => [coord[1], coord[0]]);

                const polygon = new L.Polygon(latLngs, {
                    stroke: true,
                    color: '#3388ff',
                    weight: 4,
                    opacity: 0.5,
                    fill: true
                });

                this.currentLayer = polygon;
                this.drawnItems.addLayer(polygon);
            });

            if (this.drawnItems.getBounds().isValid()) {
                this.map.fitBounds(this.drawnItems.getBounds());
            }
        }

        this.map.addLayer(this.drawnItems);
        this.drawControl = new L.Control.Draw({
            draw: {
                polygon: {
                    allowIntersection: true,
                    showArea: true,
                },
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false,
                polyline: false
            },
            edit: {
                featureGroup: this.drawnItems,
                remove: true
            }
        });

        // Aggiungi il controllo di disegno alla mappa
        this.map.addControl(this.drawControl);

        // Aggiungi eventi per la creazione, modifica e cancellazione dei poligoni
        this.map.on(L.Draw.Event.DRAWSTART, (e: any) => this.onDrawStart(e));
        this.map.on(L.Draw.Event.CREATED, (e: any) => this.onDrawCreated(e));
        this.map.on(L.Draw.Event.EDITED, (e: any) => this.onDrawEdited(e));
        this.map.on(L.Draw.Event.DELETED, (e: any) => this.onDrawDeleted(e));
    }

    private onDrawStart(e: any) {
        // if (this.currentLayer) {
        //     this.drawnItems.removeLayer(this.currentLayer);
        // }
    }

    private onDrawCreated(e: any) {
        if (this.currentLayer) {
            this.drawnItems.removeLayer(this.currentLayer);
        }

        this.currentLayer = e.layer;
        this.drawnItems.addLayer(this.currentLayer);
    }

    private onDrawEdited(e: any) {
        const editedLayers = e.layers;
        editedLayers.eachLayer((layer: L.Layer) => {
            this.currentLayer = layer;
        });
    }

    private onDrawDeleted(e: any) {
        this.currentLayer = null;
    }

    confirmGeofence() {
        if (!this.currentLayer) return;
        if ('toGeoJSON' in this.currentLayer) {
            const geoJson = (this.currentLayer as L.Layer & { toGeoJSON: () => any }).toGeoJSON();
            this.geofenceSelected.emit(JSON.stringify(geoJson));
        } else {
            console.error('Il layer corrente non supporta toGeoJSON');
        }
    }

    cancel(): void {
        this.dialogRef.close({ event: 'cancel' });
    }

    onSubmit() {
        if (this.currentLayer && 'toGeoJSON' in this.currentLayer) {
            this.dialogRef.close({ result: 'ok', geofence: (this.currentLayer as L.Layer & { toGeoJSON: () => any }).toGeoJSON() });
        } else {
            console.error('Il layer corrente non supporta toGeoJSON');
            this.dialogRef.close({ result: 'error', message: 'Il layer corrente non supporta toGeoJSON' });
        }
    }

    searchLocation(query: string) {
        if (!query) return;

        const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data && data.length > 0) {
                    const { lat, lon } = data[0];
                    this.map.setView([lat, lon], 17);
                }
            });
    }
}