import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { CompanyReportModel } from 'src/app/models/db.model';
import { Session } from 'src/app/models/session.model';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-report-home',
    imports: [CommonModule, RouterModule, MatIcon],
    templateUrl: './report-home.component.html',
    styleUrl: './report-home.component.css'
})
export class ReportHomeComponent {
    private subscriptions = new Subscription();
    reports: CompanyReportModel[] = [];

    constructor(
        private sessionService: SessionService,
    ) { }

    ngOnInit() {
        this.subscriptions.add(
            this.sessionService.userSettings$.subscribe((settings: Session) => {
                this.reports = settings.company.reports;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe(); // Annulla tutte le sottoscrizioni
    }
}
