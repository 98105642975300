import { Component, ViewChild } from '@angular/core';
import { StorageService } from './services/storage.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { ApiService } from './services/api.service';
import { Subject, takeUntil } from 'rxjs';
import { CompanyModel } from './models/db.model';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environment/environment';

const DEFAULT_TITLE = 'Cooperative in Cloud';
const PRODUCTION = environment.production;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  private destroy$ = new Subject<void>();
  siteFavicon: HTMLLinkElement = document.querySelector('#site_favicon');
  siteTitle: HTMLLinkElement = document.querySelector('#site_title');
  smallScreen: boolean = false;
  isUserSysAdmin: boolean = false;

  role: string = 'user';
  menu = [
    { 'type': 'item', 'routerLink': '/', 'icon': 'home', 'title': 'Home', 'role': 'user' },
    { 'type': 'item', 'routerLink': '/presenze', 'icon': 'handshake', 'title': 'Presenze', 'role': 'user' },
    { 'type': 'item', 'routerLink': '/assenze', 'icon': 'work_off', 'title': 'Assenze', 'role': 'user', 'hideContract': true },
    { 'type': 'item', 'routerLink': '/segnalazioni', 'icon': 'playlist_add_check', 'title': 'Segnalazioni', 'role': 'user' },
    { 'type': 'item', 'routerLink': '/upload', 'icon': 'cloud_upload', 'title': 'Upload', 'role': 'admin' },
    { 'type': 'item', 'routerLink': '/statistiche', 'icon': 'leaderboard', 'title': 'Statistiche', 'role': 'user' },
    {
      'type': 'dropdown', 'icon': 'dog', 'title': 'Anagrafiche', 'role': 'admin', 'items': [
        { 'type': 'item', 'routerLink': '/commesse', 'icon': 'business', 'title': 'Commesse', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/istituti', 'icon': 'school', 'title': 'Istituti', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/allievi', 'icon': 'child_care', 'title': 'Allievi', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/operatori', 'icon': 'people_alt', 'title': 'Operatori', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/attivita', 'icon': 'edit_calendar', 'title': 'Attività', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/qualifiche', 'icon': 'edit_calendar', 'title': 'Qualifiche', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/anni', 'icon': 'refresh', 'title': 'Anni', 'role': 'admin' },
      ]
    },
    {
      'type': 'dropdown', 'icon': 'people_alt', 'title': 'Archivio', 'role': 'admin', 'items': [
        { 'type': 'item', 'routerLink': '/archivio/presenze', 'icon': 'people_alt', 'title': 'Presenze', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/archivio/assenze', 'icon': 'people_alt', 'title': 'Assenze', 'role': 'admin' },
      ]
    },
    {
      'type': 'dropdown', 'icon': 'report', 'title': 'Report', 'role': 'admin', 'items': [
        { 'type': 'item', 'routerLink': '/report/istituto-mese', 'icon': 'grid_on', 'title': 'Istituto mese', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/operatore-mese', 'icon': 'grid_on', 'title': 'Operatore mese', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/istituto-settimana', 'icon': 'grid_on', 'title': 'Istituto settimana', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/operatore-settimana', 'icon': 'grid_on', 'title': 'Operatore settimana', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/contratto-settimana', 'icon': 'grid_on', 'title': 'Contratto settimana', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/sostituzione-settimana', 'icon': 'grid_on', 'title': 'Sostituzione settimana', 'role': 'admin' },
        { 'type': 'item', 'routerLink': '/report/settimane-incomplete', 'icon': 'grid_on', 'title': 'Settimane incomplete', 'role': 'admin' },
      ]
    },
  ];

  loggedIn: boolean = false;
  termsAndConditions: boolean = false;
  admin: boolean = false;
  principal: boolean = false;
  opened: boolean;
  events: string[] = [];
  username: string = '';
  mobile: boolean = false;
  contract: string;
  companies: [] = [];
  selectedCompany: number = 0;
  companyInfo: CompanyModel;
  menuImage: string;
  headerImage: string;

  constructor(
    private storage: StorageService,
    private api: ApiService,
    private router: Router,
    private titleService: Title,
    private meta: Meta
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        // chiude il sidenav quando viene rilevato il cambio di pagina, solo small screen
        if(this.smallScreen) this.sidenav?.close();
        // recupera eventuale parametro "mobile" settato solo da app mobile
        this.mobile = this.storage.getMobile() == 'true' ? true : false;
        // recupera e controlla il ruolo dell'utente
        const token = this.storage.getToken();
        if (token) {
          this.loggedIn = true;
          this.selectedCompany = this.storage.getUserCompany();
          this.isUserSysAdmin = this.storage.isUserSysAdmin();
          if(this.isUserSysAdmin) {
            this.getCompanies();
          }

          this.role = this.storage.getRole();
          this.username = this.storage.getUserUsername();
          this.termsAndConditions = this.storage.getUserTermsAndConditions();
          this.contract = this.storage.getUserContract();
          this.menu = this.menu.filter(item => !(item.hideContract != undefined && item.hideContract == true
              && ['Partinta Iva', 'Occasionale'].indexOf(this.contract) > -1)
          );

          if (this.role == 'admin') {
            this.admin = true;
          } else {
            this.admin = false;
          }
          if (this.role == 'principal') {
            this.principal = true;
          } else {
            this.principal = false;
          }
        } else {
          this.loggedIn = false;
        }
      }
    });
    this.checkScreenSize();
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        this.companyInfo = this.storage.getCompanyInfo();
        this.menuImage = this.companyInfo.image_menu;
        this.headerImage = this.companyInfo.image_header;
        this.siteFavicon.href = this.companyInfo.image_favicon;
        // setto il titolo della pagina concatenandolo al default
        this.companyInfo.description.length > 0 && this.companyInfo.description != DEFAULT_TITLE ?
          this.titleService.setTitle(this.companyInfo.description + ' - ' + DEFAULT_TITLE)
          : this.titleService.setTitle(DEFAULT_TITLE);
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }

  ngOnInit() {
    this.companyInfo = this.storage.getCompanyInfo();
    this.menuImage = this.companyInfo.image_menu;
    this.headerImage = this.companyInfo.image_header;
    if(!PRODUCTION) {
      // prevenie che il sito di test venga indicizzato
      this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCompanies() {
    const sourceName = 'companies';
    const fields = ['id', 'description_short'];
    const filters = [];
    const sort = [{field: 'description_short', direction: 'asc'}];
    const options = {};
    this.api.select( typeof {}, sourceName, fields, filters, sort, options)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.companies = data;
          }
        );
  }

  onCompanyChange(event) {
    this.storage.setUserCompany(event.value);
    window.location.reload();
  }

  checkScreenSize() {
    this.smallScreen = window.innerWidth < 768;
  }
}
