import { Injectable } from '@angular/core';
import { UserModel } from '../models/user.model';
import { CompanyModel } from '../models/db.model';

const HOME_TITLE = 'Cooperative in Cloud';
const HOME_IMAGE = 'assets/img/cooperativeincloud/sfondo-home.png';
const HEADER_IMAGE = 'assets/img/cooperativeincloud/logo-header.png';
const MENU_IMAGE = 'assets/img/cooperativeincloud/logo.png';
const MENU_FAVICON = 'assets/img/cooperativeincloud/favicon.ico';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  companyId: number | null;
  companyDescription: string;
  companyDescriptionShort: string;
  companyImageMenu: string;
  companyImageHeader: string;
  companyImageHome: string;
  companyImageFavicon: string;

  constructor() {
    this.refreshData()
  }

  refreshData() {
    this.companyId = +this.validStringOrNull(this.getCompanyId()) || null;
    this.companyDescription = this.validStringOrNull(this.getCompanyDescription()) || HOME_TITLE;
    this.companyDescriptionShort = this.validStringOrNull(this.getCompanyDescriptionShort()) || HOME_TITLE;
    this.companyImageMenu = this.validStringOrNull(this.getCompanyImageMenu()) || MENU_IMAGE;
    this.companyImageHeader = this.validStringOrNull(this.getCompanyImageHeader()) || HEADER_IMAGE;
    this.companyImageHome = this.validStringOrNull(this.getCompanyImageHome()) || HOME_IMAGE;
    this.companyImageFavicon = this.validStringOrNull(this.getCompanyImageFavicon()) || MENU_FAVICON;
  }

  validStringOrNull(str: string) {
    return !!str && str && str.trim() !== '' && str != 'null' && str != 'undefined' ? str : null;
  }

  saveUser(data: any) {
    sessionStorage.setItem('token', data.access_token);
    if (data.refresh_token)
      sessionStorage.setItem('refreshToken', data.refresh_token);
    return true;
  }

  getToken() {
    return sessionStorage.getItem('token');
  }

  getMobile() {
    return sessionStorage.getItem('mobile');
  }

  getRefreshToken() {
    return sessionStorage.getItem('refreshToken');
  }

  getRole() {
    return sessionStorage.getItem('role');
  }

  setRole(role: string) {
    sessionStorage.setItem('role', role);
  }

  setUser(user: UserModel) {
    sessionStorage.setItem('role', user.admin ? 'admin' : user.principal ? 'principal' : 'user');
    sessionStorage.setItem('sys_admin', user.sys_admin ? '1' : '0');
    sessionStorage.setItem('username', user.username);
    sessionStorage.setItem('terms_and_conditions', user.terms_and_conditions?.toString());
    sessionStorage.setItem('contract', user.contract);
    if(!user.sys_admin) sessionStorage.setItem('id_company', user.id_company ? user.id_company?.toString() : null);
  }

  setCompanyInfo(company: CompanyModel) {
    this.companyId = company.id;
    this.companyDescription = company.description || HOME_TITLE;
    this.companyDescriptionShort = company.description_short || HOME_TITLE;
    this.companyImageMenu = company.image_menu || MENU_IMAGE;
    this.companyImageHeader = company.image_header || HEADER_IMAGE;
    this.companyImageHome = company.image_home || HOME_IMAGE;
    this.companyImageFavicon = company.image_favicon || MENU_FAVICON;

    sessionStorage.setItem('company_id', company.id?.toString());
    sessionStorage.setItem('company_description', company.description);
    sessionStorage.setItem('company_description_short', company.description_short);
    sessionStorage.setItem('company_image_menu', company.image_menu);
    sessionStorage.setItem('company_image_header', company.image_header);
    sessionStorage.setItem('company_image_home', company.image_home);
    sessionStorage.setItem('company_image_favicon', company.image_favicon);
  }

  clearCompanyInfo() {
    sessionStorage.removeItem('company_id');
    sessionStorage.removeItem('company_description');
    sessionStorage.removeItem('company_description_short');
    sessionStorage.removeItem('company_image_menu');
    sessionStorage.removeItem('company_image_header');
    sessionStorage.removeItem('company_image_home');
    sessionStorage.removeItem('company_image_favicon');
  }

  getCompanyId(): string {
    return sessionStorage.getItem('company_id');
  }

  getCompanyDescription(): string {
    return sessionStorage.getItem('company_description');
  }

  getCompanyDescriptionShort(): string {
    return sessionStorage.getItem('company_description_short');
  }

  getCompanyImageMenu(): string {
    return sessionStorage.getItem('company_image_menu');
  }

  getCompanyImageHeader(): string {
    return sessionStorage.getItem('company_image_header');
  }

  getCompanyImageHome(): string {
    return sessionStorage.getItem('company_image_home');
  }

  getCompanyImageFavicon(): string {
    return sessionStorage.getItem('company_image_favicon');
  }

  getCompanyInfo(): CompanyModel {
    return {
      id: this.companyId,
      description: this.companyDescription,
      description_short: this.companyDescriptionShort,
      image_menu: this.companyImageMenu,
      image_header: this.companyImageHeader,
      image_home: this.companyImageHome,
      image_favicon: this.companyImageFavicon
    };
  }

  getUserUsername(): string {
    return sessionStorage.getItem('username');
  }

  getUserTermsAndConditions(): boolean {
    return sessionStorage.getItem('terms_and_conditions') == '1' ? true : false;
  }

  getUserContract(): string {
    return sessionStorage.getItem('contract');
  }

  isUserSysAdmin(): boolean {
    return sessionStorage.getItem('sys_admin') == '1' ? true : false;
  }

  getUserCompany(): number {
    return parseInt(sessionStorage.getItem('id_company')) || 0;
  }

  setUserCompany(id_company: number) {
    sessionStorage.setItem('id_company', id_company.toString());
  }

  deleteUser() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('terms_and_conditions');
    sessionStorage.removeItem('sys_admin');
    sessionStorage.removeItem('contract');
    sessionStorage.removeItem('id_company');
    return true;
  }
}
