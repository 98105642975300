import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthService } from '../../services/auth.services';
import { StorageService } from '../../services/storage.service';
import { NotifierService } from 'src/app/services/notifier.service';

import { environment } from '../../../environment/environment';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from "rxjs";

const RECAPTCHA_SITE_KEY = environment.RECAPTCHA_SITE_KEY;

declare var grecaptcha: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: false
})

export class LoginComponent implements OnInit {
    @ViewChild('username') username: ElementRef;

    loginForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';
    hide: boolean = true;
    private destroy$ = new Subject<void>();
    currentVersion: string = "";
    private versionUrl = "/assets/version.json";

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private auth: AuthService,
        private storage: StorageService,
        private notifier: NotifierService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private http: HttpClient
    ) {
        // redirect to home if already logged in
        if (this.storage.getToken()) {
            this.router.navigate(['/']);
        }

        this.loadVersion();
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // reCAPTCHA script
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
        document.body.appendChild(script);
    }

    ngAfterViewInit() {
        this.username.nativeElement.focus();
        this.changeDetectorRef.detectChanges();
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        grecaptcha.ready(() => {
            grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'login' }).then((token) => {
                this.auth.login(this.f.username.value, this.f.password.value, token)
                    .pipe(first())
                    .subscribe({
                        next: (data) => {
                            this.storage.saveUser(data);
                            this.router.navigate(['/']);
                            // this.api.saveCompanyInfo();
                        },
                        error: err => {
                            if (err.status == 444) {
                                // 444 ha la gestione centralizzata in jwt interceptor, quindi non viene mostrato un altro messaggio di errore
                            } else if (err.status == 425) {
                                // accesso con password temporanea, verrà reindirizzato alla pagina di cambio password
                                this.notifier.showWarning('Attenzione', 'È stata utilizzata una password provvisoria. È necessario cambiarla!');
                            } else if (err.status == 401) {
                                // credenziali non valide
                                this.notifier.showError('Errore', 'Credenziali non valide.');
                            } else {
                                // errore imprevisto, loggo l'errore in console e mostro il messaggio di errore generico
                                console.error(err);
                                this.notifier.showError('Errore', 'Si è verificato un errore imprevisto, si prega di riprovare. Contattare gli amministratori del sistema se l\'errore persiste.');
                            }
                            this.loading = false;
                        }
                    });
            });
        });
    }

    async loadVersion() {
        try {
            const data = await firstValueFrom(this.http.get<{ version: string }>(this.versionUrl));
            this.currentVersion = data.version;
        } catch (error) {
            console.error("Errore nel caricamento della versione:", error);
        }
    }
}