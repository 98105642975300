<div class="container" style="margin-top: 36px;">
	<div class="home-image-container">
		<img src="{{homeImage}}" alt="Background" class="home-background-image">
		<h1 class="home-image-text">{{homeTitle}}</h1>
	</div>
</div>

<div class="container" style="margin-top: 48px;">
	<div class="home-grid">

		<!-- MODULE SCHOOL -->
		<ng-container *ngIf="settings && settings.hasModule('SCHOOL')">
			<div class="home-item" [routerLink]="'/presenze'">
				<img src="assets/img/Icona Presenze.svg" alt="Presenze" class="home-icon">
				<span class="home-text">Presenze</span>
			</div>
			<div class="home-item" [routerLink]="'/assenze'" *ngIf="contractHasAbsences">
				<img src="assets/img/Icona Assenze.svg" alt="Assenze" class="home-icon">
				<span class="home-text">Assenze</span>
			</div>
			<div class="home-item" [routerLink]="'/segnalazioni'">
				<img src="assets/img/Icona Segnalazioni.svg" alt="Segnalazioni" class="home-icon">
				<span class="home-text">Segnalazioni</span>
			</div>
			<div class="home-item" [routerLink]="'/statistiche'">
				<img src="assets/img/Icona Statistiche.svg" alt="Statistiche" class="home-icon">
				<span class="home-text">Statistiche</span>
			</div>
		</ng-container>

		<!-- MODULE PUA -->
		<ng-container *ngIf="settings && settings.hasModule('PUA')">
			<div class="home-item" [routerLink]="'/badge'">
				<mat-icon class="home-icon">badge</mat-icon>
				<span class="home-text">Badge</span>
			</div>
			<div class="home-item" [routerLink]="'/assenze-pua'" *ngIf="contractHasAbsences">
				<img src="assets/img/Icona Assenze.svg" alt="Assenze PUA" class="home-icon">
				<span class="home-text">Assenze PUA</span>
			</div>
			<div class="home-item" [routerLink]="'/segnalazioni-pua'">
				<img src="assets/img/Icona Segnalazioni.svg" alt="Segnalazioni PUA" class="home-icon">
				<span class="home-text">Segnalazioni PUA</span>
			</div>
		</ng-container>

	</div>
</div>