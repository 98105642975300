import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotifierService {

    constructor(private toastr: ToastrService) { }

    showSuccess(title: string, message: string, option?: {}) {
        this.toastr.success(message, title, option);
    }

    showError(title: string, message: string, option?: {}) {
        this.toastr.error(message, title, option);
    }

    showWarning(title: string, message: string, option?: {}) {
        this.toastr.warning(message, title, option);
    }

    showInfo(title: string, message: string, option?: {}) {
        this.toastr.info(message, title, option);
    }
}
