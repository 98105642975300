import { Component, ViewChild } from '@angular/core';
import { StorageService } from './services/storage.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { ApiService } from './services/api.service';
import { debounceTime, filter, fromEvent, Subject, takeUntil } from 'rxjs';
import { CompanyModel } from './models/db.model';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environment/environment';

const DEFAULT_TITLE = 'Cooperative in Cloud';
const PRODUCTION = environment.production;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent {
    @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
    private destroy$ = new Subject<void>();
    siteFavicon: HTMLLinkElement = document.querySelector('#site_favicon');
    siteTitle: HTMLLinkElement = document.querySelector('#site_title');
    smallScreen: boolean = false;
    isProduction: boolean = true;
    loggedIn: boolean = false;
    termsAndConditions: boolean = false;
    opened: boolean;
    events: string[] = [];
    username: string = '';
    mobile: boolean = false;
    companyInfo: CompanyModel;
    headerImage: string;

    constructor(
        private storage: StorageService,
        private api: ApiService,
        private router: Router,
        private titleService: Title,
        private meta: Meta,
    ) {
        this.isProduction = PRODUCTION;

        this.loggedIn = !!this.storage.getToken();
        if (this.storage.getToken()) {
            // carico i dati della company ( a seguire anche i report)
            this.api.saveCompanyInfo();
        }

        this.checkScreenSize();
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.handlePageOrScreenChange());

        // resta in attesa del salvataggio delle companyInfo
        this.storage.companyInfo$.subscribe(companyInfo => {
            this.loadCompanyInfo(companyInfo);
        });
    }

    ngOnInit() {
        if (!PRODUCTION) {
            // prevenie che il sito di test venga indicizzato
            this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
        }

        fromEvent(window, 'resize')
            .pipe(debounceTime(150), takeUntil(this.destroy$))
            .subscribe(() => this.handlePageOrScreenChange());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private handlePageOrScreenChange() {
        this.checkScreenSize();
        this.loggedIn = !!this.storage.getToken();
        this.username = this.storage.getUserUsername();
        this.termsAndConditions = this.storage.getUserTermsAndConditions();
    }

    private loadCompanyInfo(companyInfo: CompanyModel) {
        this.companyInfo = companyInfo;
        // procedo solo se sono stati caricati i dati della company
        if (!this.companyInfo) return;

        this.headerImage = this.companyInfo.image_header;
        this.siteFavicon.href = this.companyInfo.image_favicon;

        this.companyInfo.description?.length > 0 && this.companyInfo.description != DEFAULT_TITLE ?
            this.titleService.setTitle(this.companyInfo.description + ' - ' + DEFAULT_TITLE)
            : this.titleService.setTitle(DEFAULT_TITLE);
    }

    private checkScreenSize() {
        this.smallScreen = window.innerWidth < 768;
        if (this.smallScreen) this.sidenav?.close();
        this.mobile = this.storage.getMobile() == 'true' ? true : false;
    }
}
