import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Subscription } from 'rxjs';
import { Session } from 'src/app/models/session.model';
import { NotifierService } from 'src/app/services/notifier.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-file-upload',
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule
    ],
    templateUrl: './file-upload.component.html',
    styleUrl: './file-upload.component.css'
})
export class FileUploadComponent {
    @Output() fileUploaded = new EventEmitter<File>();

    private subscriptions = new Subscription();
    selectedFile: File | null = null;
    isDragOver = false;
    settings: Session;

    constructor(
        private notifier: NotifierService,
        private sessionService: SessionService
    ) { }

    ngOnInit() {
        this.subscriptions.add(
            this.sessionService.userSettings$.subscribe((settings: Session) => {
                this.settings = new Session(settings);
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe(); // Annulla tutte le sottoscrizioni
    }

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            this.updateSelectedFile(input.files[0]);
        }
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
        this.isDragOver = true;
    }

    onDragLeave(): void {
        this.isDragOver = false;
    }

    onDrop(event: DragEvent): void {
        event.preventDefault();
        this.isDragOver = false;
        if (event.dataTransfer?.files.length) {
            this.updateSelectedFile(event.dataTransfer.files[0]);
        }
    }

    removeFile(event): void {
        event.stopPropagation();
        this.updateSelectedFile(null);
        const input = document.getElementById('fileInput') as HTMLInputElement;
        input.value = '';
    }

    filePreviewClick(event): void {
        event.stopPropagation();
    }

    get fileExtension(): string {
        return this.selectedFile ? this.selectedFile.name.split('.').pop()?.toUpperCase() || 'FILE' : '';
    }

    get readableFileSize(): string {
        if (!this.selectedFile) return '';
        const size = this.selectedFile.size;
        // if (size < 1024) return `${size} B`;
        // if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
        return this.formatFileSize(size);
    }

    formatFileSize(size: number, locale: string = navigator.language) {
        if (size > 0) {
            const units = ['B', 'KB', 'MB', 'GB', 'TB'];
            let unitIndex = 0;
            while (size >= 1024 && unitIndex < units.length - 1) {
                size /= 1024;
                unitIndex++;
            }
            if (size >= 1000 && unitIndex < units.length - 1) {
                size = size / 1024;
                unitIndex++;
            }
            const formattedSize = new Intl.NumberFormat(locale, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(size);
            return `${formattedSize} ${units[unitIndex]}`;
        }
        return '0 B';
    }

    private updateSelectedFile(file: File): void {
        const free_storage = this.settings.getFreeStorage();
        if (file?.size > free_storage) {
            this.notifier.showError('Attenzione', 'La dimensione del file supera lo spazio reisduo a disposizione. Dimensione residua: ' + this.formatFileSize(free_storage));
            this.selectedFile = null;
            return;
        }

        this.selectedFile = file;
        this.fileUploaded.emit(this.selectedFile);
    }
}
