import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';

import { AuthService } from '../../services/auth.services';
import { StorageService } from '../../services/storage.service';
import { NotifierService } from 'src/app/services/notifier.service';

import { environment } from '../../../environment/environment';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CompanyModel } from 'src/app/models/db.model';
const RECAPTCHA_SITE_KEY = environment.RECAPTCHA_SITE_KEY;

declare var grecaptcha: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: false
})

export class LoginComponent implements OnInit {
    @ViewChild('username') username: ElementRef;

    loginForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';
    hide: boolean = true;
    private destroy$ = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private auth: AuthService,
        private api: ApiService,
        private storage: StorageService,
        private notifier: NotifierService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        // redirect to home if already logged in
        if (this.storage.getToken()) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // reCAPTCHA script
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
        document.body.appendChild(script);
    }

    ngAfterViewInit() {
        this.username.nativeElement.focus();
        this.changeDetectorRef.detectChanges();
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        grecaptcha.ready(() => {
            grecaptcha.execute(RECAPTCHA_SITE_KEY, {action: 'login'}).then((token) => {
                this.auth.login(this.f.username.value, this.f.password.value, token)
                    .pipe(first())
                    .subscribe({
                        next: (data) => {
                            this.storage.saveUser(data);
                            this.saveCompanyInfo();
                        },
                        error: err => {
                            if (err.statusText == 'Unknown Error') {
                                console.log('error', err);
                                this.notifier.showError('Errore', 'Si è verificato un errore imprevisto.');
                            } else if(err.status != 444) {
                                // 444 ha la gestione centralizzata in jwt interceptor, quindi non viene mostrato un altro messaggio di errore
                                console.log(err);
                                this.notifier.showError('Errore', 'Credenziali non valide.');
                            }
                            this.loading = false;
                        }
                    });
            });
        });
    }

    saveCompanyInfo(){
        this.api.select(null, 'companies', ['description', 'description_short', 'image_menu', 'image_header', 'image_home', 'image_favicon'], [], [], {})
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: data => {
                    const company: CompanyModel = {
                        id: data[0].id,
                        description: data[0].description,
                        description_short: data[0].description_short,
                        image_menu: data[0].image_menu,
                        image_header: data[0].image_header,
                        image_home: data[0].image_home,
                        image_favicon: data[0].image_favicon
                    }
                    this.storage.setCompanyInfo(company);
                    this.router.navigate(['/']);
                },
                error: err => {
                    console.log(err);
                    this.notifier.showWarning('Attenzione', 'Non è stato possibile recuperare le informazioni della cooperativa.');
                    this.router.navigate(['/']);
                }
            });
        }
}