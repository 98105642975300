import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CompanyCounter } from 'src/app/models/db.model';
import { Session } from 'src/app/models/session.model';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'app-stats',
    imports: [CommonModule, MatListModule],
    templateUrl: './admin-stats.component.html',
    styleUrl: './admin-stats.component.css'
})
export class AdminStatsComponent {
    private destroy$ = new Subject<void>();
    private subscriptions = new Subscription();
    companiesCounters: CompanyCounter[] = [];
    selectedCompany: CompanyCounter;
    settings: Session;
    maxCountUsers: number;

    constructor(
        private api: ApiService,
        private sessionService: SessionService
    ) {

    }

    ngOnInit() {
        this.getCompaniesCounter();

        this.subscriptions.add(
            this.sessionService.userSettings$.subscribe((settings: Session) => {
                this.settings = new Session(settings);
            })
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.subscriptions.unsubscribe();
    }

    private getCompaniesCounter() {
        const sourceName = 'companies_counters';
        const fields = [];
        const filters = [];
        const sort = [{ field: 'count_users', direction: 'desc' }];
        const options = {};
        this.api.select(typeof {}, sourceName, fields, filters, sort, options)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    this.companiesCounters = data;
                    this.maxCountUsers = Math.max(...this.companiesCounters.map(company => company.count_users));
                },
                error: (error) => {
                    console.error(error);
                }
            });
    }

    formatFileSize(size: number, locale: string = navigator.language) {
        if (size > 0) {
            const units = ['B', 'KB', 'MB', 'GB', 'TB'];
            let unitIndex = 0;
            while (size >= 1024 && unitIndex < units.length - 1) {
                size /= 1024;
                unitIndex++;
            }
            if (size >= 1000 && unitIndex < units.length - 1) {
                size = size / 1024;
                unitIndex++;
            }
            const formattedSize = new Intl.NumberFormat(locale, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(size);
            return `${formattedSize} ${units[unitIndex]}`;
        }
        return '0 B';
    }

}
