import { FieldsModel, FiltersModel, FormFieldModel } from "./entity.model";

export class reportOption {
    title: string;
    filename: string;
}

export class Report {
    model: any;
    sourceName: ('assistances_per_schools' | 'assistances_per_users');
    fields: FieldsModel[];
    filters?: FiltersModel[];
    options: reportOption;

    constructor(source: string) {
        // in caso di inizializzazione vuota, restituisco null
        if (source == '') { return null; }

        const activeReport: Report = reports[source];
        this.model = activeReport.model;
        this.sourceName = activeReport.sourceName;
        // inizializzo tutti i campi come visibili
        this.fields = activeReport.fields.map(f => { f.show = true; return f; });
        this.filters = activeReport.filters;
        this.options = activeReport.options;
    }

    getAllFieldsKV(): FieldsModel[] {
        let output: FieldsModel[] = [];
        this.fields
            .slice()
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .forEach(field => {
                // trasposizione chiave-valore della lista dei campi
                output[field.name] = field;
            });
        return output;
    }

    getFieldByName(fieldName: string): FieldsModel {
        return this.fields
            .slice()
            .filter(f => f.name == fieldName)[0];
    }

    getOptions(): reportOption {
        return this.options;
    }

    getFields(): FieldsModel[] {
        return this.fields
            .slice()
            .sort(function (a, b) { return a.tableSort - b.tableSort; });
    }

    getFieldsList(): string[] {
        return this.fields
            .slice()
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .map(field => {
                return field.name;
            });
    }

    getVisibleFields(): FieldsModel[] {
        return this.fields
            .slice()
            .filter(f => f.format != 'hidden')
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; });
    }

    getVisibleFieldsKV(): FieldsModel[] {
        let output: FieldsModel[] = [];
        this.fields
            .slice()
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .forEach(field => {
                // trasposizione chiave-valore della lista dei campi
                output[field.name] = field;
            });
        return output;
    }

    getVisibleFieldsList(): string[] {
        return this.fields
            .slice()
            .filter(field => field.format != 'hidden')
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .map(f => f.name);
    }

    getFormFields(): FieldsModel[] {
        return this.fields
            .slice()
            .filter(f => f.form != null)
            .sort(function (a, b) { return a.form.formSort - b.form.formSort; });
    }

    getFormSelectList(): string[] {
        return this.fields
            .slice()
            .filter(f => f.form?.type == 'select')
            .map(field => {
                return field.name;
            });
    }

    getFormSelectByFieldName(fieldName: string): FormFieldModel {
        return this.fields
            .slice()
            .filter(f => f.name == fieldName)
            .map(field => {
                return field.form;
            })[0];
    }
}

const schoolMonthReport = new Report('');
schoolMonthReport.model = null;
schoolMonthReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    { name: 'description_network', label: 'Commessa', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'description_school', label: 'Istituto', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'mechanical_code', label: 'Codice meccanografico', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'sep', label: 'Settembre', format: 'duration', tableSort: 4, crud: ['select'] },
    { name: 'oct', label: 'Ottobre', format: 'duration', tableSort: 5, crud: ['select'] },
    { name: 'nov', label: 'Novembre', format: 'duration', tableSort: 6, crud: ['select'] },
    { name: 'dec', label: 'Dicembre', format: 'duration', tableSort: 7, crud: ['select'] },
    { name: 'jan', label: 'Gennaio', format: 'duration', tableSort: 8, crud: ['select'] },
    { name: 'feb', label: 'Febbraio', format: 'duration', tableSort: 9, crud: ['select'] },
    { name: 'mar', label: 'Marzo', format: 'duration', tableSort: 10, crud: ['select'] },
    { name: 'apr', label: 'Aprile', format: 'duration', tableSort: 11, crud: ['select'] },
    { name: 'may', label: 'Maggio', format: 'duration', tableSort: 12, crud: ['select'] },
    { name: 'jun', label: 'Giugno', format: 'duration', tableSort: 13, crud: ['select'] },
    { name: 'jul', label: 'Luglio', format: 'duration', tableSort: 14, crud: ['select'] },
    { name: 'aug', label: 'Agosto', format: 'duration', tableSort: 15, crud: ['select'] },
    { name: 'total', label: 'Totale', format: 'duration', tableSort: 16, crud: ['select'] },
];
schoolMonthReport.filters = [];
schoolMonthReport.options = {
    title: 'Istituto mese',
    filename: 'Report_istituto_mese'
};

const userMonthReport = new Report('');
userMonthReport.model = null;
userMonthReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_school', label: 'Istituto', form: {
            type: 'select',
            name: 'id_school',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'schools',
                placeholder: 'Selezionare un istituto',
                id: 'id',
                label: 'description',
                filter: true,
                upSelectFilter: {
                    upField: 'id_network',
                    filterKey: 'id_network'
                },
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 3
        }, format: 'hidden', crud: []
    },
    { name: 'description_network', label: 'Commessa', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'description_school', label: 'Istituto', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'mechanical_code', label: 'Codice meccanografico', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'user', label: 'Operatore', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'student', label: 'Allievo', format: 'string', tableSort: 4, crud: ['select'] },
    { name: 'sep', label: 'Settembre', format: 'duration', tableSort: 5, crud: ['select'] },
    { name: 'oct', label: 'Ottobre', format: 'duration', tableSort: 6, crud: ['select'] },
    { name: 'nov', label: 'Novembre', format: 'duration', tableSort: 7, crud: ['select'] },
    { name: 'dec', label: 'Dicembre', format: 'duration', tableSort: 8, crud: ['select'] },
    { name: 'jan', label: 'Gennaio', format: 'duration', tableSort: 9, crud: ['select'] },
    { name: 'feb', label: 'Febbraio', format: 'duration', tableSort: 10, crud: ['select'] },
    { name: 'mar', label: 'Marzo', format: 'duration', tableSort: 11, crud: ['select'] },
    { name: 'apr', label: 'Aprile', format: 'duration', tableSort: 12, crud: ['select'] },
    { name: 'may', label: 'Maggio', format: 'duration', tableSort: 13, crud: ['select'] },
    { name: 'jun', label: 'Giugno', format: 'duration', tableSort: 14, crud: ['select'] },
    { name: 'jul', label: 'Luglio', format: 'duration', tableSort: 15, crud: ['select'] },
    { name: 'aug', label: 'Agosto', format: 'duration', tableSort: 16, crud: ['select'] },
    { name: 'total', label: 'Totale', format: 'duration', tableSort: 17, crud: ['select'] },
];
userMonthReport.filters = [];
userMonthReport.options = {
    title: 'Operatore mese',
    filename: 'Report_operatore_mese'
};

const schoolWeekReport = new Report('');
schoolWeekReport.model = null;
schoolWeekReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_school', label: 'Istituto', form: {
            type: 'select',
            name: 'id_school',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'schools',
                placeholder: 'Selezionare un istituto',
                id: 'id',
                label: 'description',
                filter: true,
                upSelectFilter: {
                    upField: 'id_network',
                    filterKey: 'id_network'
                },
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 3
        }, format: 'hidden', crud: []
    },
    { name: 'description_school', label: 'Istituto', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'mechanical_code', label: 'Codice meccanografico', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'week_monday', label: 'Settimana', format: 'hidden', crud: [], form:
        { type: 'week', name: 'week_monday', isRequired: true, formSort: 4 }
    },
    { name: 'week_duration', label: 'Totale h settimana', format: 'duration', tableSort: 3, crud: ['select'] },
    { name: 'prev_week_duration', label: 'Totale h fino alla settimana (inclusa)', format: 'duration', tableSort: 4, crud: ['select'] },
];
schoolWeekReport.filters = [];
schoolWeekReport.options = {
    title: 'Istituto settimana',
    filename: 'Report_istituto_settimana'
};

const userWeekReport = new Report('');
userWeekReport.model = null;
userWeekReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_school', label: 'Istituto', form: {
            type: 'select',
            name: 'id_school',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'schools',
                placeholder: 'Selezionare un istituto',
                id: 'id',
                label: 'description',
                filter: true,
                upSelectFilter: {
                    upField: 'id_network',
                    filterKey: 'id_network'
                },
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 3
        }, format: 'hidden', crud: []
    },
    { name: 'description_school', label: 'Istituto', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'mechanical_code', label: 'Codice meccanografico', format: 'string', tableSort: 2, crud: ['select'] },
    {
        name: 'id_user', label: 'Operatore', form: {
            type: 'select',
            name: 'id_user',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'users',
                placeholder: 'Selezionare un operatore',
                id: 'id',
                label: 'fullname',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company'
                },
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 4
        }, format: 'hidden', crud: []
    },
    { name: 'fullname_user', label: 'Operatore', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'week_monday', label: 'Settimana', format: 'hidden', crud: [], form:
        { type: 'week', name: 'week_monday', isRequired: true, formSort: 5 }
    },
    { name: 'week_duration', label: 'Totale h settimana', format: 'duration', tableSort: 4, crud: ['select'] },
    { name: 'prev_week_duration', label: 'Totale h fino alla settimana (inclusa)', format: 'duration', tableSort: 5, crud: ['select'] },
];
userWeekReport.filters = [];
userWeekReport.options = {
    title: 'Operatore settimana',
    filename: 'Report_operatore_settimana'
};

const contractWeekReport = new Report('');
contractWeekReport.model = null;
contractWeekReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    { name: 'week_monday', label: 'Settimana', format: 'hidden', crud: [], form:
        { type: 'week', name: 'week_monday', isRequired: true, formSort: 2 }
    },
    { name: 'description_contract', label: 'Contratto', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'week_duration', label: 'Totale h settimana', format: 'duration', tableSort: 2, crud: ['select'] },
    { name: 'prev_week_duration', label: 'Totale h fino alla settimana (inclusa)', format: 'duration', tableSort: 3, crud: ['select'] },
];
contractWeekReport.filters = [];
contractWeekReport.options = {
    title: 'Contratto settimana',
    filename: 'Report_contratto_settimana'
};

const substitutionWeekReport = new Report('');
substitutionWeekReport.model = null;
substitutionWeekReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies_related',
                placeholder: 'Selezionare una cooperativa',
                id: 'id_company_related',
                label: 'description_short_company_related',
                filters: [{ field: 'active_company_related', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    {
        name: 'id_network', label: 'Commessa', form: {
            type: 'select',
            name: 'id_network',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'companies_networks_related',
                placeholder: 'Selezionare una commessa',
                id: 'id_network',
                label: 'description_network',
                filter: true,
                upSelectFilter: {
                    upField: 'id_company',
                    filterKey: 'id_company_related'
                },
                filters: [{ field: 'active_network', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    { name: 'week_monday', label: 'Settimana', format: 'hidden', crud: [], form:
        { type: 'week', name: 'week_monday', isRequired: true, formSort: 2 }
    },
    { name: 'substitution', label: 'Sostituzione', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'week_duration', label: 'Totale h settimana', format: 'duration', tableSort: 2, crud: ['select'] },
    { name: 'prev_week_duration', label: 'Totale h fino alla settimana (inclusa)', format: 'duration', tableSort: 3, crud: ['select'] },
];
substitutionWeekReport.filters = [];
substitutionWeekReport.options = {
    title: 'Sostituzione settimana',
    filename: 'Report_sostituzione_settimana'
};

const userMissingsReport = new Report('');
userMissingsReport.model = null;
userMissingsReport.fields = [
    {
        name: 'id_company', label: 'Cooperativa', form: {
            type: 'select',
            name: 'id_company',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                distinct: true,
                sourceName: 'companies',
                placeholder: 'Selezionare una cooperativa',
                id: 'id',
                label: 'description_short',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo commesse attive
            },
            formSort: 1
        }, format: 'hidden', crud: []
    },
    { name: 'fullname_user', label: 'Operatore', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'week_monday', label: 'Settimana', format: 'hidden', crud: [], form:
        { type: 'week', name: 'week_monday', isRequired: true, formSort: 1 }
    },
    {
        name: 'id_contract', label: 'Contratto', form: {
            type: 'select',
            name: 'id_contract',
            isRequired: false,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'contracts',
                placeholder: 'Selezionare un contratto',
                id: 'id',
                label: 'description',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo contratti attive
            },
            formSort: 2
        }, format: 'hidden', crud: []
    },
    { name: 'description_contract', label: 'Contratto', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'mon', label: 'Lun', format: 'boolean', tableSort: 3, crud: ['select'] },
    { name: 'tue', label: 'Mar', format: 'boolean', tableSort: 4, crud: ['select'] },
    { name: 'wed', label: 'Mer', format: 'boolean', tableSort: 5, crud: ['select'] },
    { name: 'thu', label: 'Gio', format: 'boolean', tableSort: 6, crud: ['select'] },
    { name: 'fri', label: 'Ven', format: 'boolean', tableSort: 7, crud: ['select'] },
    { name: 'missing_days', label: 'Incompleti', format: 'number', tableSort: 8, crud: ['select'] },
];
userMissingsReport.filters = [
    { "field": "missing_days", "operator": ">", "value": "0" },
];
userMissingsReport.options = {
    title: 'Settimane incomplete',
    filename: 'Report_settimane_incomplete'
};

let reports: Report[] = [];
reports['school_month'] = schoolMonthReport;
reports['user_month'] = userMonthReport;
reports['school_week'] = schoolWeekReport;
reports['user_week'] = userWeekReport;
reports['contract_week'] = contractWeekReport;
reports['substitution_week'] = substitutionWeekReport;
reports['user_missing_weeks'] = userMissingsReport;