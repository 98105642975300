<div class="report-section">
    <form [formGroup]="filterForm">
        <mat-card class="filter-card">
            <mat-card-header class="filter-card-header">
                <mat-card-title>
                    <span>Report: {{options.title}}</span>
                </mat-card-title>
                <div>
                    <button type="button" *ngIf="showFilter" (click)="showFilter=false" mat-button>Nascondi filtri</button>
                    <button type="button" *ngIf="!showFilter" (click)="showFilter=true" mat-button>Mostra filtri</button>
                </div>
            </mat-card-header>
            <mat-card-content *ngIf="showFilter" class="filter-card-body">
                <ng-container *ngFor="let col of formFields">
                    <ng-container *ngIf="col.form.type=='select'">
                        <mat-form-field appearance="outline" class="fill-width">
                            <mat-label>{{col.label}}</mat-label>
                            <mat-select matInput formControlName="{{col.form.name}}"
                                (selectionChange)="selectChange(col.form.name, $event)">
                                <mat-form-field appearance="outline"
                                    *ngIf="dynamicData[col.form.name].form.select.filter"
                                    style="margin: 0px 8px; width: calc(100% - 16px);">
                                    <input matInput (keyup)="selectFilter(col.form.name, $event)"
                                        formControlName="{{'selectFilter_'+col.form.name}}" placeholder="Filtro">
                                </mat-form-field>
                                <!-- Placeholder mostrato solo se elenco vuoto o con più di un elemento -->
                                <mat-option *ngIf="filteredDynamicData[col.form.name]['data'].length!=1">--</mat-option>
                                <!-- Elenco valori, se presente un solo valore, viene selezionato di default -->
                                <mat-option *ngFor="let opt of filteredDynamicData[col.form.name]['data']"
                                    [value]="opt[dynamicData[col.form.name].form.select.id]"
                                    [attr.selected]="filteredDynamicData[col.form.name]['data'].length==1 ? 'selected' : null">
                                    {{opt[dynamicData[col.form.name].form.select.label]}}
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix *ngIf="f[col.form.name].status=='VALID'" class="valid-icon">check</mat-icon>
                            <mat-error *ngIf="f[col.form.name].errors?.required">
                                Il campo '{{col.label}}' è obbligatorio
                            </mat-error>
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="col.form.type=='week'">
                        <mat-form-field appearance="outline" class="fill-width">
                            <mat-label>{{col.label}}</mat-label>
                            <input matInput [matDatepickerFilter]="weekFilter" [matDatepicker]="picker"
                                formControlName="{{col.form.name}}">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-hint align="end">Formato dd/mm/yyyy</mat-hint>
                            <mat-error *ngIf="f[col.form.name].errors?.required">
                                Il campo '{{col.label}}' è obbligatorio
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                </ng-container>
                
                <div class="break"></div>

                <!-- Input di ricerca libera -->
                <mat-form-field subscriptSizing="dynamic" appearance="outline" class="flex-grow search-input" [ngClass]="{'d-none':!data.length || !showData}">
                    <mat-label>Ricerca libera</mat-label>
                    <input matInput type="text" #filterAll value="" (keyup)="updateFilters($event)">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions *ngIf="showFilter" class="filter-card-actions" align="end">
                <button [disabled]="loading || !formIsChanged" type="button" (click)="getReport('view')" color="secondary" mat-button>Mostra a video</button>
                <button [disabled]="loading || !formIsChanged" type="button" (click)="getReport('export')" color="primary" mat-button>Scarica</button>
            </mat-card-actions>
            <mat-card-footer>
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
    </form>

    <!-- Table card -->
    <div [ngClass]="{'d-none':!showData}" class="table-container">
        <!-- Table -->
        <mat-table [dataSource]="dataSource" matSort>

            <!-- Column Header cell -->
            <ng-container *ngFor="let col of visibleFields; let index = index" matColumnDef="{{col.name}}">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{col.label}}</mat-header-cell>
                <mat-cell *matCellDef="let cell" [ngClass]="{
                                    'numberTd'   : visibleFieldsKV[col.name]['format']=='number',
                                    'currencyTd' : visibleFieldsKV[col.name]['format']=='currency',
                                    'stringTd'   : visibleFieldsKV[col.name]['format']=='string',
                                    'datetimeTd' : visibleFieldsKV[col.name]['format']=='datetime',
                                    'dateTd'     : visibleFieldsKV[col.name]['format']=='date',
                                    'timeTd'     : visibleFieldsKV[col.name]['format']=='time',
                                    'durationTd' : visibleFieldsKV[col.name]['format']=='duration',
                                    'booleanTd'  : visibleFieldsKV[col.name]['format']=='boolean',
                                }">
                    <!--- <small class="d-inline-mobile" style="color: darkgrey">{{col.label}}:&nbsp;</small> --->
                    <ng-container [ngSwitch]="visibleFieldsKV[col.name]['format']">
                        <ng-container *ngSwitchCase="'number'">
                            {{cell[col.name]}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'currency'">
                            €&nbsp;{{cell[col.name]}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'string'">
                            {{cell[col.name]}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'datetime'">
                            {{cell[col.name] | date: 'dd/MM/yyyy HH:mm:ss'}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'date'">
                            {{cell[col.name] | date: 'dd/MM/yyyy'}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'time'">
                            {{cell[col.name]*1000 | date: 'HH:mm':'UTC'}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'duration'">
                            {{formatDuration(cell[col.name])}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'boolean'">
                            <mat-icon *ngIf="cell[col.name]==true || cell[col.name]==1" class="boolean_icon_true">check</mat-icon>
                            <mat-icon *ngIf="cell[col.name]==false || cell[col.name]==0" class="boolean_icon_false">minimize</mat-icon>
                        </ng-container>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Riga dei titoli, con ordinamento -->
            <mat-header-row mat-header-row *matHeaderRowDef="visibleFieldsList; sticky: true"></mat-header-row>
            <!-- Riga di contenuto -->
            <mat-row mat-row *matRowDef="let row; columns: visibleFieldsList" class="row_main"></mat-row>
        </mat-table>
    </div>

    <!-- Paginazione -->
    <mat-paginator [ngClass]="{'d-none':!showData}" [length]="data.length" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" aria-label="Paginazione" class="mat_paginator">
    </mat-paginator>
</div>  