import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class VersionCheckService {
    private currentVersion: string = "";
    private versionUrl = "/assets/version.json";

    constructor(private http: HttpClient) {
        this.loadVersion();
    }

    async loadVersion() {
        try {
            const data = await firstValueFrom(this.http.get<{ version: string }>(this.versionUrl));
            this.currentVersion = data.version;
            console.log("Versione attuale:", this.currentVersion);
        } catch (error) {
            console.error("Errore nel caricamento della versione:", error);
        }
    }

    startVersionCheck(intervalMs: number = 60000) {
        setInterval(async () => {
            try {
                const data = await firstValueFrom(this.http.get<{ version: string }>(this.versionUrl));
                if (data.version !== this.currentVersion) {
                    console.warn("Nuova versione disponibile!", data.version);
                    this.promptReload();
                }
            } catch (error) {
                console.error("Errore nel controllo della versione:", error);
            }
        }, intervalMs);
    }

    private promptReload() {
        if (confirm("È disponibile una nuova versione. Vuoi aggiornare?")) {
            window.location.reload();
        }
    }
}