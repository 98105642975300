import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FiltersModel } from '../models/entity.model';
import { environment } from '../../environment/environment';
import { Subject } from 'rxjs';

// url contenuta nel file environment
const API_URL = environment.API_URL;

const httpOptions = {
    headers: new HttpHeaders({
        'accept': 'application/json'
    }),
};

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private destroy$ = new Subject<void>();

    constructor(
        private http: HttpClient
    ) { }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    select(dataModel: any, table: string, fields: string[], filters: FiltersModel[], sort: any[], options: {}) {
        const data = {
            "table": table,
            "fields": fields,
            "filters": filters,
            "sort": sort,
            "options": options ? options : {}
        };

        return this.http.post<typeof dataModel>(API_URL + '/select/', data, httpOptions);
    }

    getDownloadUrl(id: number) {
        return this.http.post<string>(API_URL + '/documentale/download/', {id: id}, httpOptions);
    }

    insert(dataModel: any, table: string, fields: {}) {
        const data = {
            "table": table,
            "fields": fields
        };

        return this.http.post<typeof dataModel>(API_URL + '/insert/', data, httpOptions);
    }

    update(dataModel: any, table: string, fields: {}, filters: FiltersModel[]) {
        const data = {
            "table": table,
            "fields": fields,
            "filters": filters
        };

        return this.http.post<typeof dataModel>(API_URL + '/update/', data, httpOptions);
    }

    delete(dataModel: any, table: string, filters: FiltersModel[]) {
        const data = {
            "table": table,
            "filters": filters
        };

        return this.http.post<typeof dataModel>(API_URL + '/delete/', data, httpOptions);
    }

    upsert(dataModel: any, table: string, fields: {}, filters: FiltersModel[]) {
        const data = {
            "table": table,
            "fields": fields,
            "filters": filters
        };

        return this.http.post<typeof dataModel>(API_URL + '/upsert/', data, httpOptions);
    }
}
