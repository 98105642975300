import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinner, MatSpinner } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTable, MatHeaderCell, MatColumnDef, MatCell, MatHeaderRow, MatRow } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { EntityModel, FieldsModel, OptionModel } from 'src/app/models/entity.model';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.css',
  imports: [
    CommonModule,
    MatButton,
    MatCell,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    MatMenuTrigger,
    MatPaginator,
    MatRow,
    MatSortModule,
    // MatSpinner,
    MatProgressSpinner,
    MatTable,
    MatTableModule,
    RouterLink,
  ],
})
export class TableComponent {
  @Input() dataType: any;
  @Input() data: typeof this.dataType;
  @Input() entity: EntityModel;
  @Input() dataLoading: boolean;
  @Input() tableOptions: OptionModel;
  @Input() count: number;
  @Input() offset: number;
  @Output() offsetChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() limit: number;
  @Output() limitChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() sortActive: string;
  @Output() sortActiveChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() sortDirection: ('asc' | 'desc' | '');
  @Output() sortDirectionChange: EventEmitter<('asc' | 'desc' | '')> = new EventEmitter<('asc' | 'desc' | '')>();
  @Input() initSort: boolean;
  @Input() tableVisibleFields: FieldsModel[];
  @Input() tableVisibleFieldsKV: FieldsModel[];
  @Input() tableVisibleFieldsList: string[];
  @Input() fieldsGroups: string[];

  @Output() loadMain = new EventEmitter</*{
    count: number,
    offset: number,
    limit: number,
    sortActive: string,
    sortDirection: ('asc' | 'desc' | ''),
    initSort: boolean
  }*/void>();
  @Output() openDialog = new EventEmitter<{_type: ('insert' | 'update'), obj?: typeof this.table.model}>();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tableContainer') tableEl: ElementRef;
  @ViewChild('elementDetails') detailsEl: ElementRef;

  dataSource = new MatTableDataSource<typeof this.data>(null);
  expandedElement: {} | null = null;
  role: string = null;
  details_width: number = 0;

  constructor(
    private storage: StorageService,
    private matPaginatorIntl: MatPaginatorIntl,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    let hasSort = false;
    Object.keys(changes).forEach(propName => {
      if (changes[propName].currentValue) {
        if(propName === 'data') {
          this.dataSource = new MatTableDataSource<typeof this.dataType>(changes[propName].currentValue);
        } else {
          this[propName] = changes[propName].currentValue;
        }
      }
      if(this.sort && (propName === 'sortActive' || propName === 'sortDirection')) {
        hasSort = true;
      }
    });
    if(hasSort) {
        this.tableSortInputs();
    }
  }

  ngOnInit(): void {
    this.role = this.storage.getRole();
    this.dataSource = new MatTableDataSource<typeof this.data>(this.data);

    // traduzione dell'oggetto paginator
    this.matPaginatorIntl.itemsPerPageLabel = 'Elementi per pagina';
    this.matPaginatorIntl.nextPageLabel = 'Pagina successiva';
    this.matPaginatorIntl.previousPageLabel = 'Pagina precedente';
    this.matPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize === 0) {
        return 'Nessun elemento';
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${this.formatInteger(startIndex + 1)} – ${this.formatInteger(endIndex)} di ${this.formatInteger(length)}`;
    };
  }

  ngAfterViewInit() {
    // imposta la larghezza della sezione details della tabella, in base alla larghezza del contenitore della tabella, meno il padding della sezione stessa
    this.details_width = this.tableEl.nativeElement.offsetWidth - 48;
    
    // Create ResizeObserver to monitor table width changes
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        this.details_width = entry.contentRect.width - 48;
      }
    });
  
    // Start observing the table element
    resizeObserver.observe(this.tableEl.nativeElement);
  }

  handleLoadMain(/*count: number, offset: number, limit: number, sortActive: string, sortDirection: ('asc' | 'desc' | ''), initSort: boolean*/) {
    this.loadMain.emit(/*{count, offset, limit, sortActive, sortDirection, initSort}*/);
  }

  handleOpenDialog(_type: ('insert' | 'update'), obj?: typeof this.dataType): void {
    this.openDialog.emit({ _type, obj });
  }
  
  tableSortHeaders(e) {
    // invocato quando l'utente ordina utilizzando l'header della tabella
    this.sortActive = e['active'];
    this.sortActiveChange.emit(this.sortActive);
    this.sortDirection = e['direction'];
    this.sortDirectionChange.emit(this.sortDirection);
    // non aggiorna i dati se si tratta del primo caricamento dei dati
    if (!this.initSort) {
      // aggiorna i dati
      this.handleLoadMain(/*this.count, this.offset, this.limit, this.sortActive, this.sortDirection, this.initSort*/);
    }
    this.initSort = false;
  }

  tableSortInputs() {
    // invocato alla creazione della pagina e quando l'utente ordina utilizzando il menu laterale
    const sortState: Sort = { active: this.sortActive, direction: this.sortDirection };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  paginatorChange(e) {
    this.offset = e.pageIndex * e.pageSize;
    this.offsetChange.emit(this.offset);
    this.limit = e.pageSize;
    this.limitChange.emit(this.limit);
    this.handleLoadMain(/*this.count, this.offset, this.limit, this.sortActive, this.sortDirection, this.initSort*/);
  }

  getDateFormat(): string {
    return window.innerWidth <= 480 ? 'dd/MM/yy' : 'dd/MM/yyyy';
  }

  handleActionsClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  getDataById(id: number) {
    // restituisce il record corrispondende all'id in input
    return this.data.find(value => value['id'] == id);
  }

  paginatorFirstPage() {
    this.paginator.firstPage();
  }

  formatInteger(num: number): string {
    return new Intl.NumberFormat('it-IT', {
      useGrouping: true,
      maximumFractionDigits: 0,
    }).format(num);
  }

  getGroupFields(groupName: string): FieldsModel[] {
    return this.entity.getGroupFields(groupName);
  }
}
