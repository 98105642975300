<h1 mat-dialog-title>
    <div class="title-container">
        Geofence
        <div class="search-container">
            <mat-form-field class="full-width" appearance="outline" subscriptSizing="dynamic">
                <mat-label>Cerca luogo</mat-label>
                <input matInput placeholder="Città, Indirizzo, CAP, ecc." #searchInput (keyup.enter)="searchLocation(searchInput.value)">
                <button mat-icon-button matSuffix (click)="searchLocation(searchInput.value)">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
</h1>
<mat-dialog-content class="mat-typography">
    <div class="map-container">
        <div id="map"></div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" mat-button (click)="cancel()">Annulla</button>
    <button type="button" mat-button (click)="onSubmit()" type="submit" class="button-with-icon"
        [disabled]="!currentLayer">Salva</button>
</mat-dialog-actions>