import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { EntityModel, LinkedModel, FieldsModel, FiltersModel, OptionModel } from 'src/app/models/entity.model';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { StorageService } from 'src/app/services/storage.service';
import { FormModalComponent } from 'src/app/shared/form-modal/form-modal.component';
import { MatButtonModule } from '@angular/material/button';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { SessionService } from 'src/app/services/session.service';
import { Session } from 'src/app/models/session.model';

@Component({
    selector: 'app-entity-detail',
    templateUrl: './entity-detail.component.html',
    styleUrls: ['./entity-detail.component.css'],
    standalone: false
})
export class EntityDetailComponent {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private api: ApiService,
        private storage: StorageService,
        private notifier: NotifierService,
        public dialog: MatDialog,
        private sessionService: SessionService,
    ) { }

    private destroy$ = new Subject<void>();
    private subscriptions = new Subscription();
    routeData = this.route.data['_value'];
    id: number;
    sourceName: string;
    page: EntityModel;
    fields_: FieldsModel;
    fieldsList: string[];
    visibleFields: FieldsModel[];
    visibleFieldsKV: FieldsModel[] = [];
    visibleFieldsList: string[] = [];
    fieldsGroups: string[];
    options: OptionModel;
    linked: LinkedModel[];
    filters: FiltersModel[];
    data: any = [];
    formSelectList: string[];
    dynamicLinked = [];
    dynamicAddLinked = [];
    isEditMode: boolean;
    dynamicData = [];
    formMode: ('insert' | 'update') = null;
    allFieldsList: string[];
    linkedAddShow: boolean[] = [];
    linkedAddEntries = [];
    linkedAddForm = [];
    filter_input: [] = [];
    activeLinked: LinkedModel;
    mobile: boolean = false;
    admin: boolean = false;
    principal: boolean = false;
    settings: Session;
    selectedFile: File | null = null;
    editingLinkedId: number = null;
    noBackButton: boolean = false;

    ngOnInit() {
        this.subscriptions.add(
            this.sessionService.userSettings$.subscribe((settings: Session) => {
                this.settings = new Session(settings);
                this.principal = settings.user.principal;
                this.admin = settings.user.admin;
            })
        );
        this.route.params
            .pipe(takeUntil(this.destroy$))
            .subscribe(params => {
                this.id = +params['id'];
                // Nel caso in cui il parametro della url è non numerico intero
                if (typeof this.id !== "number" || isNaN(this.id) || this.id <= 0 || Math.trunc(this.id) != this.id) {
                    // Reindirizzamento alla pagina 404
                    this.router.navigate(['/404']);
                }
                // parametro source ricevuto in input da app-routing.module.ts
                this.sourceName = this.routeData['source'];
                this.noBackButton = this.routeData['noBackButton'];
                this.page = new EntityModel(this.sourceName);
                // recupera la lista dei campi (tutti)
                this.fieldsList = this.page.getFieldsList();
                this.visibleFields = this.page.getVisibleFields();
                this.visibleFieldsKV = this.page.getVisibleFieldsKV();
                this.visibleFieldsList = this.page.getVisibleFieldsList();
                this.allFieldsList = this.page.getAllFieldsList();
                this.fieldsGroups = this.page.getFieldsGroups();
                this.options = this.page.getOptions();
                this.linked = this.page.getLinked(this.settings);
                this.formSelectList = this.page.getFormSelectList();
                // recupera eventuale parametro "mobile" settato solo da app mobile
                this.mobile = this.storage.getMobile() == 'true' ? true : false;
                // applica il filtro per recuperare solo l'oggetto della pagina
                this.filters = [{ field: "id", operator: "=", value: this.id }];
                // carica i dati
                this.loadData();
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.subscriptions.unsubscribe(); // Annulla tutte le sottoscrizioni
    }

    loadData() {
        // scarica i dati dell'entità dall'api
        this.api.select(typeof this.page.model, this.sourceName, this.allFieldsList, this.filters, [], {})
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                data => {
                    this.data = data[0];
                    if (this.data) {
                        this.loadSelect();
                        this.loadLinked();
                    } else {
                        this.noData();
                    }
                }
            );
    }

    loadSelect() {
        // Recupera la lista dei campi che hanno una form select
        this.formSelectList.forEach(fieldName => {
            this.dynamicData[fieldName] = {
                "form": this.page.getFormSelectByFieldName(fieldName),
                "data": []
            };
        });

        // scarica eventuali dati per le form select
        this.formSelectList.forEach(fieldName => {
            let options: {} = {};
            if (this.dynamicData[fieldName].form.select.distinct) {
                options = {
                    'distinct': true
                };
            }

            let selectList = [];
            // id della select
            selectList.push(this.dynamicData[fieldName].form.select.id);
            // label della select
            selectList.push(this.dynamicData[fieldName].form.select.label);
            // eventuale chiave per un filtro dipendente da altra select
            if (this.dynamicData[fieldName].form.select.upSelectFilter) {
                selectList.push(this.dynamicData[fieldName].form.select.upSelectFilter.filterKey);
            }

            this.api.select(
                typeof {},
                this.dynamicData[fieldName].form.select.sourceName,
                selectList,
                this.dynamicData[fieldName].form.select.filters,
                [],
                options)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    data => {
                        this.dynamicData[fieldName]['data'] = data;
                    }
                );
        });
    }

    loadLinked() {
        // scarica eventuali dati per le entità collegate
        this.linked?.forEach(obj => {
            const fields: string[] = obj.fields
                // nasconde il campo admin se l'entità ha il filtro admin attivo e l'utente non è admin
                .filter(field => !obj.adminFilter || this.admin || !field.adminOnly)
                .map(field => { return field.name; });
            const firstVisibleField: string = obj.fields.filter(field => field.format != 'hidden').map(field => { return field.name; })[0];
            // recupera i filtri dal modello
            const filters: FiltersModel[] = obj.filters.slice();
            // aggiunge il filtro dell'entità aperta
            filters.push(
                { field: obj.idJoinLinked, operator: "=", value: this.data[obj.idJoinMain], }
            );
            // recupera gli oggetti associati
            this.api.select(typeof {}, obj.sourceName, fields, filters, [{ field: firstVisibleField, direction: 'asc' }], {})
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    data => {
                        this.dynamicLinked[obj.sourceName] = data;
                        // prepara la lista dei linked già associati
                    }
                );
            // in caso di relazione "n a m"
            if (obj.select) {
                // recupera tutti i possibili valori dell'entità collegata per aggiungerle
                let selectFields = [obj.select.id, obj.select.label];
                this.api.select(typeof {},
                    obj.select.sourceName,
                    selectFields,
                    obj.select.filters, [{ field: obj.select.label, direction: 'asc' }], {})
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        data => {
                            this.dynamicData[obj.select.sourceName] = {
                                "form": null,
                                "data": data
                            };
                        }
                    );
            }
            // in caso di relazioni "1 a n"
            if (obj.addFields) {
                obj.addFields.forEach(f => {
                    if (f.form?.select) {
                        // recupera tutti i possibili valori dell'entità collegata per aggiungerle
                        this.api.select(typeof {},
                            f.form.select.sourceName,
                            [f.form.select.id, f.form.select.label],
                            f.form.select.filters, [{ field: f.form.select.label, direction: 'asc' }], {})
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(
                                data => {
                                    this.dynamicData[f.form.name] = {
                                        "form": f.form,
                                        "data": data
                                    };
                                    // this.filteredDynamicData[obj.select.sourceName]['data'] = data;
                                }
                            );
                    }
                });
            }
        });
    }

    handleActionsClick(event: Event) {
        event.stopPropagation();
        event.preventDefault();
    }

    getGroupFields(groupName: string) {
        return this.page.getGroupFields(groupName);
    }

    getPropertyList(arr: any[], property: string) {
        return arr.map(f => { return f[property]; });
    }

    getSortedActive(link: LinkedModel) {
        const arr: FieldsModel[] = link.fields;
        return arr
            // esclude i campi nascosti
            .filter(f => { return f.format != 'hidden' })
            // esclude i campi admin se l'utente non è admin
            .filter(f => { return !link.adminFilter || !f.adminOnly || this.admin })
            .sort((a, b) => a.tableSort.toString().localeCompare(b.tableSort.toString(), 'it'));
    }

    getPropertyKV(arr: any[], property: string) {
        let output: FieldsModel[] = [];
        arr.forEach(field => {
            output[field[property]] = field;
        });
        return output;
    }

    isLinkedUsed(linkedObj: LinkedModel, id: number) {
        const relatedIdName = linkedObj.routerId;
        const sourceName = linkedObj.sourceName;
        const searchWhere = this.dynamicLinked[sourceName].map(f => f[relatedIdName]);
        return searchWhere.indexOf(id) > -1;
    }

    toggleLinkedForm(linkedObj: LinkedModel) {
        this.editingLinkedId = null;
        this.linkedAddShow[linkedObj.sourceName] = !this.linkedAddShow[linkedObj.sourceName];
        this.linkedAddForm = [];
    }

    linkedEditable(linkedObj: LinkedModel, data: any) {
        // linkedObj ha almeno un campo con crud che contine update
        const updateFileds = linkedObj.addFields?.filter(field => {
            if (field.crud.indexOf('update') > -1) {
                this.linkedAddForm[field.form.name] = data[field.form.name];
                return true;
            }
            return false;
        });
        return updateFileds && updateFileds.length > 0;
    }

    editLinked(linkedObj: LinkedModel, id: number) {
        this.editingLinkedId = id;
        this.linkedAddShow[linkedObj?.sourceName] = true;
    }

    addLinked(linked: LinkedModel) {
        const sourceName = linked.sourceName;
        const entityField = linked.idJoinLinked;
        const entityValue = this.data[linked.idJoinMain];
        const relatedField = linked.routerId;
        const relatedSourceName = linked.select?.sourceName;
        const relatedValues = this.linkedAddEntries[relatedSourceName];

        relatedValues.forEach(relatedValue => {
            var fields = {};
            fields[entityField] = entityValue;
            fields[relatedField] = relatedValue;
            fields = <FieldsModel>fields;

            this.api.insert(null, sourceName, fields)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (response) => {
                        this.loadData();
                        this.linkedAddShow[relatedSourceName] = false;
                        // svuota la lista dei selezionati - pulsante "Aggiungi"
                        this.linkedAddEntries[relatedSourceName] = null;
                        this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
                    },
                    error: (err) => {
                        this.linkedAddShow[relatedSourceName] = false;
                        if (err.status != 444) {
                            // 444 ha la gestione centralizzata in jwt interceptor, quindi non viene mostrato un altro messaggio di errore
                            console.error(err);
                            this.notifier.showError('Errore', 'Si è verificato un errore. Riprovare o contattare gli amministratori se il problema persiste.');
                        }
                    }
                });
        });
    }

    deleteLink(sourceName: string, id: number) {
        if (!id) {
            this.notifier.showError('Errore', 'Configurazione dell\'entità non corretta. Segnalare l\'errore a un amministratore');
            return;
        }
        if (confirm('Procedere con l\'eliminazione?')) {
            // const fields = {
            //   'active': 0
            // };
            const filters: FiltersModel[] = [{
                field: 'id',
                operator: '=',
                value: id
            }];
            this.api.delete(null, sourceName, /*fields,*/ filters)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (response) => {
                        this.loadData();
                        this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
                    },
                    error: (err) => {
                        if (err.status != 444) {
                            // 444 ha la gestione centralizzata in jwt interceptor, quindi non viene mostrato un altro messaggio di errore
                            console.error(err);
                            this.notifier.showError('Errore', 'Si è verificato un errore. Riprovare o contattare gli amministratori se il problema persiste.');
                        }
                    }
                });
        }
    }

    isUplaodForm(linked) {
        return linked.addFields?.some(f => f.form?.type === 'file') ?? false;
    }

    addLinkedForm(linked: LinkedModel, editingLinkedId: number) {
        var error = false;
        var fields = {};
        var sourceName = linked.sourceName;
        var relatedSourceName = linked.sourceName;

        linked.addFields?.filter(f => {
            return editingLinkedId ? f.crud.indexOf('update') > -1 : f.crud.indexOf('insert') > -1;
        }).forEach(f => {
            const fieldValue = this.linkedAddForm[f.form?.name]?.toString() || '';

            // Check obbligatorietà
            if (f.form?.isRequired && !fieldValue.length) {
                error = true;
            }

            // Check lunghezza massima
            if (f.form?.maxLength && fieldValue.length > f.form?.maxLength) {
                error = true;
            }

            if (error) return;

            // Assegna il valore solo se il nome è definito
            if (f.form?.name) {
                // fields[f.form.name] = fieldValue;
                if (f.form.type == 'date') {
                    // solo in caso di data formatto il valore
                    fields[f.form.name] = fieldValue ? moment(fieldValue).format('YYYY-MM-DD') : null;
                } else if (f.form.type == 'boolean') {
                    let value: (0 | 1) = null;
                    if (fieldValue == true || fieldValue == "true") value = 1;
                    else if (fieldValue == false || fieldValue == "false") value = 0;
                    else error = true;
                    // il valore del campo è castato a 0/1 nel caso in cui erano true/false
                    fields[f.form.name] = value != null ? value : fieldValue;
                } else {
                    fields[f.form.name] = fieldValue;
                }
            }
        });
        if (error) {
            this.notifier.showWarning('Attenzione', 'La form non è compilata correttamente');
            return false;
        }

        let apiMethod = null;

        if (!editingLinkedId) {
            // aggiunge di default il campo di join con l'entità principale
            fields[linked.idJoinLinked] = this.data[linked.idJoinMain];
            // definisce formato fields
            fields = <FieldsModel>fields;
            // imposta come api method l'inserimento
            apiMethod = this.api.insert(null, sourceName, fields)
        } else {
            // definisce formato fields
            fields = <FieldsModel>fields;
            // aggiunge ai filtri l'id del linked modificato
            const filters: FiltersModel[] = [{
                field: linked.editId,
                operator: '=',
                value: editingLinkedId
            }];
            // imposta come api method l'aggiornamento
            apiMethod = this.api.update(null, sourceName, fields, filters)
        }

        if (apiMethod)
            apiMethod
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (response) => {
                        this.loadData();
                        this.linkedAddShow[relatedSourceName] = false;
                        this.linkedAddForm = [];
                        this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
                        return true;
                    },
                    error: (err) => {
                        if (err.status != 444) {
                            // 444 ha la gestione centralizzata in jwt interceptor, quindi non viene mostrato un altro messaggio di errore
                            console.error(err);
                            this.notifier.showError('Errore', 'Si è verificato un errore. Riprovare o contattare gli amministratori se il problema persiste.');
                        }
                        return false;
                    }
                });

        return false;
    }

    openDialog(_type: ('insert' | 'update'), obj?: typeof this.page.model): void {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: { model: this.page, formMode: _type, selObj: obj },
            panelClass: 'full-page-dialog'
        });

        // metodo invocato quando il dialog viene chiuso
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                if (result?.result == 'ok')
                    this.loadData();
            });
    }

    noData() {
        this.notifier.showError('Errore', 'La pagina richiesta non è disponibile');
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    isLinkedVisible(link: LinkedModel): boolean {
        return link.showCondition == undefined || this.data[link.showCondition];
    }

    filterSelect(data: any, label: string, filter: string) {
        if (filter) {
            return data.filter((item: any) => item[label].toLowerCase().includes(filter.toLowerCase()));
        }
        return data;
    }

    toggleActive(setActive: boolean): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: setActive ? 'Attivazione' : 'Disattivazione',
                message: setActive ?
                    'Procedere con l\'attivazione dell\'elemento?' :
                    'Procedere con la disattivazione dell\'elemento?'
            },
        });

        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                if (result === true) {
                    const fields = {
                        'active': setActive ? 1 : 0
                    };
                    const filters: FiltersModel[] = [{
                        field: 'id',
                        operator: '=',
                        value: this.id
                    }];

                    this.api.update(null, this.sourceName, fields, filters)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe({
                            next: (response) => {
                                this.loadData();
                                this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
                            },
                            error: (err) => {
                                if (err.status != 444) {
                                    console.error(err);
                                    this.notifier.showError('Errore', 'Si è verificato un errore. Riprovare o contattare gli amministratori se il problema persiste');
                                }
                            }
                        });
                }
            });
    }

    allSelected(data: any, linkSourcename: string, valueField: string) {
        // lista ordinata di tutti gli id
        const allIdList = data.map((item: any) => item[valueField]).sort((a, b) => a - b);
        // lista degli id selezionati
        const selectedIdList = this.linkedAddEntries[linkSourcename] || [];
        // true se la lista degli id selezionati è uguale a quella in selectedIDList
        return allIdList.every((id, index) => id == selectedIdList[index]);
    }


    selectAll(data: any, linkSourcename: string, valueField: string) {
        // lista ordinata di tutti gli id
        const allIdList = data.map((item: any) => item[valueField]).sort((a, b) => a - b);
        // lista degli id selezionati
        const selectedIdList = this.linkedAddEntries[linkSourcename] || [];
        // true se la lista degli id selezionati è uguale a quella in selectedIDList
        const allSelected = allIdList.every((id, index) => id == selectedIdList[index]);
        if (allSelected) {
            this.linkedAddEntries[linkSourcename] = [];
        } else {
            this.linkedAddEntries[linkSourcename] = allIdList;
        }
    }

    onTabChange(event: any) {
        this.activeLinked = this.linked.filter(f => this.isLinkedVisible(f))[event.index - 1];
        this.linkedAddShow = [];
        this.linkedAddForm = [];
    }

    getExportableData(data: any, linked: LinkedModel) {
        // Elenco dei campi visibili (oggetto con tutte le proprietà del campo)
        const visibleFields = this.getSortedActive(linked);
        let exportableData = data.map(row => {
            let exportableRow = {};
            visibleFields.forEach(f => {
                let exportableField: any;
                switch (f.format) {
                    case 'date':
                        exportableField = row[f.name] ? moment(row[f.name]).format('DD/MM/YYYY') : '';
                        break;
                    case 'datetime':
                        exportableField = row[f.name] ? moment(row[f.name]).format('DD/MM/YYYY HH:mm:ss') : '';
                        break;
                    case 'time':
                        exportableField = row[f.name] ? moment(row[f.name] * 1000).utc().format('HH:mm') : '';
                        break;
                    case 'boolean':
                        exportableField = row[f.name] == 1 ? 'Si' : 'No';
                        break;
                    default:
                        exportableField = row[f.name];
                        break;
                }
                // Utilizza come nome della colonna quello della header della tabella
                exportableRow[f.label] = exportableField;
            });
            return exportableRow;
        });
        return exportableData;
    }

    export(format: ('xlsx'), linked: LinkedModel, data: any) {
        // Recupera l'oggetto JSON esportabile
        const outputData = this.getExportableData(data, linked);

        switch (format) {
            case 'xlsx':
                // Restituisce la lunghezza per ciascuna colonna
                const fitToColumn = data => {
                    const columnWidths = [];
                    // Larghezza massima della colonna
                    const maxWidth = 30;
                    for (const property in data[0]) {
                        // Larghezza reale della colonna (potenzialmente maggiore della maxWidth)
                        const realWidth = Math.max(
                            property ? property.toString().length : 0, ...data.map(
                                obj => obj[property] ? obj[property].toString().length : 0)
                        );
                        columnWidths.push({
                            // utilizza la realWidth se minore della maxWidth
                            wch: Math.min(maxWidth, realWidth)
                        });
                    }
                    return columnWidths;
                };
                // Crea WorkBook e WorkSheet
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(outputData);
                ws['!cols'] = fitToColumn(outputData);
                // Crea il file
                const sheetName = this.page.options.title || 'export';
                const fileName = sheetName + '_' + moment(new Date()).format('YYYYMMDDHHmmss') + '.xlsx';
                XLSX.utils.book_append_sheet(wb, ws, sheetName);
                // Esporta il file all'utente
                XLSX.writeFile(wb, fileName);
                break;

            default:
                alert('Non disponibile');
                break;
        }
    }

    fileUploaded(uploadedFile: any) {
        this.selectedFile = uploadedFile;
        const _path = this.settings.company.id + '/' + this.page.sourceName + '/' + this.id + '/' + uploadedFile.name;

        if (uploadedFile) {
            const reader = new FileReader();
            reader.onload = () => this.linkedAddForm['file'] = reader.result as string;
            reader.readAsDataURL(uploadedFile);
        } else {
            this.linkedAddForm['file'] = null;
        }

        this.linkedAddForm['file_path'] = uploadedFile ? _path : null;
        this.linkedAddForm['original_name'] = uploadedFile?.name || null;
        this.linkedAddForm['file_size'] = uploadedFile?.size || null;
        this.linkedAddForm['mime_type'] = uploadedFile?.type || null;
        this.linkedAddForm['uploaded_by'] = uploadedFile ? Number(this.settings.user.id) : null;
        this.linkedAddForm['reference_table'] = uploadedFile ? this.page.sourceName : null;
        this.linkedAddForm['reference_id'] = uploadedFile ? this.id : null;
    }

    downloadFile(id: number) {
        this.api.getDownloadUrl(id)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (url: string) => {
                    const a = document.createElement('a');
                    a.href = url;
                    // l'app mobile va in errore se si imposta il target blank
                    // ma è necessario per il download dei file da browser
                    if (!this.mobile)
                        a.target = '_blank';
                    a.download = '';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                },
                error: (error: any) => {
                    console.error(error);
                }
            });
    }

    formatFileSize(size: number, locale: string = navigator.language) {
        if (size > 0) {
            const units = ['B', 'KB', 'MB', 'GB', 'TB'];
            let unitIndex = 0;
            while (size >= 1024 && unitIndex < units.length - 1) {
                size /= 1024;
                unitIndex++;
            }
            if (size >= 1000 && unitIndex < units.length - 1) {
                size = size / 1024;
                unitIndex++;
            }
            const formattedSize = new Intl.NumberFormat(locale, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(size);
            return `${formattedSize} ${units[unitIndex]}`;
        }
        return '0 B';
    }
}

@Component({
    selector: 'confirmation-dialog',
    template: `
    <h2 mat-dialog-title>{{data.title}}</h2>
    <mat-dialog-content>{{data.message}}</mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Annulla</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Conferma</button>
    </mat-dialog-actions>
  `,
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatButtonModule
    ]
})
export class ConfirmationDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, message: string }) { }
}