import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.services';
import { TermsAndConditionsDetailsComponent } from './terms-and-conditions-details.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.css'],
    standalone: true,
    imports: [
        MatButtonModule,
        TermsAndConditionsDetailsComponent
    ]
})
export class TermsAndConditionsComponent {


    constructor(
        private router: Router,
        private auth: AuthService
    ) { }

    accept() {
        return this.auth.acceptTermsAndConditions().subscribe({
            next: (data) => {
                window.location.href = '/';
            },
            error: error => {
            }
        });
    }
}
