import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FiltersModel } from '../models/entity.model';
import { environment } from '../../environment/environment';

// server test
// const API_URL = 'HTTPS://gass.3cengineering.it:8000/api';
// server prod
//const API_URL = 'https://myitinera.coopitinera.it:8000/api';

// local con proxy
// const API_URL = '/api';

// url contenuta nel file environment
const API_URL = environment.API_URL;

const httpOptions = {
  headers: new HttpHeaders({
    'accept': 'application/json'
  }),
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { }

  select(dataModel: any, table: string, fields: string[], filters: FiltersModel[], sort: any[], options: {}) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters,
      "sort": sort,
      "options": options ? options : {}
    };

    return this.http.post<typeof dataModel>(API_URL + '/select/', data, httpOptions);
  }

  insert(dataModel: any, table: string, fields: {}) {
    const data = {
      "table": table,
      "fields": fields
    };

    return this.http.post<typeof dataModel>(API_URL + '/insert/', data, httpOptions);
  }

  update(dataModel: any, table: string, fields: {}, filters: FiltersModel[]) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters
    };

    return this.http.post<typeof dataModel>(API_URL + '/update/', data, httpOptions);
  }

  delete(dataModel: any, table: string, filters: FiltersModel[]) {
    const data = {
      "table": table,
      "filters": filters
    };

    return this.http.post<typeof dataModel>(API_URL + '/delete/', data, httpOptions);
  }

  upsert(dataModel: any, table: string, fields: {}, filters: FiltersModel[]) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters
    };

    return this.http.post<typeof dataModel>(API_URL + '/upsert/', data, httpOptions);
  }
}
