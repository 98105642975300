<div class="main_div_terms">
    <img class="img_terms" src="/assets/img/cooperativeincloud/logo.png">
    <div>
<p><b>TERMINI E CONDIZIONI GENERALI DI FORNITURA E DI UTILIZZO DEI SERVIZI OFFERTI TRAMITE L’APPLICAZIONE “Cooperative in Cloud”</b></p>

<p><b>1. INTRODUZIONE</b></p>

<p><b>1.1.</b> I presenti Termini e Condizioni (di seguito anche solo “Condizioni”) sono stipulati tra 3CEngineering S.r.l.s., con sede legale in Roma, Via Riccardo Pitteri n. 32, P.IVA 14556671007 (di seguito “la Società”) e l’Utente (come di seguito definito) ed entrano in vigore al momento dell’accettazione delle stesse da parte dell’Utente. Oggetto delle presenti Condizioni è la licenza d’uso dell’Applicazione denominata “Cooperative in Cloud” (come di seguito definita), secondo i termini e le condizioni di seguito descritti.</p>

<p><b>1.2.</b> Installando l’Applicazione o utilizzando i servizi alla stessa collegati, l’Utente dichiara di aver letto e compreso i termini di utilizzo delle presenti Condizioni e di accettare integralmente ciascuna delle presenti disposizioni.</p>

<p><b>1.3.</b> L’accettazione integrale delle Condizioni è requisito necessario per l’utilizzo dell’Applicazione secondo i termini previsti dalle presenti Condizioni. Se l’Utente non intende accettare le presenti Condizioni è invitato a non cliccare sul pulsante di registrazione all’Applicazione e a non accedere, visionare, inserire dati, scaricare o in altro modo utilizzare alcuna pagina, contenuto o servizio offerto dall’Applicazione.</p>

<p><b>1.4.</b> L’applicazione si propone di semplificare la relazione tra lavoratore e azienda e offrire una migliore employee experience.</p>

<p><b>2. DEFINIZIONI</b></p>

<p><b>2.1.</b> “Aggiornamenti” indica ogni aggiornamento, miglioramento, supplemento che può essere fornito dalla Società in relazione all’Applicazione;</p>

<p><b>2.2.</b> “Apple App Store” indica il servizio realizzato da Apple, Inc., che permette agli utenti di scaricare e acquistare applicazioni disponibili sulla piattaforma di distribuzione iTunes Store, sviluppata e gestita da Apple, Inc. sotto la propria esclusiva responsabilità;</p>

<p><b>2.3.</b> “Google Play” indica la piattaforma di distribuzione di applicazioni e contenuti digitali sviluppata e gestita da Google, Inc. sotto la propria esclusiva responsabilità;</p>

<p><b>2.4.</b> “Applicazione” indica l’applicazione denominata “Cooperative in Cloud”, disponibile sulla piattaforma Google Play per smartphone aventi sistema operativo Android versione 8.0 e successive e sulla piattaforma Apple App Store per tutti i modelli di iPhone con sistema operativo iOS versioni 12 e successive, che permette agli Utenti di accedere all’applicazione medesima e di interagire attivamente con La Società;</p>

<p><b>2.5.</b> “Codice del Consumo” indica il Decreto Legislativo 6 settembre 2005, n. 206, e successive modifiche ed integrazioni;</p>

<p><b>2.6.</b> “Condizioni” indica le presenti condizioni di utilizzo e ogni altro documento ivi richiamato o alle stesse allegato;</p>

<p><b>2.7.</b> “Contenuti” indica informazioni, dati o contenuti, di interesse di La Società, contenuti nell’Applicazione, compresi a titolo esemplificativo loghi, marchi, icone, grafici, mappe, testi, secondo quanto specificato nel menu dell’Applicazione nonché tutti i dati che verranno inseriti nell’Applicazione dall’Utente;</p>

<p><b>2.8.</b> “Parti” indica la Società e l’Utente;</p>

<p><b>2.9.</b> “Società” indica la Società 3CEngineering Srls, con sede legale in Roma, Via Riccardo Pitteri n. 32 P.IVA 14556671007;</p>

<p><b>2.10.</b> “Utente” indica la persona fisica che installa l’Applicazione sul proprio Smartphone e che accetta le presenti Condizioni. L’Utente può agire unicamente per scopi legati all’attività professionale svolta per conto e/o alle dipendenze e/o in collaborazione con la propria Azienda.</p>

<p>I termini al singolare comprendono il plurale e viceversa, a seconda di quanto permesso o richiesto dal contesto.</p>

<p><b>2.11.</b> “GDPR” indica in Regolamento Europeo n.679/2016 sulla Protezione ed il Trattamento dei dati personali.</p>

<p><b>3. FUNZIONAMENTO DELL’APPLICAZIONE</b></p>

<p><b>3.1. Requisiti per il funzionamento di Cooperative in Cloud.</b></p>

<p><b>3.1.1.</b> Il funzionamento di Cooperative in Cloud può avvenire alternativamente:</p>

<p>a) mediante accesso browser collegandosi con il proprio pc o mac al seguente indirizzo web <a href="https://www.cooperativeincloud.it">https://www.cooperativeincloud.it</a>;</p>

<p>b) mediante installazione dell’App sul proprio smartphone/tablet.</p>

<p>L’utilizzo mediante App richiede congiuntamente:</p>

<ul>
  <li>il possesso di uno smartphone o di un pc o di un mac dotato di connessione internet;</li>
  <li>il download gratuito dell’Applicazione da Apple App Store o Google Play;</li>
  <li>l’installazione corretta dell’Applicazione;</li>
  <li>la registrazione Utente.</li>
</ul>

<p><b>3.1.2.</b> L’Applicazione, una volta installata, prevede la possibilità, per l’Utente, di utilizzare le funzioni solo ad avvenuta registrazione.</p>

<p><b>3.1.3.</b> L’Utente, al fine di utilizzare tutte le funzioni dell’Applicazione dovrà effettuare la registrazione al servizio, inserendo i dati richiesti ed accettando termini d’uso, condizioni e privacy policy.</p>

<p><b>3.1.4.</b> Il funzionamento dell’Applicazione e le attività ad essa connesse sono strettamente legati all’attività professionale svolta dall’Utente per conto e/o alle dipendenze e/o in collaborazione con la propria Azienda.</p>

<p><b>3.1.5.</b> Per impostazione predefinita, l’Applicazione effettuerà il download automatico degli Aggiornamenti.</p>

<p><b>3.2. Termini e modalità di funzionamento dell’Applicazione</b></p>

<p><b>3.2.1.</b> Attraverso l’Applicazione installata sul proprio smartphone, l’Utente, potrà caricare i dati relativi alla propria prestazione lavorativa quali, in via meramente enumerativa e non esaustiva, gli orari di lavoro, l’utente cui si rivolge la prestazione, la tipologia della prestazione, il luogo presso cui viene svolta la prestazione.</p>

<p><b>3.2.2.</b> I dati relativi alla prestazione svolta dovranno essere inseriti unicamente tramite Applicazione.</p>

<p><b>3.2.3.</b> L’Utente ha l’obbligo di inserire i dati lavorati nella settimana di competenza entro massimo le ore 23:59:00 del giorno di domenica della medesima settimana di competenza.</p>

<p><b>3.2.4.</b> L’App ha, altresì, per alcuni servizi che lo richiedano, lo scopo di:</p>
<ul>
  <li>consentire ai dipendenti di effettuare la registrazione dell’ingresso e dell’uscita all’interno di un perimetro massimo di 100 metri dall’area designata di svolgimento della propria prestazione lavorativa;</li>
  <li>consentire di abilitare l’accesso ai software aziendali solo quando l’utente si trova all’interno del suddetto perimetro;</li>
  <li>registrare unicamente le seguenti informazioni:
    <ul>
      <li>identificativo del badge;</li>
      <li>data e ora dell’evento di ingresso e di uscita;</li>
      <li>conferma della posizione entro il perimetro stabilito unicamente al momento della registrazione;</li>
    </ul>
  </li>
  <li>interrompere qualsiasi forma di tracciamento della posizione al di fuori degli eventi di check-in e check-out in corrispondenza della registrazione dell’entrata e dell’uscita (badging/controllo accessi).</li>
</ul>

<p>L’App non effettua in alcun modo il monitoraggio continuo della posizione dell’utente e non conserva informazioni sulla sua localizzazione al di fuori dell’orario strettamente necessario per il controllo accessi.</p>

<p><b>3.2.5.</b> È espressamente inteso che la Società si riserva tutti i diritti non specificamente licenziati in conformità alle presenti Condizioni.</p>

<p><b>4. LICENZA</b></p>

<p><b>4.1. Concessione di Licenza.</b></p>

<p><b>4.1.1.</b> Con le presenti Condizioni, la Società concede all’Utente il diritto non esclusivo e non trasferibile di utilizzare l’Applicazione conformemente alle modalità di uso espressamente consentite dall’Applicazione e disciplinate dalle presenti Condizioni. L’Utente dovrà attenersi a qualsiasi limitazione tecnica presente nell’Applicazione senza alterare in alcun modo l’Applicazione stessa. In particolare, l’Utente si asterrà, a titolo esemplificativo, dalle seguenti attività:</p>

<ul>
  <li>aggirare le limitazioni tecniche presenti nell’Applicazione;</li>
  <li>decodificare, decompilare l’Applicazione;</li>
  <li>rimuovere o modificare gli avvisi di copyright o l’identificazione dell’autore così come appaiono nell’Applicazione;</li>
</ul>

<p><b>4.2. Durata.</b> L’attivazione avviene al momento in cui l’Applicazione viene installata per la prima volta dall’Utente sul proprio smartphone dopo aver eseguito la registrazione.</p>

<p><b>4.3. Restrizioni alla licenza.</b></p>

<p><b>4.3.1.</b> L’Utente potrà installare e utilizzare l’Applicazione su qualsiasi smartphone compatibile di sua proprietà per tutto il periodo di licenza, anche disinstallando l’Applicazione dal dispositivo su cui la abbia precedentemente installata e installandola su un altro smartphone. Sono fatte salve, in ogni caso, le eventuali limitazioni tecniche applicate dai gestori delle piattaforme Apple App Store e Google Play.</p>

<p><b>4.3.2.</b> Salvo quanto espressamente consentito dalle presenti Condizioni, l’Utente non può:</p>
<ol type="i">
  <li>affittare, concedere in prestito, rivendere, sublicenziare o in altro modo distribuire l’Applicazione;</li>
  <li>usare l’Applicazione per fornire o rendere operativi servizi di marketing, formazione e outsourcing o consulenza, o qualsiasi altro servizio commerciale;</li>
  <li>usare l’Applicazione per sviluppare un prodotto che sia in concorrenza con l’Applicazione;</li>
  <li>utilizzare strumenti di accesso automatico, come spider o robot, oppure effettuare attività di scraping in relazione all’Applicazione o raccogliere informazioni e dati dall’Applicazione;</li>
  <li>consentire a terzi l’accesso o l’utilizzo dell’Applicazione, salvo quanto eventualmente previsto nelle presenti Condizioni;</li>
  <li>distribuire o pubblicare le proprie credenziali di accesso all’Applicazione;</li>
  <li>usare credenziali di accesso non autorizzate.</li>
</ol>

<p><b>5. ULTERIORI OBBLIGHI DELL’UTENTE</b></p>

<p><b>5.1.</b> In aggiunta a quanto previsto nella clausola 4) che precede, considerata la particolare natura cui è destinata l’Applicazione ed i dati trattati in essa inseriti, l’Utente si impegna ad utilizzare l’Applicazione in conformità alla normativa applicabile ed in particolare:</p>

<ul>
  <li>
    <p>dichiara, consapevole della responsabilità in caso di dichiarazioni mendaci, di formazione o uso di atti falsi, ai sensi e per gli effetti dell'art. 46 del D.P.R. 445 del 28 dicembre 2000, che i dati che verranno inseriti saranno veritieri e rispondenti alle effettive prestazioni rese, assumendosene l’Utente ogni responsabilità a riguardo;</p>
  </li>
  <li>
    <p>si obbliga a fornire informazioni esatte, aggiornate, non confidenziali e non lesive della proprietà intellettuale o di altri diritti di terzi;</p>
  </li>
  <li>
    <p>si obbliga ad inserire i dati relativi alla prestazione espletata nella settimana di competenza entro massimo le ore 23:59:00 della domenica della medesima settimana di competenza;</p>
  </li>
  <li>
    <p>si obbliga a custodire con diligenza le credenziali utilizzate per accedere all’Applicazione, della cui sicurezza e segretezza l’Utente è l’unico responsabile. Qualora l’Utente sospetti che sia stato effettuato un accesso non autorizzato al proprio account da parte di terzi, l’Utente è pregato di contattare immediatamente la Società, e di modificare la propria password nel più breve tempo possibile. La Società non assume alcuna responsabilità per qualunque danno derivante dalla non diligente custodia delle credenziali di accesso utilizzate dall’Utente per accedere all’Applicazione;</p>
  </li>
  <li>
    <p>si obbliga a non riprodurre, pubblicare, comunicare al pubblico con qualsiasi mezzo di comunicazione, trasmettere, eseguire, rappresentare, distribuire, esporre, rimuovere, cancellare, aggiungere ovvero in qualsiasi altro modo modificare l’Applicazione o i Contenuti, salvo che per finalità connesse all’uso personale e nei limiti previsti dalle vigenti leggi applicabili, nonché creare opere ad essi ispirate o derivate dagli stessi, commercializzare l’Applicazione e/o i Contenuti e/o prendere parte attivamente alla loro commercializzazione;</p>
  </li>
  <li>
    <p>si obbliga a non danneggiare, interferire o interrompere l’accesso all’Applicazione e ai Contenuti, ovvero compiere azioni che potrebbero alterare la funzionalità o interferire con l’accesso di altri Utenti all’Applicazione o ai Contenuti, nonché utilizzare l’Applicazione o i Contenuti in modo illegale o dannoso per la Società, o per qualsiasi altro Utente.</p>
  </li>
</ul>

<p><b>6. PROPRIETÀ INTELLETTUALE</b></p>

<p>Tutti i diritti e titoli di proprietà intellettuale sull’Applicazione sono e rimarranno della Società e/o dei suoi licenzianti e nessun diritto o titolarità sugli stessi viene con le presenti Condizioni trasferito all’Utente.</p>

<p>Fatto salvo quanto previsto dalle clausole 4 e 5 che precedono, l’Utente non avrà alcun diritto di modificare (anche ai fini della correzione di errore), adattare, o convertire l’Applicazione o creare opere derivate dall’Applicazione, eccetto quanto necessario per configurare l’Applicazione mediante menù, opzioni e strumenti ove previsti a tali fini e contenuti nell’Applicazione stessa.</p>

<p>Fatto salvo quanto permesso ai sensi della legge applicabile, sono proibite le attività di reverse compiling (incluso il reverse compiling diretto a consentire l’interoperabilità), reverse engineering e altra estrapolazione del codice sorgente dell’Applicazione.</p>

<p><b>7. GARANZIE</b></p>

<p><b>7.1.</b> L’Utente, ai sensi degli artt. 129 e seguenti del Codice del Consumo, ove applicabile, avrà diritto, in caso di non conformità dell’Applicazione alle presenti Condizioni, al ripristino senza spese della conformità dell’Applicazione mediante riparazione o sostituzione, ovvero alla risoluzione delle presenti Condizioni. L’Utente decadrà dai diritti previsti dall’articolo 130, comma 2, del Codice del Consumo, ove applicabile, se non denuncerà alla Società il difetto di conformità entro il termine di due (2) mesi dalla data in cui ha scoperto il difetto.</p>

<p><b>7.2.</b> Nei limiti consentiti dalla legge e ferme restando le garanzie fornite ai sensi delle presenti Condizioni, la Società non garantisce che il funzionamento dell’Applicazione sia ininterrotto o privo di errori.</p>

<p><b>7.3.</b> Escluse le garanzie specificate in questo articolo e nella misura massima consentita dalla legge, la Società non riconosce altre garanzie. Questo articolo sopravvivrà alla cessazione delle presenti Condizioni.</p>

<p><b>8. LIMITAZIONE DI RESPONSABILITÀ</b></p>

<p><b>8.1.</b> I Contenuti dell’Applicazione sono di proprietà della Società o dei suoi licenzianti. La Società non potrà essere ritenuta responsabile per eventuali danni dovessero derivare agli Utenti in relazione ai Contenuti.</p>

<p><b>8.2.</b> La Società non garantisce che il funzionamento dell’Applicazione sia ininterrotto o privo di errori. L’Utente riconosce espressamente che la Società non è responsabile per le azioni, i contenuti, le informazioni o i dati di terze parti – compresi contenuti, azioni, informazioni o dati di Apple, Inc., in quanto gestore della piattaforma Apple App Store, di Google, Inc., in quanto gestore della piattaforma Google Play, o di terzi che forniscano contenuti o servizi a pagamento sull’Applicazione – e l’Utente si impegna a tenere indenne la Società da qualsiasi pretesa, reclamo, richiesta di danno connessa a contenuti, azioni, informazioni o dati di terze parti.</p>

<p><b>8.3.</b> La Società non potrà essere in alcun modo ritenuta responsabile delle conseguenze derivanti dalla violazione, da parte dell’Utente, degli obblighi di cui alle presenti Condizioni, inclusa la violazione, da parte dell’Utente, della normativa applicabile al trattamento dei dati personali di terzi di cui egli venga in possesso nel corso dell’utilizzo dell’Applicazione. L’Utente si impegna a tenere indenne la Società da ogni e qualunque responsabilità derivante dalla violazione, da parte dell’Utente stesso, delle presenti Condizioni.</p>

<p><b>8.4.</b> Nulla di quanto stabilito nelle presenti Condizioni implica in alcun modo che la Società si obblighi né possa essere considerata tenuta allo svolgimento di un’attività di sorveglianza preventiva sulle informazioni che memorizza e trasmette, né a ricercare attivamente fatti o circostanze che indichino la presenza di attività legalmente o contrattualmente illecite. Sono fatti comunque salvi i diritti e le facoltà riconosciute alla Società ai sensi della legislazione comunitaria e nazionale applicabile.</p>

<p><b>9. RISOLUZIONE</b></p>

<p><b>9.1.</b> La Società potrà in ogni momento risolvere le presenti Condizioni, con effetto immediato, con semplice comunicazione scritta all’Utente nel caso in cui quest’ultimo violi uno qualsiasi degli obblighi previsti dalle clausole 4, 5 e 6 che precedono. La risoluzione non limiterà nessuna parte dall’esperire altri rimedi disponibili.</p>

<p><b>10. RECESSO</b></p>

<p><b>10.1.</b> Effettuando il download dell’Applicazione, l’Utente acconsente espressamente all’esecuzione, da parte della Società, della fornitura dell’Applicazione ed accetta che il diritto di recesso è escluso rispetto alla suddetta fornitura ai sensi dell’articolo 59, comma 1, lettera o), del Codice del Consumo.</p>

<p><b>11. DATI PERSONALI – DATI RACCOLTI E MODALITÀ DI TRATTAMENTO</b></p>

<p><b>11.1.</b> La Società tratterà i dati personali dell’Utente secondo la modalità ed i termini specificati nell’Informativa Privacy (<a href="https://www.3cengineering.it/privacy-policy">https://www.3cengineering.it/privacy-policy</a>), allegata alle presenti Condizioni, ferme restando le informazioni ed indicazioni fornite agli Utenti nell’ambito della Privacy e Cookie Policy del sito web della Società. L’Utente dichiara, altresì, di essere informato/a, ai sensi e per gli effetti di cui al D. Lgs. n.196/2003, nelle rimanenti parti applicabili, e del Reg. UE n.679/2016 (GDPR), che i dati raccolti saranno trattati, anche con strumenti informatici, esclusivamente nell'ambito del processo di inserimento dei dati.</p>

<p><b>11.2.</b> L’App raccoglie e tratta esclusivamente i seguenti dati personali degli utenti:</p>
<ul>
  <li>identificativo del badge aziendale;</li>
  <li>data e ora dell’evento di entrata e uscita;</li>
  <li>conferma della posizione all’interno del perimetro stabilito (100 metri) al momento della registrazione dell’accesso, ove richiesto dall’Azienda (datore di lavoro) unicamente per determinati servizi che lo richiedano.</li>
</ul>

<p>L’App non raccoglie in nessun caso dati sulla posizione dell’utente al di fuori dell’accesso ai sistemi aziendali, né esegue operazioni di tracciamento della mobilità o della permanenza in una determinata area.</p>

<p><b>11.3.</b> Il trattamento dei dati personali avviene nel rispetto delle seguenti basi giuridiche previste dal Regolamento (UE) 2016/679 (GDPR):</p>
<ul>
  <li><b>esecuzione di un contratto</b> [art. 6(1)(b) GDPR]: i dati sono necessari per la gestione dell’accesso ai sistemi aziendali in conformità al rapporto di lavoro tra dipendente e datore di lavoro.</li>
  <li><b>interesse legittimo del titolare del trattamento</b> [art. 6(1)(f) GDPR]: i dati sono trattati per garantire la sicurezza e la corretta gestione degli accessi ai sistemi informatici aziendali.</li>
</ul>

<p><b>11.4.</b> I dati raccolti dall’App vengono conservati per il periodo strettamente necessario a garantire la sicurezza degli accessi e la verifica delle attività degli utenti. In particolare:</p>
<ul>
  <li>i dati sono archiviati su server protetti, situati all’interno dell’Unione Europea, e soggetti a misure di sicurezza avanzate, tra cui:</li>
  <ul>
    <li>crittografia dei dati;</li>
    <li>autenticazione a più fattori per l’accesso ai sistemi di archiviazione;</li>
    <li>accesso limitato solo al personale autorizzato.</li>
  </ul>
</ul>

<p>I dati non saranno mai condivisi con terze parti per finalità di marketing o profilazione e non saranno trasmessi al di fuori dello Spazio Economico Europeo (SEE), salvo specifici obblighi di legge.</p>

<p><b>11.5.</b> Gli utenti dell’App, in qualità di interessati ai sensi del GDPR, hanno diritto di:</p>
<ul>
  <li>accedere ai dati personali trattati dall’App;</li>
  <li>chiedere la rettifica dei dati inesatti o incompleti;</li>
  <li>richiedere la cancellazione dei dati nei limiti consentiti dalla normativa;</li>
  <li>limitare il trattamento dei dati in determinate circostanze previste dal GDPR;</li>
  <li>opporsi al trattamento, qualora ritengano che i loro diritti fondamentali prevalgano sull’interesse legittimo del titolare del trattamento;</li>
  <li>presentare un reclamo all’Autorità Garante per la protezione dei dati personali se ritengono che il trattamento dei loro dati personali avvenga in violazione delle disposizioni di legge.</li>
</ul>

<p>Per esercitare i propri diritti, l’utente può inviare una richiesta a <a href="mailto:assistenza@3cengineering.it">assistenza&#64;3cengineering.it</a>.</p>

<p><b>12. CLAUSOLE GENERALI</b></p>

<p><b>12.1. Legge Applicabile e Foro Competente.</b> Per qualsiasi controversia inerente l’interpretazione o l’esecuzione delle presenti Condizioni e dei contratti che ad esso facciano riferimento, sarà applicabile esclusivamente la legge Italiana, con esclusione delle norme di diritto internazionale privato eventualmente applicabili. Ogni controversia derivante dall’interpretazione, validità, esecuzione, adempimento o risoluzione delle presenti Condizioni o comunque inerente al o derivante dalle Condizioni sarà soggetta alla competenza esclusiva ed inderogabile del giudice del luogo ove la Società ha sede legale.</p>

<p><b>12.2. Integrazioni e Modifiche.</b> Le presenti Condizioni costituiscono l’intero accordo tra le Parti e sostituiscono qualsiasi precedente o contemporanea condizione, accordo o dichiarazione, sia verbale che scritta, relativa alla materia oggetto delle presenti Condizioni. Le presenti Condizioni non potranno essere modificate o nessun termine o condizione rinunciata se non con un documento scritto e sottoscritto da un rappresentante debitamente autorizzato di ciascuna parte.</p>

<p><b>12.3. Cessione.</b> Le presenti Condizioni e qualsiasi diritto e obbligazione, non potranno essere cedute dall’Utente senza il preventivo consenso scritto della Società.</p>

<p><b>12.4. Conservazione delle Condizioni.</b> Qualora una qualsiasi disposizione delle presenti Condizioni sia dichiarata illecita, nulla, o inefficace, tale disposizione sarà modificata in modo da renderla efficace o altrimenti eliminata, e non inficerà la validità e l’efficacia delle restanti disposizioni.</p>

<p><b>12.5. Forza maggiore.</b> Nessuna parte sarà considerata inadempiente ai sensi delle presenti Condizioni, a causa di un ritardo o della mancata esecuzione della sua obbligazione, risultante da qualsiasi evento al di fuori del suo ragionevole controllo (cd. “Forza Maggiore”) a condizione che dia comunicazione in modo ragionevolmente tempestivo della condizione di Forza Maggiore e compia ragionevoli sforzi per ridurre il ritardo o l’inadempimento.</p>

    </div>
</div>