<div class="container profile_page">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">

        <!-- Profilo -->
        <mat-tab label="Profilo">
            <div class="main_tab_body">
                <div class="profile-field">
                    <small class="field-name">USERNAME</small>
                    <label class="field-value">{{ user.username }}</label>
                </div>
                <div class="profile-field">
                    <small class="field-name">NOME E COGNOME</small>
                    <label class="field-value">{{ user.name }} {{ user.surname }}</label>
                </div>
                <div class="profile-field">
                    <small class="field-name">COOPERATIVA</small>
                    <label class="field-value">{{ company.description }}</label>
                </div>
                <div class="profile-field">
                    <small class="field-name">IDENTIFICATIVO UNIVOCO</small>
                    <label class="field-value">#{{ user.id }}</label>
                </div>
                <div class="profile-field">
                    <small class="field-name">RUOLO</small>
                    <label class="field-value">
                        {{ user.sys_admin ? 'Sys Admin' : user.admin ? 'Admin' : user.principal ? 'Preside' :
                        'Operatore' }}
                    </label>
                </div>
                <div class="profile-field">
                    <small class="field-name">EMAIL</small>
                    <label class="field-value">{{ user.email }}</label>
                </div>
                <div class="profile-field">
                    <small class="field-name">TELEFONO</small>
                    <label class="field-value">{{ user.phone }}</label>
                </div>
                <div class="profile-field">
                    <small class="field-name">STATO</small>
                    <label class="field-value">Attivo</label>
                </div>
                <button mat-raised-button class="btn-primary" [routerLink]="['/cambio-password']">
                    Cambio password
                </button>
            </div>
        </mat-tab>

        <!-- Documenti -->
        <!-- mat-tab>
            <ng-template mat-tab-label>
                <div matBadge="{{documents.length ?? 0}}" matBadgeOverlap="false" class="demo-section">
                    Documenti
                </div>
            </ng-template>

            <div *ngIf="!documents.length">
                Nessun documento disponibile...
            </div>

            <div class="file-list" *ngFor="let doc of documents">
                <div class="file-card">
                    <div class="file-icon">
                        {{ getExtension(doc.original_name) }}
                    </div>
                    <div class="file-info">
                        <div class="file-name">{{ doc.original_name }}</div>
                        <div class="file-meta">
                            <span class="file-date">{{ doc.uploaded_at | date: 'shortDate' }}</span>
                            <span class="file-size">{{ formatFileSize(doc.file_size) }}</span>
                        </div>
                    </div>
                    <button mat-icon-button class="download-button" (click)="downloadFile(doc.id)">
                        <mat-icon>cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </mat-tab -->

    </mat-tab-group>
</div>