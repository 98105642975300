import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { StorageService } from './services/storage.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { debounceTime, filter, fromEvent, Subject, Subscription, takeUntil } from 'rxjs';
import { CompanyModel } from './models/db.model';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environment/environment';
import { SessionService } from './services/session.service';
import { Session } from './models/session.model';
import { VersionCheckService } from './services/version-check.service';

const DEFAULT_TITLE = 'Cooperative in Cloud';
const PRODUCTION = environment.production;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent {
    @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
    private destroy$ = new Subject<void>();
    siteFavicon: HTMLLinkElement = document.querySelector('#site_favicon');
    smallScreen: boolean = false;
    isProduction: boolean = true;
    loggedIn: boolean = false;
    termsAndConditions: boolean = false;
    opened: boolean;
    events: string[] = [];
    username: string = '';
    mobile: boolean = false;
    companyInfo: CompanyModel;
    headerImage: string;
    private subscriptions = new Subscription();

    constructor(
        private storage: StorageService,
        private router: Router,
        private titleService: Title,
        private meta: Meta,
        private sessionService: SessionService,
        private cdr: ChangeDetectorRef,
        private versionCheckService: VersionCheckService
    ) {
        this.isProduction = PRODUCTION;

        if (!PRODUCTION) {
            // prevenie che il sito di test venga indicizzato
            this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
        }
    }

    ngOnInit() {
        this.versionCheckService.startVersionCheck();

        this.checkScreenSize();

        this.subscriptions.add(
            this.sessionService.userSettings$.subscribe((settings: Session) => {
                this.loggedIn = !!this.storage.getToken();
                if (settings == null) {
                    this.cdr.detectChanges();
                    return;
                }
                this.headerImage = settings.company.image_header;
                this.siteFavicon.href = settings.company.image_favicon;
                settings.company.description?.length > 0 ?
                    this.titleService.setTitle(settings.company.description + ' - ' + DEFAULT_TITLE)
                    : this.titleService.setTitle(DEFAULT_TITLE);
                this.username = settings.user.username;
                this.termsAndConditions = settings.user.terms_and_conditions;
                this.cdr.detectChanges();
            })
        );

        this.subscriptions.add(
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this.destroy$)
            ).subscribe(() => {
                if (this.smallScreen) {
                    this.sidenav.close();
                }
            })
        );

        this.loggedIn = !!this.storage.getToken();

        fromEvent(window, 'resize')
            .pipe(debounceTime(150), takeUntil(this.destroy$))
            .subscribe(() => this.checkScreenSize());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.subscriptions.unsubscribe(); // Annulla tutte le sottoscrizioni
    }

    private checkScreenSize() {
        this.smallScreen = window.innerWidth < 768;
        if (this.smallScreen) this.sidenav?.close();
        this.mobile = this.storage.getMobile() == 'true' ? true : false;
    }

    // gestione swipe chiusura menu
    startX = 0;
    startY = 0;
    currentX = 0;
    currentY = 0;
    isSwiping = false;
    sidenavWidth = 250;
    isVerticalSwipe = false;
    minSwipeDistance = 10; // Distanza minima per considerare uno swipe (in pixel)

    startSwipe(event: TouchEvent) {
        if (!this.smallScreen || !this.sidenav.opened) return; // Swipe solo su mobile con menu aperto
        
        this.isSwiping = true;
        this.startX = event.touches[0].clientX;
        this.startY = event.touches[0].clientY; // Aggiungi la variabile per tracciare la posizione verticale iniziale
        this.isVerticalSwipe = false;

        this.disableBodyScroll(); // Disabilita lo scroll del body
        this.disableSidenavScroll(); // Disabilita lo scroll del sidenav
    }

    moveSwipe(event: TouchEvent) {
        if (!this.isSwiping) return;

        this.currentX = event.touches[0].clientX;
        this.currentY = event.touches[0].clientY;

        const horizontalDistance = Math.abs(this.currentX - this.startX);
        const verticalDistance = Math.abs(this.currentY - this.startY);

        // Determina se lo swipe è verticale o orizzontale
        if (verticalDistance > horizontalDistance) {
            this.isVerticalSwipe = true; // Se il movimento verticale è maggiore, è uno swipe verticale
        }

        if (this.isVerticalSwipe) {
            return; // Blocca lo swipe orizzontale se è in corso uno swipe verticale
        }

        let translateX = Math.min(0, this.currentX - this.startX); // Evita che vada oltre il punto di partenza
        const sidenavElement = document.querySelector('mat-sidenav') as HTMLElement;
        sidenavElement.style.transform = `translateX(${translateX}px)`;
    }

    endSwipe(event: TouchEvent) {
        if (!this.isSwiping) return;

        // Aggiorna currentX e currentY se il tap è avvenuto senza movimento significativo
        this.currentX = event.changedTouches[0].clientX;
        this.currentY = event.changedTouches[0].clientY;

        this.isSwiping = false;

        const distance = this.currentX - this.startX;
        const sidenavElement = document.querySelector('mat-sidenav') as HTMLElement;

        // Rimuove lo stile manuale per permettere ad Angular di gestire l'animazione
        sidenavElement.style.transform = '';

        // Rimuove la transizione temporaneamente per evitare sfarfallio
        sidenavElement.style.transition = 'none';

        // Se il movimento è troppo breve (un tap), non fare nulla
        if (Math.abs(distance) < this.minSwipeDistance) {
            this.enableBodyScroll();
            this.enableSidenavScroll(); // Ripristina lo scroll del sidenav
            return;
        }

        if (distance < -this.sidenavWidth / 2) {
            setTimeout(() => {
                this.sidenav.close(); // Chiude il menu se lo swipe è abbastanza lungo
                this.enableBodyScroll();
                this.enableSidenavScroll(); // Ripristina lo scroll del sidenav
            }, 10);

        } else {
            this.enableBodyScroll();
            this.enableSidenavScroll(); // Ripristina lo scroll del sidenav
        }

        // Ripristina la transizione per la chiusura
        setTimeout(() => {
            sidenavElement.style.transition = 'transform 0.2s ease-out';
        }, 300);
    }

    private disableBodyScroll() {
        document.body.style.overflow = 'hidden';
    }

    private enableBodyScroll() {
        document.body.style.overflow = '';
    }

    private disableSidenavScroll() {
        const sidenavElement = document.querySelector('mat-sidenav') as HTMLElement;
        sidenavElement.style.overflowY = 'hidden'; // Disabilita lo scroll verticale
    }

    private enableSidenavScroll() {
        const sidenavElement = document.querySelector('mat-sidenav') as HTMLElement;
        sidenavElement.style.overflowY = ''; // Ripristina lo scroll verticale
    }
}
