import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SessionService } from 'src/app/services/session.service';
import { Session } from 'src/app/models/session.model';
import { MatIcon } from '@angular/material/icon';

const HOME_TITLE = 'Cooperative in Cloud';
const HOME_IMAGE = 'assets/img/cooperativeincloud/sfondo-home.png';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    imports: [
        RouterLink,
        CommonModule,
        MatIcon
    ]
})
export class HomeComponent {
    private subscriptions = new Subscription();
    homeTitle: string;
    homeImage: string;
    contractHasAbsences: boolean = false;
    settings: Session;
    isAdmin: boolean = false;

    constructor(
        private sessionService: SessionService
    ) { }

    ngOnInit() {
        this.subscriptions.add(
            this.sessionService.userSettings$.subscribe((settings: Session) => {
                this.homeTitle = settings?.company?.description || HOME_TITLE;
                this.homeImage = settings?.company?.image_home || HOME_IMAGE;
                this.contractHasAbsences = !([3, 4].indexOf(settings?.user.id_contract) > -1);
                this.settings = new Session(settings);
                this.isAdmin = this.settings.isAdmin();
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe(); // Annulla tutte le sottoscrizioni
    }
}
