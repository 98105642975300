<div class="work-container card">
    <div *ngIf="!activeWork">
        <h2>Nuova attività</h2>
    </div>

    <div *ngIf="!endWorkCompleted">
        <div *ngIf="activeWork">
            <h2>Attività in corso</h2>
            <div class="time-card">
                <div>
                    <small>Codice</small><br />
                    <strong>{{ activeWork.code }}</strong>
                </div>
                <div class="time-box">
                    <div class="left-box">
                        <mat-icon>access_time</mat-icon>
                        <div>
                            <small>Inizio</small><br />
                            <strong>{{ secondsToTime(activeWork.start_time) }}</strong>
                        </div>
                    </div>
                    <div *ngIf="duration" class="right-box">
                        <mat-icon>timer</mat-icon>
                        <div>
                            <small>In corso</small><br />
                            <strong>{{ now?.format('HH:mm:ss') }}</strong>
                        </div>
                    </div>
                </div>
                <div *ngIf="duration">
                    <small>Durata attuale</small><br />
                    <strong>{{ secondsToTime(duration) }}</strong>
                </div>
            </div>
        </div>
        <div class="position-status">
            <div class="status-indicator" [ngClass]="positionStatus">
                <span class="led"></span>
                <span class="status-text">
                    {{ positionStatusText }}
                </span>
                <button mat-icon-button class="refresh-button" (click)="refreshPosition()"
                    [disabled]="positionStatus === 'searching'">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </div>

        <div id="location-map" class="map-container"></div>

        <div *ngIf="activeWork && positionStatus !== 'searching'">
            <div *ngIf="!isInsideGeofence">
                <div class="site-status-box error-box">
                    Non ti trovi in nessuna sede autorizzata.
                    <br />Non è possibile terminare l'attività.
                </div>
            </div>
            <div *ngIf="isInsideGeofence && !isInCorrectSite">
                <div *ngIf="pua_badge_same_start_end">
                    <div class="site-status-box warning-box">
                        Ti trovi nella sede: {{ activeGeofence?.description_site }}
                        <br />Devi essere nella sede: {{ activeWork?.description_site }}
                    </div>
                </div>
                <div *ngIf="!pua_badge_same_start_end">
                    Attività iniziata nella sede: {{ activeWork?.description_site }}
                    <h3 *ngIf="multipleSites.length == 1">Puoi terminare l'attività nella sede: {{ activeGeofence?.description_site }}</h3>
                    <h3 *ngIf="multipleSites.length > 1">Seleziona la sede corretta per la fine attività</h3>
                    <mat-radio-group class="sites-list" [(ngModel)]="selectedSite">
                        <mat-radio-button *ngFor="let geofence of multipleSites" [value]="geofence"
                            (change)="updateMapPolygons(geofence)" [class.selected]="selectedSite === geofence">
                            {{ geofence.description_site }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div *ngIf="isInCorrectSite" class="site-status-box success-box">
                Sei nella sede corretta: {{ activeWork.description_site }}
            </div>
        </div>

        <div *ngIf="!positionLoading && positionError" class="position-container">
            <div class="error-box">
                Si è verificato un errore nel caricamento della posizione.
                <br />Si prega di riprovare o contattare gli amministratori.
                <button mat-button class="full-width" (click)="reloadPage()">
                    Aggiorna
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="!activeWork">
        <mat-form-field *ngIf="pua_badge_has_activities" appearance="outline" class="full-width">
            <mat-label>Seleziona attività</mat-label>
            <mat-select [(ngModel)]="selectedActivity">
                <mat-option *ngFor="let activity of activities" [value]="activity.id">
                    {{ activity.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="positionStatus !== 'searching' && !multipleSites.length" class="site-status-box" [ngClass]="{
            'success-box': isInsideGeofence,
            'error-box': !isInsideGeofence
        }">
            <div *ngIf="!isInsideGeofence">
                Non ti trovi in nessuna sede autorizzata.
                <br />Non è possibile iniziare l'attività.
            </div>
            <div *ngIf="isInsideGeofence">
                Ti trovi nella sede: {{ activeGeofence.description_site }}
            </div>
        </div>

        <div *ngIf="multipleSites.length > 0">
            <h3 *ngIf="multipleSites.length > 1">Seleziona la sede corretta</h3>
            <mat-radio-group class="sites-list" [(ngModel)]="selectedSite">
                <mat-radio-button *ngFor="let geofence of multipleSites" [value]="geofence"
                    (change)="updateMapPolygons(geofence)" [class.selected]="selectedSite === geofence">
                    {{ geofence.description_site }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <button mat-raised-button class="button-with-icon full-width"
            [disabled]="positionLoading || positionError || !position || !selectedSite" (click)="startWork()">
            Inizio Attività
        </button>
    </div>

    <div *ngIf="activeWork">
        <button *ngIf="!endWorkCompleted" mat-raised-button class="button-with-icon full-width"
            [disabled]="positionLoading || positionError || !position || !selectedSite" (click)="endWork()">
            Fine Attività
        </button>
        <div *ngIf="endWorkCompleted">
            <div class="success-box">
                <mat-icon>thumb_up</mat-icon>
                <h2>Attività terminata correttamente!</h2>
            </div>

            <div class="site-box">
                Sede<br />
                <strong>{{ activeWork.description_site }}</strong>
            </div>
            <div class="time-card">
                <div>
                    <small>Codice</small><br />
                    <strong>{{ activeWork.code }}</strong>
                </div>
                <div class="time-box">
                    <div class="left-box">
                        <mat-icon>access_time</mat-icon>
                        <div>
                            <small>Inizio</small><br />
                            <strong>{{ secondsToTime(activeWork.start_time) }}</strong>
                        </div>
                    </div>
                    <div *ngIf="duration" class="right-box">
                        <mat-icon>timer</mat-icon>
                        <div>
                            <small>Fine</small><br />
                            <strong>{{ endWorkTs }}</strong>
                        </div>
                    </div>
                </div>
                <div *ngIf="duration">
                    <small>Durata</small><br />
                    <strong>{{ secondsToTime(duration) }}</strong>
                </div>
            </div>

            <button mat-button class="full-width" (click)="reloadPage()">
                Nuova Attività
            </button>
        </div>
    </div>
</div>