<h1 mat-dialog-title>
    <span *ngIf="!isEditMode">Inserimento</span>
    <span *ngIf="isEditMode">Modifica</span>
</h1>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="dynamicForm" (ngSubmit)="onSubmit()">
        <ng-container *ngFor="let group of data.model.getFormFieldsGroups()">
            <div class="group-wrapper" *ngIf="data.model.getGroupFormFields(group, formMode).length > 0">
                <label *ngIf="group" class="group-title">{{group}}</label>
                <!-- <ng-container *ngFor="let col of formFields"> -->
                <ng-container *ngFor="let col of data.model.getGroupFormFields(group, formMode)">
                    <ng-container *ngIf="
                        col.form!=null
                        && col.crud.indexOf(formMode) !== -1
                        && ( col.form.type!='select' || filteredDynamicData[col.form.name]['data'].length > 0 || col.form.isRequired || dynamicForm.get(col.form.name)?.hasValidator(Validators.required))">
                        <!-- Eccezione per nascondere i campi select se non hanno valori possibili e non sono required -->
                        <div class="group-field">

                            <ng-container *ngIf="col.form.type=='hidden'">
                                <input id="form_{{col.form.name}}" type="hidden" [attr.value]="col.form.defaultValue"
                                    formControlName="{{col.form.name}}">
                            </ng-container>

                            <ng-container *ngIf="col.form.type=='readonly'">
                                <mat-form-field appearance="outline" class="fill-width readonly-input">
                                    <mat-label>{{col.label}}</mat-label>
                                    <input matInput id="form_{{col.form.name}}" type="text"
                                        formControlName="{{col.form.name}}" [attr.value]="col.form.defaultValue" readonly>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngIf="col.form.type=='text'">
                                <mat-form-field appearance="outline" class="fill-width">
                                    <mat-label>{{col.label}}</mat-label>
                                    <input #input matInput type="text" value="" formControlName="{{col.form.name}}"
                                        placeholder="{{col.form.placeholder}}" (keyup)="customValidation(col)">
                                    <mat-icon matSuffix *ngIf="f[col.form.name].status=='VALID'"
                                        class="valid-icon">check</mat-icon>
                                    <mat-hint *ngIf="col.form.maxLength" align="end">
                                        {{input.value.length}}/{{col.form.maxLength}}
                                    </mat-hint>
                                    <mat-error *ngIf="f[col.form.name].errors?.required">
                                        Il campo '{{col.label}}' è obbligatorio
                                    </mat-error>
                                    <mat-error *ngIf="f[col.form.name].errors?.maxlength
                                        || f[col.form.name].errors?.minlength" align="end">
                                        {{input.value.length}}/{{col.form.maxLength}}
                                    </mat-error>
                                    <mat-error *ngIf="f[col.form.name].errors?.pattern" align="end">
                                        Formato non valido
                                    </mat-error>
                                    <mat-error *ngIf="f[col.form.name].errors?.codiceFiscale" align="end">
                                        Il codice fiscale inserito non è valido
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngIf="col.form.type=='textarea'">
                                <mat-form-field appearance="outline" class="fill-width">
                                    <mat-label>{{col.label}}</mat-label>
                                    <textarea #input matInput type="text" value="" formControlName="{{col.form.name}}"
                                        placeholder="{{col.form.placeholder}}"></textarea>
                                    <mat-icon matSuffix *ngIf="f[col.form.name].status=='VALID'"
                                        class="valid-icon">check</mat-icon>
                                    <mat-hint *ngIf="col.form.maxLength"
                                        align="end">{{input.value.length}}/{{col.form.maxLength}}</mat-hint>
                                    <mat-error *ngIf="f[col.form.name].errors?.required">Il campo '{{col.label}}' è
                                        obbligatorio</mat-error>
                                    <mat-error *ngIf="f[col.form.name].errors?.maxlength"
                                        align="end">{{input.value.length}}/{{col.form.maxLength}}</mat-error>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngIf="col.form.type=='number'">
                                <mat-form-field appearance="outline" class="fill-width">
                                    <mat-label>{{col.label}}</mat-label>
                                    <input matInput type="number" value="" step="1" min="0"
                                        formControlName="{{col.form.name}}" placeholder="{{col.form.placeholder}}">
                                    <mat-icon matSuffix *ngIf="f[col.form.name].status=='VALID'"
                                        class="valid-icon">check</mat-icon>
                                    <mat-hint align="end">Formato 123</mat-hint>
                                    <mat-error *ngIf="f[col.form.name].errors?.required">Il campo '{{col.label}}' è
                                        obbligatorio</mat-error>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngIf="col.form.type=='currency'">
                                <mat-form-field appearance="outline" class="fill-width">
                                    <mat-label>{{col.label}}</mat-label>
                                    <input matInput type="number" step="0.01" min="0.00" value=""
                                        formControlName="{{col.form.name}}" placeholder="{{col.form.placeholder}}">
                                    <mat-icon matSuffix *ngIf="f[col.form.name].status=='VALID'"
                                        class="valid-icon">check</mat-icon>
                                    <mat-hint align="end">Formato 123,45</mat-hint>
                                    <mat-error *ngIf="f[col.form.name].errors?.required">Il campo '{{col.label}}' è
                                        obbligatorio</mat-error>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngIf="col.form.type=='select'">
                                <mat-form-field appearance="outline" class="fill-width">
                                    <mat-label>{{col.label}}</mat-label>
                                    <mat-select matInput formControlName="{{col.form.name}}"
                                        (selectionChange)="selectChange(col.form.name, $event)">
                                        <mat-form-field appearance="outline"
                                            *ngIf="dynamicData[col.form.name].form.select.filter"
                                            style="margin: 0px 8px; width: calc(100% - 16px);">
                                            <input matInput (keyup)="selectFilter(col.form.name, $event)"
                                                formControlName="{{'selectFilter_'+col.form.name}}" placeholder="Filtro">
                                        </mat-form-field>
                                        <!-- Placeholder mostrato solo se elenco vuoto o con più di un elemento -->
                                        <mat-option
                                            *ngIf="filteredDynamicData[col.form.name]['data'].length!=1 || !col.form.isRequired">--</mat-option>
                                        <!-- Elenco valori, se presente un solo valore, viene selezionato di default -->
                                        <mat-option *ngFor="let opt of filteredDynamicData[col.form.name]['data']"
                                            [value]="opt[dynamicData[col.form.name].form.select.id]"
                                            [attr.selected]="filteredDynamicData[col.form.name]['data'].length==1 ? 'selected' : null">
                                            {{opt[dynamicData[col.form.name].form.select.label]}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix *ngIf="f[col.form.name].status=='VALID'" class="valid-icon">
                                        check
                                    </mat-icon>
                                    <mat-error *ngIf="f[col.form.name].errors?.required">
                                        Il campo '{{col.label}}' è obbligatorio
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngIf="col.form.type=='date'">
                                <mat-form-field appearance="outline" class="fill-width">
                                    <mat-label>{{col.label}}</mat-label>
                                    <input matInput [matDatepicker]="picker" formControlName="{{col.form.name}}"
                                        [matDatepickerFilter]="col.form.name == 'execution' && ( data.model.sourceName == 'assistances' || data.model.sourceName == 'absences' ) ? dateFilter : null"
                                        (dateChange)="checkConditionalRequired(col.form.name)">
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-hint align="end">Formato dd/mm/yyyy</mat-hint>
                                    <mat-error *ngIf="f[col.form.name].errors?.required">
                                        Il campo '{{col.label}}' è obbligatorio
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngIf="col.form.type=='time'">
                                <mat-form-field appearance="outline" class="fill-width">
                                    <mat-label>{{col.label}}</mat-label>
                                    <!-- TODO
                                    spostare min e max nel modello
                                -->
                                    <input #input matInput type="time" step="300" min="08:00" max="19:00" value=""
                                        formControlName="{{col.form.name}}" placeholder="{{col.form.placeholder}}"
                                        (keyup)="(col.form.name == 'start_time' || col.form.name == 'end_time') && data.model.sourceName == 'assistances' ? checkTime() : null"
                                        (change)="(col.form.name == 'start_time' || col.form.name == 'end_time') && data.model.sourceName == 'assistances' ? checkTime() : null">
                                    <mat-icon matSuffix *ngIf="f[col.form.name].status=='VALID'"
                                        class="valid-icon">check</mat-icon>
                                    <mat-error *ngIf="f[col.form.name].errors?.required">
                                        Il campo '{{col.label}}' è obbligatorio
                                    </mat-error>
                                    <mat-error *ngIf="f[col.form.name].errors?.duration" align="end">
                                        La durata non è valida (45min - 10h)
                                    </mat-error>
                                    <mat-error *ngIf="f[col.form.name].errors?.startEnd && col.form.name == 'start_time' && data.model.sourceName == 'assistances'" align="end">
                                        L'orario deve essere compreso tra 8:00 e 18:15
                                    </mat-error>
                                    <mat-error *ngIf="f[col.form.name].errors?.startEnd && col.form.name == 'end_time' && data.model.sourceName == 'assistances'" align="end">
                                        L'orario deve essere compreso tra 8:15 e 19:00
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngIf="col.form.type=='boolean'">
                                <mat-slide-toggle color="primary" formControlName="{{col.form.name}}"
                                    (change)="checkConditionalRequired(col.form.name)" style="padding-bottom: 12px">
                                    {{col.label}}
                                </mat-slide-toggle>
                            </ng-container>

                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" mat-button (click)="cancel()">Annulla</button>
    <button type="button" mat-button (click)="onSubmit()" type="submit" class="button-with-icon"
        [disabled]="loading || !formIsChanged">Salva</button>
</mat-dialog-actions>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>