<mat-sidenav-container hasBackdrop="true" class="example-container">
    <!-- Sidenav di destra -->
    <mat-sidenav #sidenav fixedInViewport="true" mode="over" position="end" [opened]="menuOpened"
        (opened)="events.push('open!')" (closed)="events.push('close!')" class="list-right-menu">
        <div class="right-menu-buttons">
            <!-- Pulsante nascondi sidenav di destra -->
            <button mat-button (click)="sidenav.toggle()" class="list-right-menu-close updateFiltersBtn main-color">
                Chiudi<mat-icon>clear</mat-icon>
            </button>
            <button #updateFiltersBtn mat-button class="updateFiltersBtn" [class.d-none]="hideFiltersBtn"
                (click)="serverFilters()">
                Aggiorna<mat-icon>refresh</mat-icon>
            </button>
        </div>
        <!-- Menu laterale di destra -->
        <mat-accordion>
            <!-- Sezione ordinamento dati -->
            <mat-expansion-panel *ngIf="tableOptions.sorting">
                <mat-expansion-panel-header>
                    <mat-panel-title>Ordinamento</mat-panel-title>
                    <mat-panel-description>{{table.getFieldByName(sortActive)['label']}}</mat-panel-description>
                </mat-expansion-panel-header>

                <div style="display: flex; flex-direction: column">
                    <mat-form-field appearance="outline">
                        <mat-label>Colonna</mat-label>
                        <mat-select [(ngModel)]="sortActive" (selectionChange)="tableSortInputs()">
                            <!-- <mat-option>--</mat-option> -->
                            <mat-option *ngFor="let field of tableVisibleFields"
                                value="{{field.name}}">{{field.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Direzione</mat-label>
                        <mat-select [(ngModel)]="sortDirection" (selectionChange)="tableSortInputs()">
                            <mat-option value="asc">Crescente</mat-option>
                            <mat-option value="desc">Decrescente</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <p *ngIf="tableOptions.exportTypes?.indexOf('xlsx_full') !== -1">
                        <mat-slide-toggle [(ngModel)]="distinctOption">
                            Valori univoci <small><i>(solo export full)</i></small>
                        </mat-slide-toggle>
                    </p>
                </div>
            </mat-expansion-panel>

            <!-- Sezione filtri -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Filtri</mat-panel-title>
                    <mat-panel-description>Filtri avanzati</mat-panel-description>
                </mat-expansion-panel-header>
                <!-- Svuota e pulisce tutti i filtri -->
                <button mat-button (click)="clearFilters()" style="margin-bottom: 12px;" class="main-color"
                    *ngIf="filtersObjKV.length > 0">
                    <mat-icon>clear</mat-icon>Cancella filtri ({{filtersCount}})
                </button>
                <!-- Elenco filtri -->
                <form [formGroup]="filtersForm"
                        style="display: flex; flex-direction: column"
                        class="list-filters"
                        (keydown.enter)="serverFilters()">
                    <ng-container *ngFor="let col of tableVisibleFields" matColumnDef="{{col.name}}-filter">
                        <ng-container [ngSwitch]="tableVisibleFieldsKV[col.name]['format']">
                            <ng-container *ngSwitchCase="'number'">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{col.label}}</mat-label>
                                    <input matInput type="number" step="1" min="0" formControlName="{{col.name}}"
                                        (keyup)="filterField(col.name,'number',$event)" />
                                    <mat-hint align="end">Formato 123</mat-hint>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'currency'">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{col.label}}</mat-label>
                                    <input matInput type="number" step="0.01" min="0.00" formControlName="{{col.name}}"
                                        (keyup)="filterField(col.name,'number',$event)" />
                                    <mat-hint align="end">Formato 123,45</mat-hint>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'string'">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{col.label}}</mat-label>
                                    <input matInput type="string" formControlName="{{col.name}}"
                                        (keyup)="filterField(col.name,'string',$event)" />
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'datetime'">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{col.label}}</mat-label>
                                    <mat-date-range-input [rangePicker]="dtPicker">
                                        <input matStartDate placeholder="Da" formControlName="{{col.name}}_start"
                                            (dateChange)="filterField(col.name,'startDate',$event)">
                                        <input matEndDate placeholder="A" formControlName="{{col.name}}_end"
                                            (dateChange)="filterField(col.name,'endDate',$event)">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matIconSuffix [for]="dtPicker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #dtPicker></mat-date-range-picker>
                                    <mat-hint align="end">Formato dd/mm/yyyy</mat-hint>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{col.label}}</mat-label>
                                    <mat-date-range-input [rangePicker]="dtPicker">
                                        <input matStartDate placeholder="Da" formControlName="{{col.name}}_start"
                                            (dateChange)="filterField(col.name,'startDate',$event)">
                                        <input matEndDate placeholder="A" formControlName="{{col.name}}_end"
                                            (dateChange)="filterField(col.name,'endDate',$event)">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matIconSuffix [for]="dtPicker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #dtPicker></mat-date-range-picker>
                                    <mat-hint align="end">Formato dd/mm/yyyy</mat-hint>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'time'">
                                <div style="display: inline-flex; flex-direction: row;">
                                    <mat-form-field appearance="outline"
                                        style="width: calc(50% - 4px); margin-right: 8px;">
                                        <mat-label>{{col.label}} da</mat-label>
                                        <input matInput type="time" placeholder="Da"
                                            formControlName="{{col.name}}_start"
                                            (change)="filterField(col.name,'startTime',$event)">
                                        <mat-icon matSuffix>access_time</mat-icon>
                                        <mat-hint align="end">Formato hh:mm</mat-hint>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" style="width: calc(50% - 4px)">
                                        <mat-label>{{col.label}} a</mat-label>
                                        <input matInput type="time" placeholder="A" formControlName="{{col.name}}_end"
                                            (change)="filterField(col.name,'endTime',$event)">
                                        <mat-icon matSuffix>access_time</mat-icon>
                                        <mat-hint align="end">Formato hh:mm</mat-hint>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'boolean'">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{col.label}}</mat-label>
                                    <mat-select formControlName="{{col.name}}"
                                        (selectionChange)="filterField(col.name,'boolean',$event)">
                                        <mat-option>--</mat-option>
                                        <mat-option value="1">Sì</mat-option>
                                        <mat-option value="0">No</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </form>
            </mat-expansion-panel>

            <!-- Sezione gestione colonne -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Colonne</mat-panel-title>
                    <mat-panel-description>Mostra/nascondi</mat-panel-description>
                </mat-expansion-panel-header>
                <!-- Lista colonne mostra/nascondi -->
                <ul cdkDropList (cdkDropListDropped)="dropField($event)" class="show-hide-column">
                    <li *ngFor="let field of tableVisibleFields" cdkDrag [cdkDragDisabled]="!field.show">
                        <mat-icon cdkDragHandle
                            [ngStyle]="{'cursor': field.show ? 'move' : 'not-allowed'}">drag_handle</mat-icon>
                        <mat-checkbox [(ngModel)]="field.show" (ngModelChange)="updateTableVisibleFieldsList()">
                            {{field.label}}
                        </mat-checkbox>
                        <span *cdkDragPreview style="padding: 10px 20px;">{{field.label}}</span>
                    </li>
                </ul>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-sidenav>

    <!-- Sidenav content -->
    <mat-sidenav-content>
        <!-- Title toolbar -->
        <mat-toolbar>
            <!-- Page title -->
            <span *ngIf="tableOptions.title" class="{{tableOptions.titleClass}} page_title">
                <label class="mb-0 ms-2" [ngClass]="tableOptions.titleClass">
                    {{tableOptions.title}}
                </label>
            </span>

            <span class="list-toolbar-spacer"></span>

            <div class="toolbar-buttons-container">
                <!-- Popup shared component -->
                <button mat-raised-button class="button-with-icon" (click)="openDialog('insert')"
                        *ngIf="role != 'principal' && !tableOptions.noInsert">
                    <span>Aggiungi</span> <i class="bi bi-plus-lg"></i>
                </button>

                <!-- Dropdown download -->
                <button mat-raised-button class="button-with-icon" [matMenuTriggerFor]="downloadMenu"
                        *ngIf="tableOptions.exportTypes?.length > 0 && !mobile">
                    <span>Esporta</span> <i class="bi bi-download"></i>
                </button>
            
                <!-- Pulsante mostra sidenav di destra -->
                <button mat-raised-button class="button-with-icon" (click)="sidenav.toggle()">
                    <span>Filtri</span> <i class="bi bi-filter"></i>
                </button>
            </div>
            
            <mat-menu #downloadMenu="matMenu" class="export-buttons">
                <button mat-menu-item (click)="export('xlsx')" *ngIf="tableOptions.exportTypes?.indexOf('xlsx') !== -1">
                    <i class="bi bi-filetype-xlsx" style="color:rgb(0, 90, 0); margin-right: 8px;"></i>
                    Excel
                </button>
                <button mat-menu-item (click)="exportFull('xlsx')" *ngIf="tableOptions.exportTypes?.indexOf('xlsx_full') !== -1">
                    <i class="bi bi-filetype-xlsx" style="color:rgb(0, 90, 0); margin-right: 8px;"></i>
                    Excel Full
                </button>
                <button mat-menu-item (click)="export('csv')" *ngIf="tableOptions.exportTypes?.indexOf('csv') !== -1">
                    <i class="bi bi-filetype-csv" style="color:rgb(150, 0, 0); margin-right: 8px;"></i>
                    CSV
                </button>
                <button mat-menu-item (click)="export('pdf')" *ngIf="tableOptions.exportTypes?.indexOf('pdf') !== -1">
                    <i class="bi bi-filetype-pdf" style="color:rgb(0, 0, 100); margin-right: 8px;"></i>
                    PDF
                </button>
                <button mat-menu-item (click)="export('xml')" *ngIf="tableOptions.exportTypes?.indexOf('xml') !== -1">
                    <i class="bi bi-filetype-xml" style="color:rgb(130, 130, 0); margin-right: 8px;"></i>
                    XML
                </button>
                <button mat-menu-item (click)="export('json')" *ngIf="tableOptions.exportTypes?.indexOf('json') !== -1">
                    <i class="bi bi-filetype-json" style="color:rgb(120, 40, 0); margin-right: 8px;"></i>
                    JSON
                </button>
            </mat-menu>
        </mat-toolbar>

        <!-- Dettaglio filtri attivi -->
        <div class="active-filter-list" *ngIf="filtersObjKV.length > 0">
            <div style="display: flex; align-items: center;">
                <button mat-button *ngIf="!showFilters" (click)="showFilters=!showFilters" class="main-color">
                    Mostra filtri ({{filtersCount}})
                </button>
                <button mat-button *ngIf="showFilters" (click)="showFilters=!showFilters" class="main-color">
                    Nascondi filtri ({{filtersCount}})
                </button>
                <span class="list-toolbar-spacer"></span>
                <!-- Svuota e pulisce tutti i filtri -->
                <button mat-button (click)="clearFilters()" style="margin-right: 12px;" *ngIf="filtersObjKV.length > 0" class="main-color">
                    <mat-icon>clear</mat-icon>Cancella filtri
                </button>
            </div>
            <ul class="filterList" *ngIf="showFilters">
                <li *ngFor="let filter of filtersObjKV">
                    <button type="button" mat-button (click)="removeFilter(filter.field)">
                        <mat-icon>clear</mat-icon>
                    </button>

                    <i>{{filter.label}} </i>
                    <span *ngIf="filter.format=='boolean'">
                        : <b>{{filter.value ? 'Sì' : 'No'}}</b>
                    </span>
                    <span *ngIf="filter.format!='boolean' && filter.value">
                        : <b>{{filter.value}}</b>
                    </span>
                    <span *ngIf="filter.start!=null && filter.end!=null">
                        tra <b>{{filter.start | date : 'dd/MM/yyyy'}}</b> e <b>{{filter.end | date : 'dd/MM/yyyy'}}</b>
                    </span>
                    <span *ngIf="filter.start!=null && filter.end==null">
                        >= <b>{{filter.start | date : 'dd/MM/yyyy'}}</b>
                    </span>
                    <span *ngIf="filter.start==null && filter.end!=null">
                        <= <b>{{filter.end | date : 'dd/MM/yyyy'}}</b>
                    </span>
                </li>
            </ul>
        </div>

        <app-table
            #tableCard
            class="table_card"
            [dataType]="typeof this.table.model"
            [data]="data"
            [entity]="table"
            [tableVisibleFields]="tableVisibleFields"
            [tableVisibleFieldsKV]="tableVisibleFieldsKV"
            [tableVisibleFieldsList]="tableVisibleFieldsList"
            [fieldsGroups]="fieldsGroups"
            [tableOptions]="tableOptions"
            [dataLoading]="dataLoading"
            [count]="count"
            [(offset)]="offset"
            [(limit)]="limit"
            [(sortActive)]="sortActive"
            [(sortDirection)]="sortDirection"
            [initSort]="initSort"
            (openDialog)="openDialog($event._type, $event.obj)"
            (loadMain)="handleLoadMain()">
            <!-- (loadMain)="handleLoadMain($event.count, $event.offset, $event.limit, $event.sortActive, $event.sortDirection, $event.initSort)"-->
        </app-table>
    </mat-sidenav-content>
</mat-sidenav-container>