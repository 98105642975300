import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../services/auth.services';
import { StorageService } from '../services/storage.service';
import { NotifierService } from '../services/notifier.service';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard {
    private destroy$ = new Subject<void>();

    constructor(
        private auth: AuthService,
        private router: Router,
        private notifier: NotifierService,
        private storage: StorageService
    ) { }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        let roles = route.data.roles as Array<string>;

        return new Observable<boolean>(obs => {
            this.auth.checkSession()
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: userData => {
                        this.storage.setUser(userData);

                        // controlla se l'utente ha già accettato i termini e le condizioni
                        if (!userData.terms_and_conditions) {
                            this.router.navigate(['/accettazione-termini-e-condizioni']);
                            obs.next(false);
                        }

                        // controlla se l'utente può visualizzare la risorsa richiesta
                        if (roles.indexOf('admin') > -1) {
                            // la pagina richiesta è una pagina admin, controlla il ruolo dell'utente
                            if (userData.admin) {
                                // l'utente è admin
                                obs.next(true);
                            } else {
                                // l'utente non è admin
                                this.router.navigate(['/']);
                            }
                        } else if (userData.id) {
                            // la pagina richiesta non è admin, non controlla il ruolo dell'utente
                            if (roles.indexOf('principal') == -1 && userData.principal) {
                                // l'utente non è admin
                                this.router.navigate(['/presenze']);
                            } else {
                                obs.next(true);
                            }
                        }

                        obs.next(false);
                    },
                    error: err => {
                        // this.notifier.showError('Errore', 'Si è verificato un errore imprevisto.');
                        this.auth.logout();
                    }
                });
        });
    }

}
