export class Company {
    id: number;
    description: string;
    description_short: string;
    active: boolean;
    image_favicon?: string | null;
    image_header?: string | null;
    image_home?: string | null;
    image_menu?: string | null;
    storage_max_size: number;
    storage_size: number;
    storage_free: number;
    modules: string[];
    reports: Report[];
    settings: Setting[];

    constructor(data: any = {}) {
        this.id = data.id || 0;
        this.description = data.description || "";
        this.description_short = data.description_short || "";
        this.active = !!data.active;
        this.image_favicon = data.image_favicon || null;
        this.image_header = data.image_header || null;
        this.image_home = data.image_home || null;
        this.image_menu = data.image_menu || null;
        this.storage_max_size = data.storage_max_size || 0;
        this.storage_size = data.storage_size || 0;
        this.storage_free = data.storage_free || 0;
        this.modules = data.modules || [];
        this.reports = data.reports ? data.reports.map((r: any) => new Report(r)) : [];
        this.settings = data.settings ? data.settings.map((s: any) => new Setting(s)) : [];
    }
}

export class Report {
    description: string;
    entityName: string;
    icon: string;
    path: string;
    module: string;

    constructor(data: any = {}) {
        this.description = data.description || "";
        this.entityName = data.entityName || "";
        this.icon = data.icon || "";
        this.path = data.path || "";
    }
}

export class Setting {
    setting_key: string;
    setting_value: string;
    default_value: string;

    constructor(data: any = {}) {
        this.setting_key = data.setting_key || "";
        this.setting_value = data.setting_value || "";
        this.default_value = data.default_value || "";
    }
}

export class User {
    id: number;
    name: string;
    surname: string;
    email: string;
    phone: string;
    username: string;
    id_contract: number;
    principal: boolean;
    sys_admin: boolean;
    admin: boolean;
    demo: boolean;
    terms_and_conditions: boolean;
    modules: string[];
    roles: string[];

    constructor(data: any = {}) {
        this.id = data.id || 0;
        this.name = data.name || "";
        this.surname = data.surname || "";
        this.email = data.email || "";
        this.phone = data.phone || "";
        this.username = data.username || "";
        this.id_contract = data.id_contract || 0;
        this.principal = !!data.principal;
        this.sys_admin = !!data.sys_admin;
        this.admin = !!data.admin;
        this.demo = !!data.demo;
        this.terms_and_conditions = !!data.terms_and_conditions;
        this.modules = data.modules || [];
        this.roles = data.roles || [];
    }
}

export class Session {
    company: Company;
    user: User;

    constructor(data: any = {}) {
        this.company = new Company(data?.company);
        this.user = new User(data?.user);
    }

    isAdmin(): boolean {
        return this.user.admin || this.user.sys_admin;
    }

    isSysAdmin(): boolean {
        return this.user.sys_admin;
    }

    isPrincipal(): boolean {
        return this.user.principal;
    }

    getUserRoles(): string[] {
        return this.user.roles;
    }

    getUserModules(): string[] {
        return this.user.modules;
    }

    getCompanyModules(): string[] {
        return this.company.modules;
    }

    getModules(): string[] {
        return this.getUserModules().filter(m => this.getCompanyModules().includes(m));
    }

    getCompanyReports(): Report[] {
        return this.company.reports;
    }

    getCompanySettings(): Setting[] {
        return this.company.settings;
    }

    hasRole(role: string): boolean {
        return this.user.roles.includes(role);
    }

    hasModule(module: string): boolean {
        return this.user.modules?.includes(module) && this.company.modules?.includes(module);
    }

    getFullName(): string {
        return `${this.user.name} ${this.user.surname}`;
    }

    getFreeStorage(): number {
        return this.company.storage_free || 0;
    }
}
