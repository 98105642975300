import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { filter, map, Observable, Subject } from 'rxjs';
import { SessionService } from '../services/session.service';
import { AuthService } from '../services/auth.services';
import { Session } from '../models/session.model';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard {
    private destroy$ = new Subject<void>();
    settings: Session;

    constructor(
        private router: Router,
        private sessionService: SessionService,
        private auth: AuthService
    ) { }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const token = sessionStorage.getItem('token'); // Controlla se esiste un token nel session storage
        if (!token) {
            this.router.navigate(['/login']); // Reindirizza alla login se il token non esiste
            return new Observable<boolean>(observer => observer.next(false));
        }

        return this.sessionService.userSettings$.pipe(
            filter((settings: Session) => settings !== null), // Aspetta i dati utente
            map((settings: Session) => {
                this.settings = new Session(settings);
                // Verifica se c'è almeno un ruolo in comune tra quelli richiesti e quelli dell'utente
                const userRoles: string[] = this.settings?.getUserRoles(); // Ruoli dell'utente
                const requiredRoles: string[] = route.data['roles'] || []; // Ruoli richiesti
                const hasAccess: boolean = requiredRoles.some(role => userRoles.includes(role));

                // Verifica se c'è almeno un modulo in comune tra quelli richiesti e quelli dell'utente - skip se non sono previsti moduli o se requiredModules contiene 'BASE'
                const modules: string[] = this.settings?.getModules();
                const requiredModules: string[] = route.data['modules'] || []; // Moduli richiesti
                const hasModules: boolean = requiredModules.length == 0 || requiredModules.some(module => modules.includes(module)) || requiredModules.includes('BASE');
                
                if (!hasAccess || !hasModules) {
                    this.auth.logout();
                    return false;
                }
                return true;
            })
        );
    }
}
