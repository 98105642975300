export class ActivityModel {
    id: number;
    description: string;
    creation: Date;
    edit: Date;
    active: number;
}

export class AbsenceModel {
    id: number;
    execution: Date;
    justified: number;
    id_absence_type: number;
    description_absence_type: string;
    active_absence_type: number;
    id_user: number;
    username_user: string;
    name_user: string;
    surname_user: string;
    fullname_user: string;
    active_user: number;
    creation: Date;
    edit: Date;
    active: number;
}

export class AbsenceTypeModel {
    id: number;
    description: string;
    creation: Date;
    edit: Date;
    active: number;
}

export class AssistanceModel {
    id: number;
    code: string;
    execution: Date;
    start_time: Date;
    end_time: Date;
    substitution: number;
    id_user: number;
    username_user: string;
    name_user: string;
    surname_user: string;
    active_user: number;
    id_student: number;
    name_student: string;
    surname_student: string;
    active_student: number;
    id_activity: number;
    description_activity: string;
    active_activity: number;
    id_school: number;
    description_school: string;
    class: string;
    active_school: number;
    id_network: number;
    description_network: string;
    active_network: number;
    creation: Date;
    edit: Date;
    active: number;
}

export class AssistanceFullModel {
    id_year: number;
    description_year: string;
    day_start_year: Date;
    day_end_year: Date;
    id_assistance: number;
    code_assistance: string;
    execution_assistance: Date;
    start_time_assistance: Date;
    end_time_assistance: Date;
    duration_assistance: Date;
    substitution_assistance: number;
    creation_assistance: Date;
    edit_assistance: Date;
    id_user: number;
    username_user: string;
    name_user: string;
    surname_user: string;
    fullname_user: string;
    birth_date_user: Date;
    birth_place_user: string;
    birth_province_user: string;
    residence_address_user: string;
    residence_city_user: string;
    residence_province_user: string;
    domicile_address_user: string;
    domicile_city_user: string;
    domicile_province_user: string;
    fiscal_code_user: string;
    vat_number_user: string;
    phone_user: string;
    email_user: string;
    iban_user: string;
    academic_qualification_user: string;
    qualification_type_user: string;
    psychologists_register_user: number;
    psychologists_register_region_user: string;
    psychologists_register_number_user: string;
    notes_user: string;
    monthly_hours_user: number;
    last_contract_date_user: Date;
    admin_user: number;
    id_contract: number;
    description_contract: string;
    id_student: number;
    name_student: string;
    surname_student: string;
    fullname_student: string;
    fiscal_code_student: string;
    disability_student: string;
    multidisability_student: number;
    bes_student: number;
    dsa_student: number;
    certifications_student: number;
    notes_student: string;
    class_student: string;
    id_gender: number;
    description_gender: string;
    id_activity: number;
    description_activity: string;
    id_school: number;
    description_school: string;
    typology_school: string;
    mechanical_code_school: string;
    lead_school: number;
    address_school: string;
    house_number_school: string;
    city_school: string;
    province_school: string;
    email_school: string;
    pec_school: string;
    phone_school: string;
    fiscal_code_school: string;
    principal_surname_school: string;
    principal_name_school: string;
    principal_fiscal_code_school: string;
    branch_office_1_school: string;
    branch_office_2_school: string;
    branch_office_3_school: string;
    branch_office_4_school: string;
    id_network: number;
    description_network: string;
    contract_start_network: Date;
    contract_end_network: Date;
    mono_network: number;
    hours_network: number;
    hourly_rate_network: number;
    hourly_rate_vat_network: number;
    hourly_rate_gross_network: number;
    total_value_network: number;
    total_value_vat_network: number;
    total_value_gross_network: number;
    editable: number;
}

export class AbsenceFullModel {
    id_year: number;
    description_year: string;
    day_start_year: Date;
    day_end_year: Date;
    id_absence: number;
    execution_absence: Date;
    start_time_absence: Date;
    end_time_absence: Date;
    justified_absence: number;
    creation_absence: Date;
    edit_absence: Date;
    id_absence_type: number;
    description_absence_type: string;
    id_user: number;
    username_user: string;
    name_user: string;
    surname_user: string;
    fullname_user: string;
    birth_date_user: Date;
    birth_place_user: string;
    birth_province_user: string;
    residence_address_user: string;
    residence_city_user: string;
    residence_province_user: string;
    domicile_address_user: string;
    domicile_city_user: string;
    domicile_province_user: string;
    fiscal_code_user: string;
    vat_number_user: string;
    phone_user: string;
    email_user: string;
    iban_user: string;
    academic_qualification_user: string;
    qualification_type_user: string;
    psychologists_register_user: number;
    psychologists_register_region_user: string;
    psychologists_register_number_user: string;
    notes_user: string;
    monthly_hours_user: number;
    last_contract_date_user: Date;
    admin_user: number;
    id_contract: number;
    description_contract: string;
    editable: number;
}

export class ContractModel {
    id: number;
    description: string;
    creation: Date;
    edit: Date;
    active: number;
}

export class NetworkModel {
    id: number;
    description: string;
    contract_start: Date;
    contract_end: Date;
    mono: number;
    hours: number;
    hourly_rate: number;
    hourly_rate_vat: number;
    hourly_rate_gross: number;
    total_value: number;
    total_value_vat: number;
    total_value_gross: number;
    creation: Date;
    edit: Date;
    active: number;
}

export class RequestModel {
    id: number;
    description: string;
    code: string;
    open: number;
    id_user_open: number;
    username_user_open: string;
    name_user_open: string;
    surname_user_open: string;
    id_user_close: number;
    close_date: Date;
    username_user_close: string;
    name_user_close: string;
    surname_user_close: string;
    id_assistance: number;
    code_assistance: string;
    creation: Date;
    edit: Date;
    active: number;
}

export class RequestsRepliesModel {
    id: number;
    id_request: number;
    description: string;
    id_user: number;
    username_user: string;
    name_user: string;
    surname_user: string;
    creation: Date;
    edit: Date;
    active: number;
}

export class SchoolModel {
    id: number;
    description: string;
    typology: string;
    mechanical_code: string;
    id_network: number;
    description_network: string;
    active_network: number;
    lead: number;
    address: string;
    house_number: string;
    city: string;
    province: string;
    email: string;
    pec: string;
    phone: string;
    fiscal_code: string;
    principal_surname: string;
    principal_name: string;
    principal_fullname: string;
    principal_fiscal_code: string;
    branch_office_1: string;
    branch_office_2: string;
    branch_office_3: string;
    branch_office_4: string;
    branch_office_5: string;
    creation: Date;
    edit: Date;
    active: number;
}

export class SchoolUsersModel {
    id: number;
    id_school: number;
    description_school: string;
    active_school: number;
    id_network: number;
    description_network: string;
    active_network: number;
    id_user: number;
    username_user: string;
    name_user: string;
    surname_user: string;
    active_user: number;
    creation: Date;
    active: number;
}

export class StudentsModel {
    id: number;
    name: string;
    surname: string;
    fullname: string;
    fiscal_code: string;
    class: string;
    disability: string;
    multidisability: number;
    bes: number;
    dsa: number;
    certifications: number;
    notes: string;
    id_school: number;
    description_school: string;
    active_school: number;
    id_network: number;
    description_network: string;
    active_network: number;
    creation: Date;
    edit: Date;
    active: number;
}

export class UsersModel {
    id: number;
    username: string;
    tmp_password: string;
    name: string;
    surname: string;
    fullname: string;
    id_gender: number;
    description_gender: string;
    birth_date: Date;
    birth_place: string;
    birth_province: string;
    residence_address: string;
    residence_city: string;
    residence_province: string;
    domicile_address: string;
    domicile_city: string;
    domicile_province: string;
    fiscal_code: string;
    vat_number: string;
    phone: string;
    email: string;
    iban: string;
    academic_qualification: string;
    qualification_type: string;
    psychologists_register: number;
    psychologists_register_region: string;
    psychologists_register_number: string;
    notes: string;
    id_contract: number;
    description_contract: string;
    active_contract: number;
    monthly_hours: number;
    admin: number;
    last_contract_date: Date;
    creation: Date;
    edit: Date;
    active: number;
}

export class YearModel {
    id: number;
    description: string;
    day_start: Date;
    day_end: Date;
    current_year: number;
    creation: Date;
    edit: Date;
    active: number;
}

export class CompanyModel {
    id: number;
    description: string;
    description_short: string;
    image_menu: string;
    image_header: string;
    image_home: string;
    image_favicon: string;
    creation?: Date;
    edit?: Date;
    active?: number;
}

export class CompanyRelatedModel {
    id_company: number;
    id_company_related: number;
    description_company_related: string;
    description_short_company_related: string;
    active_company_related: number;
}

export class QualificationModel {
    id: number;
    description: string;
    creation: Date;
    edit: Date;
    active: number;
}