import { CommonModule, Time } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';
import { FiltersModel } from 'src/app/models/entity.model';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import { StorageService } from 'src/app/services/storage.service';
import L from 'leaflet';
import moment, { Moment, MomentInput } from 'moment';
import { MatIcon } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';

declare var window: any;
const getPositionTimeoutMillisec = 30000;

@Component({
    selector: 'app-work-entry',
    imports: [
        CommonModule,
        MatSelectModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatOptionModule,
        MatIcon,
        MatRadioModule
    ],
    templateUrl: './work-entry.component.html',
    styleUrl: './work-entry.component.css'
})
export class WorkEntryComponent {
    private destroy$ = new Subject<void>();

    activeWork: any = undefined;
    selectedActivity: any = {};
    activities: any[] = [];
    sites: any[] = [];
    // activeSite: any = undefined;
    mobile: boolean = false;
    positionLoading: boolean = false;
    positionError: boolean = false;
    siteFinding: boolean = false;
    endWorkCompleted: boolean = false;
    endWorkTs: MomentInput;

    positionStatus: 'searching' | 'found' | 'error' = 'searching';
    map: L.Map;
    userMarker: L.Marker;
    isInCorrectSite: boolean = false;

    pua_badge_has_activities: boolean = false;
    pua_badge_mandatory_position_check: boolean = false;
    position: L.LatLng;
    isInsideGeofence: boolean = false;
    activeGeofence: any = null;
    multipleSites: any[] = [];
    selectedSite: any = null;
    nowIntervalId: any;

    pua_badge_same_start_end: boolean = false;

    public now: Moment;
    public duration: number = 0;
    public startTime: Moment;
    public endTime: Moment;

    constructor(
        private api: ApiService,
        private storage: StorageService,
        private sessionService: SessionService,
    ) {
        this.nowIntervalId = setInterval(() => {
            this.now = moment();
            if (this.startTime) {
                this.duration = this.now.diff(this.startTime, 'seconds');
            }
        }, 1000);
    }

    ngOnInit() {
        this.positionLoading = true;
        this.mobile = this.storage.getMobile() == 'true' ? true : false;
        this.pua_badge_has_activities = !!Number(this.sessionService.getCompanySetting('pua_badge_has_activities'));
        this.pua_badge_mandatory_position_check = !!Number(this.sessionService.getCompanySetting('pua_badge_mandatory_position_check'));
        this.pua_badge_same_start_end = !!Number(this.sessionService.getCompanySetting('pua_badge_same_start_end'));

        if (this.pua_badge_has_activities)
            this.getActivities();
        this.getActiveWork();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const mapElement = document.getElementById('location-map');
            if (mapElement) {
                this.initMap();
            } else {
                console.error('Elemento location-map non trovato nel DOM');
            }
            this.getSites();
        }, 100);
    }

    initMap() {
        this.map = L.map('location-map', {
            dragging: false,
            zoomControl: false,
            scrollWheelZoom: false,
            doubleClickZoom: false,
            touchZoom: false,
            boxZoom: false,
            keyboard: false
        }).setView([0, 0], 18);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);

        const userIcon = L.icon({
            iconUrl: 'assets/leaflet/images/marker-icon.png',
            // iconSize: [25, 25]
        });
        this.userMarker = L.marker([0, 0], { icon: userIcon }).addTo(this.map);
    }

    refreshPosition() {
        this.positionStatus = 'searching';
        this.getUserPosition();
    }

    startWork() {
        const table = 'works';
        const now = moment();
        const fields = {
            id_user: this.sessionService.getUserId(),
            id_site: this.selectedSite.id_site,
            offsite: 0,
            execution: now.format('YYYY-MM-DD'),
            start_time: now.format('HH:mm:ss'),
            start_coords: `${this.position.lat}, ${this.position.lng}`,
            start_outside_geofence: 0,
        };

        this.api.insert(undefined, table, fields)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data: any) => {
                    this.getActiveWork();
                    this.getSites();
                },
                error: (error) => {
                    console.error('Errore durante l\'inserimento:', error);
                }
            });
    }

    endWork() {
        const table = 'works';
        const now: MomentInput = moment();
        const fields = {
            end_time: now.format('HH:mm:ss'),
            end_coords: `${this.position.lat}, ${this.position.lng}`,
            end_outside_geofence: 0,
        };
        const filters: FiltersModel[] = [
            { field: 'id', operator: '=', value: this.activeWork.id }
        ];

        this.api.update(undefined, table, fields, filters)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data: any) => {
                    this.endWorkCompleted = true;
                    if (this.nowIntervalId) {
                        clearInterval(this.nowIntervalId);
                        this.nowIntervalId = null;
                    }
                    this.endWorkTs = now.format('HH:mm:ss');
                    this.duration = now.diff(this.startTime, 'seconds');

                    this.getSites();
                },
                error: (error) => {
                    console.error('Errore durante l\'inserimento:', error);
                }
            });
    }

    private getActiveWork(): void {
        const sourceName = 'works';
        const fields = ['id', 'id_site', 'code', 'description_site', 'code', 'execution', 'start_time', 'id_site'];
        const filters: FiltersModel[] = [
            { field: 'active', operator: '=', value: 1 },
            { field: 'end_time', operator: 'is null', value: '' },
            { field: 'id_user', operator: '=', value: this.sessionService.getUserId() },
        ];

        this.api.select(typeof {}, sourceName, fields, filters, [], {})
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.activeWork = data[0];
                if (this.activeWork) {
                    this.startTime = moment(this.activeWork.execution + ' ' + this.secondsToTime(this.activeWork.start_time), 'YYYY-MM-DD HH:mm:ss');
                }
            });
    }

    private getActivities(): void {
        const sourceName = 'activities';
        const fields = ['id', 'description'];
        const filters: FiltersModel[] = [
            { field: 'active', operator: '=', value: 1 },
            { field: 'code_module', operator: '=', value: 'PUA' },
        ];
        const sort = [{ field: 'description', direction: 'asc' }];

        this.api.select(typeof {}, sourceName, fields, filters, sort, {})
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.activities = data;
            });
    }

    private getSites(): void {
        const sourceName = 'sites_users';
        const fields = ['id_site', 'description_site', 'city_site', 'province_site', 'address_site', 'geofence_polygon_site'];
        const filters: FiltersModel[] = [
            { field: 'active_site', operator: '=', value: 1 },
        ];
        const sort = [{ field: 'description_site', direction: 'asc' }];
        const options = { distinct: true };
        this.api.select(typeof {}, sourceName, fields, filters, sort, options)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.sites = data;
                this.getUserPosition();
            });
    }

    private getUserPosition(): void {
        if (this.mobile) {
            // richiesta posizione da app nativa flutter
            if (window.flutter_inappwebview) {
                const timeoutPromise = new Promise((_, reject) => {
                    setTimeout(() => reject('Position request timed out'), getPositionTimeoutMillisec);
                });

                Promise.race([
                    window.flutter_inappwebview.callHandler("richiediPosizione"),
                    timeoutPromise
                ])
                    .then((position: any) => this.getPostionSuccess(position.latitude, position.longitude))
                    .catch((error) => this.getPostionError(error));
            } else {
                this.getPostionError("Flutter WebView non disponibile");
            }
        } else {
            // richiesta posizione da web
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.getPostionSuccess(position.coords.latitude, position.coords.longitude);
                    },
                    (error) => this.getPostionError(error),
                    {
                        timeout: getPositionTimeoutMillisec,
                        maximumAge: 0,
                        enableHighAccuracy: true
                    }
                );
            }
        }
    }

    private getPostionSuccess(latitude: number, longitude: number): void {
        this.position = L.latLng(latitude, longitude);

        this.positionStatus = 'found';
        this.userMarker.setLatLng(this.position);
        this.map.setView(this.position);
        this.checkGeofences(this.position);

        this.positionLoading = false;
    }

    private getPostionError(error: any): void {
        console.error('Geolocation error:', error);
        this.positionError = true;
        this.positionLoading = false;

        this.positionStatus = 'error';
    }

    private findSite(point: L.LatLng): any[] {
        if (!point) return [];

        return this.sites.filter(site => {
            if (!site.geofence_polygon_site) return false;

            const coordinates = JSON.parse(site.geofence_polygon_site).coordinates[0].map(coord => [coord[1], coord[0]]);
            const polygon = L.polygon(coordinates);

            return polygon.getBounds().contains(point) /*&& (!this.activeWork || this.activeWork.id_site === site.id_site)*/;
        });
    }

    private checkGeofences(position: L.LatLng) {
        this.isInsideGeofence = false;
        this.activeGeofence = null;
        this.isInCorrectSite = false;

        this.multipleSites = this.findSite(position);
        // se è in corso un lavoro, seleziona il sito del lavoro, alltrimenti seleziona il primo sito trovato
        this.selectedSite = !this.activeWork ? this.multipleSites[0] : this.multipleSites.find(site => site.id_site === this.activeWork.id_site);
        this.updateMapPolygons(this.selectedSite);
    }

    updateMapPolygons(selectedSite: any) {
        const selectedSiteId = selectedSite?.id_site ? selectedSite?.id_site : null;

        // Rimuovi i poligoni già presenti
        this.map.eachLayer((layer) => {
            if (layer instanceof L.Polygon) {
                this.map.removeLayer(layer);
            }
        });

        // Aggiungi i poligoni per ogni sito
        this.sites.forEach(site => {
            if (site.geofence_polygon_site) {
                const coordinates = JSON.parse(site.geofence_polygon_site).coordinates[0]
                    .map(coord => [coord[1], coord[0]]);

                let color = this.activeWork && site.id_site === this.activeWork?.id_site ? '#3d7ade' : '#ffc107';
                if (site.id_site === selectedSiteId || (this.multipleSites.length === 1 && this.multipleSites[0].id_site === site.id_site)) {
                    if (!this.pua_badge_same_start_end || this.activeWork?.id_site === site.id_site) {
                        color = '#28a745';
                    } else {
                        color = '#dc3545';
                    }
                }

                const polygon = L.polygon(coordinates, {
                    color: color,
                    fillColor: color,
                    fillOpacity: 0.2,
                    weight: 2
                }).addTo(this.map);

                // Se il sito è quello selezionato, imposta come geofence attivo
                if (site.id_site === selectedSiteId) {
                    this.isInsideGeofence = true;
                    this.activeGeofence = site;
                    this.isInCorrectSite = true;
                }
            }
        });

        if (this.multipleSites.length > 0 && (!this.pua_badge_same_start_end || this.activeWork?.id_site === this.multipleSites[0].id_site)) {
            this.isInsideGeofence = true;
            this.isInCorrectSite = false;
            this.activeGeofence = this.multipleSites[0];

            if (this.multipleSites.length == 1) {
                this.selectedSite = this.multipleSites[0];
            }
        }
    }

    secondsToTime(seconds: number): string {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    get positionStatusText(): string {
        switch (this.positionStatus) {
            case 'searching':
                return 'Ricerca posizione...';
            case 'found':
                return 'Posizione trovata';
            case 'error':
                return 'Errore posizione';
            default:
                return '';
        }
    }

    reloadPage() {
        location.reload();
    }
}
