import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CompanyModel } from 'src/app/models/db.model';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-mobilelogin',
    imports: [],
    templateUrl: './mobile-login.component.html',
    styleUrl: './mobile-login.component.css'
})
export class MobileLoginComponent {
    token: string = '';
    refreshToken: string = '';
    private intervalId: any;
    private destroy$ = new Subject<void>();

    constructor(
        private storage: StorageService,
        private router: Router,
        private api: ApiService,
        private notifier: NotifierService
    ) { }

    ngOnInit(): void {
        this.startPolling();
    }

    ngOnDestroy(): void {
        this.stopPolling();
        this.destroy$.next();
        this.destroy$.complete();
    }

    startPolling(): void {
        this.intervalId = setInterval(() => {
            this.token = this.storage.getToken();
            this.refreshToken = this.storage.getRefreshToken();
            if (this.token) {
                this.stopPolling();
                this.saveCompanyInfo();
            }
        }, 100);
    }

    stopPolling(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    saveCompanyInfo() {
        this.api.select(null, 'companies', ['description', 'description_short', 'image_menu', 'image_header', 'image_home', 'image_favicon'], [], [], {})
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: data => {
                    const company: CompanyModel = {
                        id: data[0].id,
                        description: data[0].description,
                        description_short: data[0].description_short,
                        image_menu: data[0].image_menu,
                        image_header: data[0].image_header,
                        image_home: data[0].image_home,
                        image_favicon: data[0].image_favicon
                    }
                    this.storage.setCompanyInfo(company);
                    this.router.navigate(['/']);
                },
                error: err => {
                    console.log(err);
                    this.notifier.showWarning('Attenzione', 'Non è stato possibile recuperare le informazioni della cooperativa.');
                    this.router.navigate(['/']);
                }
            });
    }

}
