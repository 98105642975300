export class UserModel {
    id: number;
    username: string;
    active: boolean;
    admin: boolean;
    sys_admin: boolean;
    principal: boolean;
    terms_and_conditions: boolean;
    contract?: string;
    id_company: number;

    constructor() {
        this.id = null;
        this.username = null;
        this.active = null;
        this.admin = null;
        this.sys_admin = null;
        this.principal = null;
        this.terms_and_conditions = null;
        this.contract = null;
        this.id_company = null;
    }
}
