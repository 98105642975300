import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotifierService } from 'src/app/services/notifier.service';
import { EntitySelectModel, FieldsModel, FiltersModel, EntityModel } from 'src/app/models/entity.model';
import * as XLSX from 'xlsx';
import { ApiService } from 'src/app/services/api.service';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.css'],
    standalone: false
})
export class UploadComponent {
    @ViewChild('uploadInputHtml') uploadInputHtml: ElementRef;
    @ViewChild(MatSort) sort: MatSort;
    private destroy$ = new Subject<void>();

    entityModel = new EntityModel('');
    entitySelect: EntitySelectModel[];
    uploadDataSource = new MatTableDataSource<any>(null);
    uploadFieldsList: string[] = [];
    excelData: any;
    selectedEntityValue: EntityModel['sourceName'];
    selectedEntityLabel: string = '';
    fileName = '';
    // skipRows = 0;
    fileSelectAlert: boolean = false;
    selectedFileName: string = '';
    definedFields: string = '';
    validRowsCount: string = '';
    workBook: XLSX.WorkBook;

    // selectedEntityFields: ListFieldsModel[] = [];
    selectedEntityUpsertFields: FieldsModel[] = [];
    selectedEntityUpdateFields: FieldsModel[] = [];
    selectedEntityUpdateRequiredFields: FieldsModel[] = [];
    fileHeaderList: string[] = [];
    rowsToSkip: number = 0;
    fieldDefaultValue = '_ignore';
    fieldValues = [];
    fieldValuesNotes = [];
    upsertFields = [];
    validRows = [];
    invalidRows = [];
    uploadResult = [];
    linkedData = [];
    isFileSelected: boolean = false;

    checkAlert = false;

    processedRows: number = 0;
    rowsToProcess: number = 0;

    entitySelectFormGroup = this._formBuilder.group({
        entityType: ['', Validators.required],
    });
    fileSelectFormGroup = this._formBuilder.group({
        uploadInput: ['', Validators.required],
        skipRows: [0, Validators.required],
    });
    fieldsDefinition = this._formBuilder.group({
    });
    isLinear = true;

    constructor(
        private notifier: NotifierService,
        private _formBuilder: FormBuilder,
        private api: ApiService
    ) { }

    ngOnInit() {
        this.entitySelect = this.entityModel.getUpsertEntites();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onStepChange(event: any): void {
        // 1 -> selezione entità
        // 2 -> selezione file e opzioni
        // 3 -> definizione campi
        // 4 -> upload
        // 5 -> riepilogo
        const step = event.selectedIndex + 1;
        const prevStep = event.previouslySelectedIndex + 1;

        if (step == 1) {
            if (prevStep > step) {
                // si torna indietro a questo step
                this.resetEntitySelection();
                this.resetFileSelection();
                this.resetFieldsDefinition();
                this.resetUpload();
            } else {
                // si avanza a questo step dal precedente
            }

        } else if (step == 2) {
            if (prevStep > step) {
                // si torna indietro a questo step
                this.resetFileSelection();
                this.resetFieldsDefinition();
                this.resetUpload();
            } else {
                // si avanza a questo step dal precedente
                // recupera il nome dell'entità selezionata per mostrarla a video
                this.selectedEntityValue = <EntityModel['sourceName']>this.entitySelectFormGroup.controls['entityType'].value;
                this.selectedEntityLabel = this.entitySelect
                    .filter(e => e.value == this.selectedEntityValue)
                    .map(e => e.label)
                [0];
                // recupera la lista dei campi dell'entità selezionata - serve al template scaricabile
                this.selectedEntityUpdateFields = this.entityModel.getUpdateFieldsByEntityName(this.selectedEntityValue);
            }

        } else if (step == 3) {
            if (prevStep > step) {
                // si torna indietro a questo step
                this.resetFieldsDefinition();
                this.resetUpload();
            } else {
                // si avanza a questo step dal precedente
                // recupera il nome del file e il numero di righe selezionata per mostrarla a video
                const cnt = this.excelData.length;
                this.selectedFileName = this.fileName + ' (' + cnt + (cnt == 1 ? ' riga' : ' righe') + ')';
                // recupera la struttura dei campi per la fase di definizione
                this.selectedEntityUpsertFields = this.entityModel.getUpsertFieldsByEntityName(this.selectedEntityValue);
                this.selectedEntityUpdateFields = this.entityModel.getUpdateFieldsByEntityName(this.selectedEntityValue);
                this.selectedEntityUpdateRequiredFields = this.entityModel.getUpdateRequiredFieldsByEntityName(this.selectedEntityValue);

                // bisonga cercare per ogni campo dell'entità bisogna cercare quale campo dell'excel corrisponde (entità controllo la label e salvo il name)
                this.fileHeaderList.forEach((h, i) => {
                    // se h termina con " *" p " **" glieli rimuovo
                    if (h.endsWith(' **')) {
                        h = h.substring(0, h.length - 3);
                    } else if (h.endsWith(' *')) {
                        h = h.substring(0, h.length - 2);
                    }
                    // cerco il campo dell'Excel dei campi dell'entità
                    const field = this.selectedEntityUpdateFields.filter(f => f.label == h)[0];
                    if (field) {
                        this.fieldValues[i] = field.name;
                        this.fieldValuesNotes[i] = 'Aggiunto dal sistema. Verificare';
                    }
                });

            }

        } else if (step == 4) {
            if (prevStep > step) {
                // si torna indietro a questo step
                this.resetUpload();
            } else {
                // si avanza a questo step dal precedente
                const cnt = this.fieldValues.slice().filter(f => f != '_ignore').length;
                this.definedFields = cnt + (cnt == 1 ? ' campo selezionato' : ' campi selezionati');
                // aggiorna l'oggetto di mappatura dei campi
                this.upsertDefinition();
                this.fileCheck();
                // preparo la lista di entità collegate
                this.loadLinked();
            }

        } else if (step == 5) {
            if (prevStep > step) {
                // si torna indietro a questo step
                // in questo scenario non applicabile - ultimo step
            } else {
                // si avanza a questo step dal precedente
                const cnt = this.validRows.length;
                this.validRowsCount = cnt + (cnt == 1 ? ' riga processabile' : ' righe processabili');
            }
        }
    }

    ignoreField(i: number) {
        if (this.fieldValues[i]) {
            this.fieldValues[i] = this.fieldDefaultValue;
            this.fieldValuesNotes[i] = '';
        }
    }

    fieldValuesChange(i: number) {
        if (this.fieldValues[i]) {
            this.fieldValuesNotes[i] = '';
        }
    }

    fileSelectSubmit() {
        if (this.fileSelectFormGroup.controls['uploadInput'].errors) {
            this.fileSelectAlert = true;
        } else {
            this.fileSelectAlert = false;
        };
    }

    resetEntitySelection() {
        // resetta la fase 1 - selezione dell'entità
        this.selectedEntityLabel = '';
    }

    resetFileSelection() {
        // resetta la fase 2 - selezione del file
        // if (this.uploadInputHtml)
        //   this.uploadInputHtml.nativeElement.value = '';

        this.selectedFileName = '';
        // this.fileName = '';
    }

    resetFieldsDefinition() {
        // resetta la fase 3 - definizione dei campi
        // cancella l'etichetta della fase di definizione
        this.definedFields = '';
    }

    resetUpload() {
        // resetta la fase 4 - upload
        // cancella l'etichetta della fase di upload
        this.validRowsCount = '';
    }

    downloadTemplate() {
        // Restituisce la lunghezza per ciascuna colonna
        const fitToColumn = data => {
            const columnWidths = [];
            // Larghezza massima della colonna
            const maxWidth = 30;
            for (const property in data[0]) {
                // Larghezza reale della colonna (potenzialmente maggiore della maxWidth)
                const realWidth = Math.max(
                    property ? property.toString().length : 0, ...data.map(
                        obj => obj[property] ? obj[property].toString().length : 0)
                );
                columnWidths.push({
                    // utilizza la realWidth se minore della maxWidth
                    wch: Math.min(maxWidth, realWidth)
                });
            }
            return columnWidths;
        };

        let templateData = [{}];
        // creo un oggetto JSON vuoto con le sole etichette di campo nella prima riga
        this.selectedEntityUpdateFields
            .sort(function (a, b) { return a.form?.formSort - b.form?.formSort; })
            .forEach(field => {
                var label = field.label;
                if (field.form?.isRequired)
                    label += ' *';
                if (field.crud.indexOf('upsert') != -1)
                    label += '*';
                templateData[0][label] = '';
            });

        // Crea WorkBook e WorkSheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(templateData);
        ws['!cols'] = fitToColumn(templateData);
        // Crea il file
        const sheetName = this.selectedEntityLabel;
        const fileName = sheetName + '_' + moment(new Date()).format('YYYYMMDDHHmmss') + '.xlsx';
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        // Esporta il file all'utente
        XLSX.writeFile(wb, fileName);
    }

    readFile() {
        // questo metodo viene invocato al caricamento del file o alla modifica dell'imput delle righe da skippare
        // se non è ancora stato selezionato alcun file, non viene fatto nulla
        if (!this.workBook)
            return;

        var sheetNames = this.workBook.SheetNames;
        this.rowsToSkip = this.fileSelectFormGroup.controls.skipRows.value;

        // legge l'excel
        this.excelData = XLSX.utils.sheet_to_json(this.workBook.Sheets[sheetNames[0]], { range: this.rowsToSkip, defval: "" });
        // recupera la lista dell'intestazione
        this.fileHeaderList = Object.keys(this.excelData[0]);
        // resetta la definizione dei campi della fase 3
        // invocato quando viene caricato un file nuovo o un file diverso
        for (let i = 0; i < this.fileHeaderList.length; i++) {
            // resetta i campi select di definizione
            this.fieldValues[i] = this.fieldDefaultValue;
            this.fieldValuesNotes[i] = '';
        }
        // svuota le liste della fase di definizione
        this.selectedEntityUpsertFields = [];
        this.selectedEntityUpdateFields = [];
        this.selectedEntityUpdateRequiredFields = [];

        // svuota l'oggetto che contiene il report del caricamento
        this.uploadResult = [];
        // registra le righe skippate all'interno dell'oggetto che contiene il report del caricamento
        for (let i = 0; i < this.rowsToSkip; i++) {
            this.uploadResult[i + 1] = 'Riga ignorata';
        }
        // registra la riga di intestazione all'interno dell'oggetto che contiene il report del caricamento
        this.uploadResult[this.rowsToSkip + 1] = 'Riga di intestazione';
    }

    loadFile(e) {
        this.isFileSelected = true;
        // fileSelectAlert = true -> colore rosso upload file
        this.fileSelectAlert = false;
        // alert per struttura file non valida
        this.checkAlert = false;
        // riferimento al file
        let file = e.target.files[0];
        this.fileName = file.name;

        let fileReader = new FileReader();
        // fileReader.readAsBinaryString(file);
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            const data = new Uint8Array(fileReader.result as ArrayBuffer);
            //this.workBook = XLSX.read(fileReader.result, { type: 'binary' });
            this.workBook = XLSX.read(data, { type: 'array' });
            this.readFile();
        };
    }

    clearFile() {
        this.isFileSelected = false;
        this.fileName = '';
        this.uploadInputHtml.nativeElement.value = '';
        this.workBook = null;
        this.excelData = null;
        this.fileHeaderList = [];
    }

    isFieldSelected(field: FieldsModel) {
        return this.fieldValues?.indexOf(field.name) != -1;
    }

    isUpsertField(fieldName: string): boolean {
        // verifica se il campo in input è di quelli 'upsert' quindi va usato come filtro
        return this.selectedEntityUpsertFields.slice().filter(f => f.name == fieldName).length != 0;
    }

    isRequiredField(fieldName: string): boolean {
        // verifica se il campo in input è di quelli 'upsert' quindi va usato come filtro
        return this.selectedEntityUpdateRequiredFields.slice().filter(f => f.name == fieldName).length != 0;
    }

    allUpsertSelected(): boolean {
        if (this.selectedEntityUpsertFields.length == 0) return false;

        let check = true;
        // Verifica che siano stati definiti tutti i campi di upsert (chiave di ricerca per fare upload)
        this.selectedEntityUpsertFields?.forEach(field => {
            if (!this.isFieldSelected(field)) check = false;
        });
        return check;
    }

    allRequiredSelected(): boolean {
        let check = true;
        // Verifica che siano stati definiti tutti i campi obbligatori (serve per fare insert)
        this.selectedEntityUpdateRequiredFields?.forEach(field => {
            if (!this.isFieldSelected(field)) check = false;
        });
        return check;
    }

    upsertDefinition() {
        this.upsertFields = [];
        // cicla la lista di definizione dei campi (excel - db)
        this.fieldValues.forEach((field, i) => {
            // esclude tutti i campi che non sono stati definiti dall'utente
            if (field != '_ignore') {
                // mapping dei campi per l'update e per il filtro
                this.upsertFields.push({
                    'inputColumn': this.fileHeaderList[i],
                    'upsertColumn': field,
                    'type': this.isUpsertField(field) ? 'upsert' : 'update',
                    'isRequired': this.isRequiredField(field)
                });
            }
        });
    }

    fileCheck() {
        this.validRows = [];
        this.invalidRows = [];
        // cicla le righe del file in upload
        this.excelData.forEach((row, i) => {
            let valid = true;
            // cicla i campi di filtro per verificare che siano valorizzati quando previsto
            this.upsertFields.forEach(f => {
                if (f.type == 'upsert' && f.isRequired &&
                    (row[f.inputColumn] == undefined || row[f.inputColumn] == null || row[f.inputColumn] == '')) {
                    valid = false;
                }
            });
            // vengono salvati i numeri di riga delle celle valide e non valide
            // +2 per indice che parte da 1 e skip della riga di intestazione
            // + this.rowsToSkip -> numero di righe da skipparedefinito dall'utente
            if (valid) {
                this.validRows.push(i + 2 + this.rowsToSkip);
            } else {
                this.invalidRows.push(i + 2 + this.rowsToSkip);
                this.uploadResult[i + 2 + this.rowsToSkip] = 'Riga non valida - non processata';
            }
        });
    }

    loadLinked() {
        this.linkedData = [];
        this.selectedEntityUpdateFields.forEach(f => {
            if (f.form?.type == 'select') {
                const select = f.form.select;
                // entità su cui fare ricerca
                const sourceName = select.sourceName;
                // campo su cui fare la ricerca
                const fieldSearch = select.upsertId ? select.upsertId : select.label;
                // chiave da usare per la foreign key
                const idJoin = select.id;

                // preparo i parametri per la select
                let fields: string[] = [idJoin, fieldSearch];
                let filters: FiltersModel[] = [{
                    'field': 'active',
                    'operator': '=',
                    'value': 1
                }];

                // recupero il valore da usare come foreign key
                this.api.select(typeof {}, sourceName, fields, filters, [], {})
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        data => {
                            this.linkedData[sourceName] = data;
                        }
                    );
            }
        });
    }

    upsert() {
        // this.processedRows = this.invalidRows.length;
        this.processedRows = 0;
        this.rowsToProcess = this.validRows.length;

        // svuota l'oggetto che contiene il report del caricamento
        this.uploadResult = [];

        this.validRows.forEach(i => {
            // elenco dei numeri delle righe da processare
            // sottrae 2 al numero perché aggiunto in precedenza prima di mostrarlo
            // in precedenza: +2 per indice che parte da 1 e skip della riga di intestazione
            //                + this.rowsToSkip -> numero di righe da skipparedefinito dall'utente
            const rowNumber = i - 2 - this.rowsToSkip;
            // estrae la riga partendo dall'indice
            const row = this.excelData[rowNumber];
            let fields: {} = {};
            let filters: FiltersModel[] = [];
            // cicla i campi definiti dall'utente
            this.upsertFields.forEach(field => {
                if (field.type == 'upsert') {
                    filters.push({
                        'field': <string>field.upsertColumn,
                        'operator': '=',
                        'value': row[field.inputColumn]
                    });
                } else {
                    const fieldModel: FieldsModel = this.selectedEntityUpdateFields.filter(f => f.name == field.upsertColumn)[0];
                    var fieldValue = null;
                    // per i campi di tipo select occorre ricavare l'id della foreign key
                    if (fieldModel.form?.type == 'select') {
                        const select = fieldModel.form.select;
                        // entità su cui fare ricerca
                        const sourceName = select.sourceName;
                        // campo su cui fare la ricerca
                        const fieldSearch = select.upsertId ? select.upsertId : select.label;
                        // valore da ricercare
                        const searchPattern = row[field.inputColumn];
                        // chiave da usare per la foreign key
                        const idJoin = select.id;

                        fieldValue = this.linkedData[sourceName]
                            .slice()
                            .filter(f => f[fieldSearch].trim() == searchPattern.toString().trim())
                            .map(f => f[idJoin])
                        [0];

                    } else {
                        fieldValue = row[field.inputColumn];
                    }

                    if (fieldValue)
                        fields[<string>field.upsertColumn] = fieldValue;
                }
            });
            // invoco il metodo upsert
            // fields - filters
            this.api.upsert(this.entityModel, this.selectedEntityValue, fields, filters)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (data) => {
                        // this.uploadResult[i] = data;
                        this.processedRows++;
                    },
                    error: (error) => {
                        this.uploadResult[i] = 'Si è verificato un errore nel processare questa riga (' + error.error['detail'] + ')';
                        this.processedRows++;
                    }
                });
        });
    }

    // TODO: controllare i tipi di campo nel metodo fileCheck()
}
